.set_banner .home_banner_subheading {
    font-weight: 600;
    color: #000000;
}
.set_banner .banner-heading {
    font-size: 58px;
    line-height: 64px;
    letter-spacing: 0px;
    max-width: 90%;
}
.set_banner .banner-para {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0;
}

.banner.set_banner {
    padding: 77px 0 96px 0;
}
.set_logo_section section.sec-2 {
    margin-bottom: 120px;
}
.sec_space.set_elevate-section {
    padding-top: 96px;
}
.set_elevate-section .hm_heading_big h3 {
    font-size: 48px;
    line-height: 46px;
    letter-spacing: -1px;
}
.set_elevate-section .hm_heading {
    margin-bottom: 35px;
}
.set_elevate-section .sec3_n_btns {
    padding-top: 0;
}
.set_big_slides_section .test-1 p {
    opacity: 0.8;
    font-weight: 400;
}
.set_big_slides_section .sec-6 .test-img {
    
    right: 40px;
    top: 22px;
}
.sec_space.set_why_section {
    padding-top: 120px;
    padding-bottom: 0;
}
.set_why_section .hm_heading.hm_heading_big h3 {
    font-size: 42px;
    line-height: 52px;
    letter-spacing: 0;
    
    margin-bottom: 80px;
}
.set_why_section  .dordr_whysec_pics span {
    margin: 72px 15px 52px 15px;
}
.set_why_section .hm_heading .dordr_whysec_infos p {
    letter-spacing: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 0;
}
section.sec5new.sec_space.set_deliver_section {
    background: #fff;
    padding-top: 0px;
    padding-bottom: 96px;
    margin-bottom: 0px;
}
.set_deliver_section .hm_heading.hm_heading_big h3 {
    color: #fff;
    font-size: 48px;
    line-height: 62px;
    letter-spacing: -1px;
}
.set_deliver_section .hm_heading.hm_heading_big h3  small {
    font-weight: 300;
}
.set_deliver_section .hm_heading.hm_heading_big p {
    color: #fff;
    font-family: Manrope;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    margin-bottom: 0;
}
.set_deliver_section .hm_whycards {
    margin-top: 62px;
}
section.sec5new.dordr_deliversec.set_deliver_section:after {
    display: none;
}
.set_deliver_section .sec4newa_grid.do_delivr_grid .hm_whycards {
    padding: 32px;
    padding-bottom: 24px;
    min-height: 450px;
    max-width: 100%;
}
.set_deliver_section  .sec4newa_grid.do_delivr_grid .hm_whycards .hm_whycard_icon span {
    background: #D9D9D9;
    margin-bottom: 0;
}
.set_deliver_section .hm_whycard_icon {
    margin-bottom: 10px;
}

.set_deliver_section .hm_whycard_icon img.square-imgg {
    transform: scale(0.75);
}


.set_deliver_section .sec4newa_grid.do_delivr_grid .hm_whycards .hm_why_infos h4 {
    font-size: 20px;
    line-height: 26px;
    min-height: inherit;
    margin-bottom: 10px;
}
.set_deliver_section .sec4newa_grid.do_delivr_grid .hm_whycards .hm_why_infos ul li {
    margin: 0 0 28px 0;
}
.set_deliver_section .sec4newa_grid.do_delivr_grid .hm_whycards .hm_why_infos ul li:last-child {
    margin-bottom: 0;
}
.set_adjust_section .hm_heading.hm_heading_big h3 {
    font-size: 48px;
    line-height: 62px;
    letter-spacing: -1px;
}
.set_adjust_section .hm_heading.hm_heading_big {
    margin-bottom: 113px;
}
.set_adjust_section  .adjust_grid {
    padding: 0;
}
.set_adjust_section  .dordr_adjust_list h4 {
    font-weight: 700;
    line-height: 35px;
}
.set_adjust_section .dordr_adjust_list {
    padding: 0 40px 14px 0px;
    margin-bottom: 26px;
    margin-right: 30px;
}

.set_adjust_section .dordr_img span {
    height: 597px;
    margin-bottom: 0;
}
.set_adjust_section .dordr_adjust_list:last-child {
    border: none;
    margin-bottom: 0px;
}
section.sec5new.sec_space.set_adjust_section {
    padding-bottom: 120px;
    overflow: hidden;
}
.set_second_logo_section .para.hmpara_btm {
    margin-top: 42px;
}
section.sec-2.set_second_logo_section {
    margin-bottom: 120px;
}
.set_fbar_up_section h2.subheading {
    line-height: 46px;
    letter-spacing: -0.5px;
}
section.footer-bar-up.set_fbar_up_section .heading {
    font-weight: 400;
}
.set_fbar_up_section .right-side {
    border-left: 1px solid #323232;
}
section.sec-8.faq_btm.set_faq_section {
    padding-top: 96px;
    padding-bottom: 96px;
}
.set_faq_section .accordion_rista .card .card-header .btn-header-link {
    font-size: 24px;
    padding: 34px 0px;
}
.set_faq_section .card-body ul li {
    margin-bottom: 22px;
}
.set_faq_section .accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link.collapsed::before {
    top: 51px;
}
.set_faq_section .accordion_rista.accordion_rista2 .card .card-header .btn-header-link.collapsed::after {
    right: 23px;
    transition: none  !important;
}
.whyrow {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 33px;
    margin-top: 72px;
    margin-bottom: 52px;
}
.why-imgg {
    border: 6px solid #494949;
    border-radius: 24px;
    max-width: 100%;
}
img.square-imgg {
    max-width: 100%;
}
img.banr-img {
    max-width: 100%;
}
img.prefer-imgg {
    max-width: 100%;
}
.mobile-imag{
    display: none;
}
.pc-imag{
    display: block;
}
.client-name{
    display: none;
}
.catalog-slider {
    max-width: 840px;
    margin: auto;
    margin-top: 52px;
    margin-bottom: 52px;
}

.set_big_slides_section .sec-6 .owl-nav button span {
    color: #000 !important;
}
/* .set_big_slides_section .sec-6 .owl-prev {
    left: 0;
    top: 100%;
}
.set_big_slides_section .sec-6 .owl-next {
    right: 0;
    top: 100%;
}
.set_big_slides_section .sec-6 .owl-dots {
    position: relative;
    right: 0;
    text-align: left;
    top: 60px;
} */
section.sec3new_upsec.sec_space.set_big_slides_section .sec-6.deordr_owl_slides .owl-slider {
    overflow: inherit;
    border: none;
}
section.sec3new_upsec.sec_space.set_big_slides_section .owl-carousel .owl-stage-outer {
    border-radius: 12px;
}
section.sec3new_upsec.sec_space.set_big_slides_section .owl-carousel .owl-nav {
    width: 100px !important;
    bottom: -20px;
    z-index: 1;
}
section.sec3new_upsec.sec_space.set_big_slides_section .owl-carousel .owl-nav button {
    border: 2px solid #000 !important;
    height: 40px;
    width: 40px;
}
section.sec3new_upsec.sec_space.set_big_slides_section .owl-carousel .owl-nav button span {
    line-height: normal;
    font-size: 37px;
    top: -9px;
}
section.sec3new_upsec.sec_space.set_big_slides_section  .owl-theme .owl-dots .owl-dot span {
    background: #C7C7C7;
}
section.sec3new_upsec.sec_space.set_big_slides_section .owl-theme .owl-dots .owl-dot.active span {
    background: #000;
}
.ban-cta {
    margin-right: 10px;
}
a.ban-second-cta {
    background: #000000cf;
}
.set_elevate-section .hm_heading.hm_heading_big h2 {
    font-size: 42px;
    font-weight: 700;
    line-height: 52px;
}
.set_deliver_section .hm_heading.hm_heading_big h4 {
    font-size: 42px;
    font-weight: 800;
    line-height: 52px;
    text-align: center;
    color: #fff;
    max-width: 55%;
    margin: auto;
    margin-bottom: 20px;
}

.set_deliver_section .hm_heading.hm_heading_big h4 small {
    font-weight: 300;
}
.set_adjust_section .hm_heading.hm_heading_big h5 {
    font-size: 42px;
    font-weight: 800;
    line-height: 52px;
    letter-spacing: 0;
    text-align: center;
    max-width: 60%;
    margin: auto;
    margin-bottom: 20px;
    text-transform: initial;
}
.set_faq_section .accordion_rista.accordion_rista2 .card-body {
    padding-left: 0;
}

/* inventory page  */
.banner.set_banner.set_inventory_banner {
    padding-bottom: 96px;
}
/* .set-inventory-logo-section .para.hmpara_btm {
    margin-bottom: 36px;
    margin-top: 0;
} */
.set_inventory_banner img.banr-img.pc-imag {
    margin-top: 22px;
}
section.sec3new.sec_space.direct_ordr_most.inverntry_m_review.set_invt_review_section {
    padding-bottom: 120px;
}
.set_invt_takecon_img img {
    max-width: 435px;
}

.set_invt_takecon_img {
    padding-bottom: 32px;
}
.set_invt_review_section h3 {
    margin-bottom: 0;
}
.set_invt_review_section .test-1 p {
    opacity: 0.8;
    font-weight: 700;
}
.set_invt_review_section .sec-6 .test-img {
    right: 40px;
    top: 22px;
}
.set-invt-control-section .takecon_infos h4 {
    line-height: 32px;
}
.set-invt-control-section .takecon_infos ul li {
    font-size: 14px;
    line-height: 24px;
}
.set-invt-control-section .take_control_sec .hm_heading h3 {
    font-size: 36px;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: -1px;
    margin-bottom: 33px;
}
.set-invt-control-section  .takecon_infos ul li:last-child {
    margin-bottom: 0;
}
section.sec_space.take_control_sec.set-invt-control-section {
    padding-bottom: 96px;
}
.inven_choose_rista.set_invt_choose_rista {
    padding-top: 96px;
    padding-bottom: 96px;
}
.sec-3.set_invt_choose_rista .subheading {
    font-size: 42px;
    font-weight: 800;
    line-height: 52px;
    letter-spacing: 0;
}
.set_invt_choose_rista .sec-3 .nav.nav-pills {
    gap: 18px;
}
.inven_choose_rista.set_invt_choose_rista ul.nav.nav-pills li {
    margin-top: 0;
    padding-left: 0;
}
.set_invt_choose_rista .point_block_main {
    background: no-repeat;
    padding: 0;
    max-width: 975px;
    margin: auto;
}
.set_invt_choose_rista .point_box h3 {
    margin-top: 0;
    font-weight: 800;
    margin-bottom: 40px;
    max-width: 100%;
}
/* .set_invt_choose_rista .tab-content {
    margin-top: 64px;
} */
.set_invt_choose_rista .points_tick ul li {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #000;
    margin-top: 0;
    margin-bottom: 20px;
}
.set_invt_choose_rista .point_box p, .set_invt_choose_rista .point_box p small {
    font-size: 14px !important;
    font-style: italic;
    font-weight: 400 !important;
    line-height: 20px;
    text-align: left;
    margin-top: 29px;
    font-family: "Plus Jakarta Sans", sans-serif !important;
    opacity: 0.74;
}
.set_invt_choose_rista .points_tick ul li:last-child {
    margin-bottom: 0;
}
section.sec_space.know_your_Inventry.set-know-invt {
    padding-bottom: 52px;
    padding-top: 96px;
}
.set-know-invt .hm_heading h3 {
    font-size: 42px;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0;
    margin-bottom: 80px;
}
.set-know-invt  .knowyour_grid {
    padding-top: 56px;
}
.set-know-invt .know_box h4 {
    font-weight: 600;
}
.set-know-invt .know_box p {
    font-family: "Inter", sans-serif;
    font-weight: 400;
}
section.footer-bar-up.set_invt-footerbar {
    padding-top: 120px;
    padding-bottom: 120px;
}
.set_invt-footerbar .subheading {
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: -2px;
    /* margin-bottom: 73px; */
    margin-right: 0;
}
.footer-bar-up.set_invt-footerbar .heading {
    font-size: 17px;
    font-weight: 500;
    line-height: 30px;
    opacity: 1;
    max-width: 450px;
}
.set-invt-control-section .takecon_infos ul {
    max-width: 530px;
}
h3.cos-text {
    color: #fff;
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 30px;
}
h3.cos-text {
    color: #fff;
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 36px;
    line-height: 48px;
}

.inventory-box-heading {
    font-size: 20px;
    font-weight: 600;
    line-height: 27px;
    margin-bottom: 0;
    min-height: 70px;
}

.inventory-box-pera {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
}

.inventory-colum {
    margin: auto;
    margin-top: 50px;
}

.inventory-box {
    margin-bottom: 64px;
    max-width: 309px;
}

.mobile-design-accordian .card-header .title {
    color: #000;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
}
.mobile-design-accordian .card-header .accicon {
    float: right;
    font-size: 30px;
    width: 1.2em;
    width: 8px;
    color: #fff;
    height: 19px;
    position: relative;
    top: -11px;
}

.mobile-design-accordian .card-header .accicon b.minus_icon {
    font-size: 24px;
    color: #000;
}

.mobile-design-accordian .card-header {
    cursor: pointer;
    border-bottom: none;
    padding-top: 31px;
    padding-bottom: 31px;
    padding-left: 23px;
    padding-right: 23px;
    background: none;
}
.mobile-design-accordian .card {
    border: none;
    border-bottom: 1px solid #E2E2E2 !important;
    background: none;
}
.mobile-design-accordian .card:last-child {
    border-bottom: none !important;
}
.mobile-design-accordian .card-body {
    border-top: none;
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #fff;
    font-family: "Inter", sans-serif;
    padding-bottom: 30px;
    padding-top: 0;
    padding-left: 23px;
    padding-right: 23px;
}
.mobile-design-accordian .card-body strong, .mobile-design-accordian .card-body b, .mobile-design-accordian .card-body li, .mobile-design-accordian .card-body p, .mobile-design-accordian .card-body span, .mobile-design-accordian .card-body a {
    font-weight: 400;
    margin-bottom: 28px;
    color: #000;
}
.mobile-design-accordian .card-header:not(.collapsed) .rotate-icon {
  transform: rotate(180deg);
}
.mobile-design-accordian .card-header span.accicon .plus_icon {
    display: none;
}
.mobile-design-accordian .card-header.collapsed span.accicon b.minus_icon {
    display: none;
}
.mobile-design-accordian .card-header.collapsed span.accicon b.plus_icon {
    display: inline-block;
    font-weight: 400;
    color: #000;
}
.mobile-design-accordian .card-body strong {
    text-decoration: none;
    color: #000;
    font-weight: 700;
}
.mobile-design-accordian .card-body ul {
    list-style: none;
    padding: 0;
}
.mobile-accord-img {
    padding-top: 8px;
}
/* .mobile-design-accordian{
    display: none;
} */
.mobile-accord-img img {
    max-width: 100%;
}
.product-suit {
    font-size: 16px;
    font-weight: 500;
    /* line-height: 69px; */
    text-align: center;
    display: none;
    margin-bottom: 15px;
}
.tabs-mobile-image {
    display: none;
}
.tabs-mobile-image img {
    max-width: 100%;
}
.tab-heading-for-mobile {
    display: none;
}
.accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link.collapsed {
    padding-right: 30px;
    position: relative;
    z-index: 1;font-weight: 500;
}
.set_faq_section .accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link.collapsed::before {
    top: 52px;
    position: absolute;
    right: 0;
}
.set_faq_section .accordion_rista.accordion_rista2 .card .card-header .btn-header-link.collapsed::after {
    right: 0;
    position: absolute;
    top: 33px !important;
}
.set_faq_section .accordion_rista .card .card-header .btn-header-link {
    padding-right: 30px;
}
.set_faq_section .accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link::after {
    width: 30px;
    position: absolute;
    top: 52px;
}
.set_faq_section .accordion_rista.accordion_rista2 .card .card-header .btn-header-link::after {
    left: inherit;
    right: 0;
}

/* whatsapp page  */
.green-clr {
    color: #00C141;
    font-style: normal;
}

/* .set_whatsapp_banner_section .banner-heading span {
    font-size: 42px;
    font-weight: 800;
    line-height: 52px;
    letter-spacing: 0px;
    text-align: left;
    display: inline-block;
    margin-left: 0;
    color: #000000;
    margin-top: 6px;
    max-width: 100%;
} */
.set_whatsapp_banner_section img.banr-img.pc-imag {
    margin-top: 0;
}
.set_banner.set_whatsapp_banner_section {
    padding-bottom: 96px;
}
.set_logo_section.set_whatsapp_logo_section {
    margin-bottom: 155px;
}
.whats_customr.set_customer_monial {
    padding-top: 0 !important;
}
.set_whatsapptab_section .selected-image img {
    max-width: 100%;
}

.set_customer_monial .customers_starbox {
    padding: 59px 45px;
}
.set_customer_monial .customers_starbox p {
    font-size: 18px;
    font-weight: 600;
    line-height: 34px;
    text-align: left;
}
.whats_customr.set_customer_monial .sec-6 .whats_star {
    padding-bottom: 17px;
}
.whatstabimg_sec.set_whatsapptab_section {
    padding-top: 0;
}
.set_customer_monial .sec-6.deordr_owl_slides {
    padding-top: 50px;
    padding-bottom: 96px;
}
.set_whatsapptab_section .hm_heading.hm_heading_big h3 {
    font-size: 42px;
    font-weight: 800;
    line-height: 52px;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 64px;
    padding-bottom: 0;
    max-width: 786px;
}
.set_whatsapptab_section .accordion_rista .card {
    margin-bottom: 20px;
}
.set_whatsapptab_section .accordion_rista .card .card-header .btn-header-link.collapsed::after {
    top: 19px;
    font-size: 31px;
}
.set_whatsapptab_section .accordion_rista .card .card-header .btn-header-link::after {
    font-size: 31px;
    top: 3px;
}
.set_whatsapptab_section .accordion_rista .card-body {
    padding-bottom: 33px;
}
.set_whatsapptab_section .accordion_rista .card:last-child {
    margin-bottom: 0;
}
.set_whatsapptab_section {
    padding-bottom: 96px;
}
section.sec_space.whats_connect.set_whatsapp_brand_section {
    padding-top: 96px;
    padding-bottom: 96px;
}
.set_whatsapp_brand_section .hm_heading h3 {
    margin-top: 0 !important;
    font-size: 36px;
    font-weight: 800;
    line-height: 48px;
    letter-spacing: 0;
    text-align: left;
    max-width: 780px !important;
}
.set_whatsapp_brand_section .hm_heading h5 {
    font-size: 20px;
    font-weight: 400;
    text-align: left;
    margin-top: 40px;
}
.set_whatsapp_brand_section .whatsapp_box {
    margin-top: 52px;
    margin-bottom: 46px;
}
.set_whatsapp_brand_section .whatsapp_box p {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: rgba(255,255,255,0.8);
}

.set_whatsapptab_section.set_whatsapptab_second_section {
    padding-top: 96px !important;
    padding-bottom: 96px;
}
.set_whatsapptab_second_section .hm_heading.hm_heading_big h3 {
    margin-bottom: 20px;
    max-width: 53%;
}
section.sec-5 .hm_heading p {
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    max-width: 38%;
    margin: 0 auto 0 auto;
}

.footer-bar-up.whatsapp_footer_bar .subheading {
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: -0.5px;
    text-align: left;
    margin-bottom: 47px;
}
.footer-bar-up.whatsapp_footer_bar h5.order-txt {
    font-family: "Plus Jakarta Sans", sans-serif !important;
    font-size: 17px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    max-width: 100%;
}
.footer-bar-up.whatsapp_footer_bar .form-group {
    margin-bottom: 1rem;
    margin-bottom: 24px;
}

.set_deliver_section .hm_heading.hm_heading_big p img {
    margin: 0 5px;
}
/* 24 june */
.client-name {
    display: block;
}
.client-name {
    position: absolute;
    left: 45px;
    bottom: 35px;
    text-align: left;
}
.client-name p {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    opacity: 0.6;
}

.client-name h5 {
    margin-bottom: 2px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
}
.set_big_slides_section .test-1 p {
    padding-bottom: 100px;
    margin-bottom: 0;
}

.set_big_slides_section .sec-6 .sec-2-item  {
    column-gap: 0;
    align-items: end;
}
.set_big_slides_section .sec-6 .sec-2-item .test-2 {
    width: 260px;
}
.set_big_slides_section .sec-6 .sec-2-item .test-2 img {
    height: 270px;
    width: 231px !important;
    object-fit: contain;
    left: 54px;
    position: relative;
    bottom: -12px;
}
.set_big_slides_section .inventery_mcrousel {
    max-width: 82%;
}
.pos-testimonial-slider .name_profile {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
}

.pos-testimonial-slider .name_profile span {
    font-weight: 500;
    color: #000;
    opacity: 0.6;
}
.pos-testimonial-slider  .sec-2-item.sec-2-item-1 {
    gap: 0;
    align-items: end;
    overflow: hidden;
    min-height: 377px;
}

.pos-testimonial-slider .sec-2-item.sec-2-item-1 .test-1 {
    min-height: 330px;
}

.pos-testimonial-slider .sec-2-item.sec-2-item-1 .test-2 {
    position: relative;
    left: 70px;
}
.sec-6 .pos-testimonial-slider .test-2 img {
    height: 243px;
    object-fit: contain;
    width: 260px !important;
    margin-right: -1px;
}
/* .inven_choose_rista.set_invt_choose_rista ul.nav.nav-pills {
    position: sticky;
    top: 98px;
    z-index: 2;
    background: #fff;
    padding: 20px;
} */
/* .set_invt_choose_rista.visible-section1 ul.nav.nav-pills {
    background: #333333;
    position: fixed;
    top: 97px;
    left: 0;
    right: 0;
    padding: 40px 0;
    z-index: 2;
}
.set_invt_choose_rista.visible-section1 ul.nav.nav-pills .nav-item a {
    color: #ffffff;
}
.set_invt_choose_rista.visible-section1 ul.nav.nav-pills .nav-item a.active {
    background: #ffffff;
    color: #000000;
    border: 1px solid #ffffff;
} */
.degi-name h5 {
    font-size: 16px;
    font-weight: 600 !important;
    line-height: 22px;
    margin-bottom: 0;
}

.degi-name p {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 22px !important;
    margin-bottom: 0;
    opacity: 0.8;
}

.degi-name {
    margin-top: 20px;
}

.whatsdegi-name {
    min-height: 205px;
}
/* 25 june */
p.tab_lastline a {
    margin-top: 18px !important;
    padding: 14px 40px !important;
    display: table !important;
    margin-left: 0 !important;
}

section.sec-8.faq_btm.set_faq_section .col-lg-7 {
    flex: 0 0 80%;
    max-width: 80%;
}

.outlets_tabsmain {
    position: relative;
    top: -150px;
}

span.wapbol {
    font-weight: 500;
}

.whats_connect .hm_heading span.wapbol.wapbol2 {
    color: #fff;
}

/*new css*/

.banner_direct_ordering.set_banner.banner_direct_ordering_new .banner-heading span {
    font-size: 36px;
    line-height: 46px;
    font-weight: 500;
    position: relative;
    top: -5px;
    color: #000;
}

section.sec5new.sec_space.set_deliver_section .hm_heading.hm_heading_big h3 {
    color: #000;
}

section.sec5new.sec_space.set_deliver_section .hm_heading.hm_heading_big p {
    color: #000;
}

.sec4newa_grid.do_delivr_grid {
    background: #F5F5F5;
    margin: 60px 0 0 0;
    padding: 0 65px 80px 65px;
}

.set_deliver_section .sec4newa_grid.do_delivr_grid .hm_whycards {
    background: none;
    border: none;
    padding: 0 15px;
}

.create_meanbr {
    display: none;
}

.sec-5.set_whatsapptab_section.deliver_accordians_mob {
    display: none;
}

/* .doyunow .douinfos p b{ font-weight: 400;} */


.mobile-design-accordian .card-body li {
    position: relative;
    z-index: 1;
    padding-left: 35px;
}

.mobile-design-accordian .card-body li:after {

    position: absolute;
    left: 7px;
    top: 4px;
    bottom: 0;
    right: 0;
    width: 8px;
    height: 17px;
    content: '';
    border-style: solid;
    border-color: transparent #000 #000 transparent;
    border-width: 0px 2px 2px 0px;
    z-index: 1;
    transform: rotate(40deg);
}



@media(min-width:1366px) and (max-width:1600px){
    .set_customer_monial .container {
        padding-right: 0;
        margin-right: 0;
        max-width: 1350px !important;
    }
}
@media(min-width:768px) and (max-width:1600px){
    img.prefer-imgg {
        max-width: 100%;
        max-width: inherit;
    }
}
@media(min-width:992px){
    img.banr-img.pc-imag {
        margin-top: 73px;
    }
}
@media(max-width:1400px){
    .whatsdegi-name {
        min-height: 170px;
    }
}
@media(max-width:1365px){
    .whatsdegi-name {
        min-height: 205px;
    }
}
@media(max-width:1366px){

.set_banner .banner-para {
    font-size: 14px;
    line-height: 24px;
}

.set_banner .banner-heading {
    font-size: 36px;
    line-height: 46px;
}


.outlets_tabsmain {
    position: relative;
    top: -100px;
}

.set_faq_section .accordion_rista .card .card-header .btn-header-link {
    font-size: 24px;
    padding: 26px 0px;
}

.banner.set_banner {
    padding: 56px 0 96px 0;
}

.hm_heading h3 {
    font-size: 36px;
    line-height: 46px;
}

.inverntry_m_review .hm_heading h3 {
    max-width: 75%;
}

.sec-3.set_invt_choose_rista .subheading {
    font-size: 36px;
    line-height: 46px;
}

.set-know-invt .hm_heading h3 {
    font-size: 36px;
    line-height: 46px;
    margin-bottom: 70px;
}

.set-know-invt .hm_heading h3 {
    font-size: 36px;
    line-height: 46px;
}

.set_elevate-section .hm_heading.hm_heading_big h2 {
    font-size: 36px;
    line-height: 46px;
}

.set_why_section .hm_heading.hm_heading_big h3 {
    font-size: 36px;
    line-height: 46px;
    letter-spacing: 0;
}

.set_deliver_section .hm_heading.hm_heading_big h4 {
    font-size: 36px;
    line-height: 46px;
}

.set_deliver_section .hm_heading.hm_heading_big p {
        font-size: 18px;
    line-height: 30px;
}

.set_adjust_section .hm_heading.hm_heading_big h5 {
    font-size: 36px;
    line-height: 46px;
}

.sec5new.dordr_adjuestsec .hm_heading p {
    font-size: 20px;
    line-height: 33px;
    max-width: 80%;
}

.set_whatsapp_banner_section .banner-heading span {
    font-size: 42px;
    line-height: 49px;
}
.set_whatsapptab_section .hm_heading.hm_heading_big h3 {
    font-size: 36px;
    line-height: 46px;
}

.set_whatsapp_brand_section .hm_heading h3 {
    font-size: 36px;
    line-height: 46px;
}

section.sec-5 .hm_heading p {
    font-size: 15px;
    line-height: 26px;
}

.footer-bar-up.whatsapp_footer_bar .subheading {
    font-size: 34px;
    line-height: 49px;
}

.set_banner .banner-heading {
    font-size: 42px;
    line-height: 49px;
}

.set_invt-footerbar .subheading {
    font-size: 34px;
    font-weight: 700;
    line-height: 49px;
    letter-spacing: -0.5px;
    /* margin-bottom: 73px; */
    margin-right: 0;
    max-width: 70%;
}

.set_deliver_section .hm_heading.hm_heading_big h3 {
    font-size: 38px;
    line-height: 46px;
}

}
@media(max-width:1199px){
    .set_banner .banner-heading {
        /* font-size: 38px;
        line-height: 48px; */
        margin-top: 15px;
    }
   
    .set_banner .banner-para {
        font-size: 15px;
        line-height: 26px;
        letter-spacing: 0px;
    }
    .set_banner .ban-bt a {
        font-size: 16px;
        padding: 16px 30px;
    }
    .set_elevate-section .hm_heading.hm_heading_big h2 {
        font-size: 38px;
        line-height: 48px;
    }
    .set_why_section .hm_heading.hm_heading_big h3 {
        font-size: 38px;
        line-height: 48px;
    }
    .set_deliver_section .hm_heading.hm_heading_big h4 {
        font-size: 38px;
        line-height: 48px;
    }
    .set_deliver_section .sec4newa_grid.do_delivr_grid .hm_whycards {
        padding: 25px;
        margin-top: 50px;
        min-height: 455px;
    }
    .set_deliver_section .hm_whycard_icon img {
        max-width: 50px;
    }
    .set_deliver_section .sec4newa_grid.do_delivr_grid .hm_whycards .hm_why_infos h4 {
        font-size: 20px;
        line-height: 28px;
    }
    .set_adjust_section .hm_heading.hm_heading_big h5 {
        font-size: 38px;
        line-height: 48px;
    }
    .set_faq_section .accordion_rista .card .card-header .btn-header-link {
        font-size: 24px;
    }
    .set_faq_section .accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link.collapsed::before {
        width: 22px;
        height: 3px;
    }
    /* .set_faq_section .accordion_rista.accordion_rista2 .card .card-header .btn-header-link.collapsed::after {
        width: 22px  !important;
        top: 17px !important;
    } */
    .set_faq_section .accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link.collapsed::before {
        right: 1px;
    }
    .accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link.collapsed::after {
        width: 24px;
    }
    .accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link::after {
        width: 24px;
        right: 0;
    }
    .set_logo_section.set_whatsapp_logo_section {
        margin-bottom: 125px;
    }
    .set_customer_monial .sec-6.deordr_owl_slides {
        padding-bottom: 125px;
    }
    .footer-bar-up.whatsapp_footer_bar .subheading {
        margin-right: 0;
    }
    .whatsdegi-name {
        min-height: 275px;
    }
}
@media(max-width:1034px){
      .banner_noimage.set_banner .banner-image.hmbanner_img{
        max-width: 100% ;
        padding-left: 0;
        padding-right: 0;
    }
}
@media(max-width:991px){
    .banner_noimage.set_banner .banner-image.hmbanner_img {
        max-width: 500px;
        margin-top: 15px;
    }
    .set_banner .banner-heading {
        font-size: 34px;
        line-height: 44px;
    }

    .set_elevate-section .hm_heading.hm_heading_big h2 {
        font-size: 34px;
        line-height: 44px;
    }
    .set_why_section .hm_heading.hm_heading_big h3 {
        font-size: 34px;
        line-height: 44px;
    }
    .set_deliver_section .hm_heading.hm_heading_big h4 {
        font-size: 34px;
        line-height: 44px;
        max-width: 65%;
    }
    .set_deliver_section .sec4newa_grid.do_delivr_grid .hm_whycards {
        min-height: auto;
    }
    .set_adjust_section .hm_heading.hm_heading_big h5 {
        font-size: 34px;
        line-height: 44px;
        max-width: 80%;
    }
    .set_faq_section .accordion_rista .card .card-header .btn-header-link {
        font-size: 20px;
    }
    .set_faq_section .accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link::after {
        width: 22px;
    }
    .banner.set_banner.set_inventory_banner {
        padding-bottom: 60px;
    }
    .set_invt_takecon_img img {
        margin: auto;
    }
    .set-invt-control-section .takecon_infos ul {
        max-width: 100%;
    }
    .point_img.point_noimg.tabs-desktop-image {
        margin-top: 20px;
        text-align: center;
    }
    .inven_choose_rista.set_invt_choose_rista {
        padding-top: 90px;
        padding-bottom: 90px;
    }
    section.footer-bar-up.set_invt-footerbar {
        padding-top: 110px;
        padding-bottom: 110px;
    }
    .set_faq_section .accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link.collapsed::before {
        right: 0px;
    }
    .set_customer_monial .hm_heading.hm_heading_big h3 {
        font-size: 34px;
        line-height: 44px;
    }
    .set_logo_section.set_whatsapp_logo_section {
        margin-bottom: 95px;
    }
    .set_customer_monial .customers_starbox p {
        font-size: 16px;
        line-height: 30px;
    }
    .set_customer_monial .customers_starbox {
        padding: 39px 25px;
    }
    .set_customer_monial .sec-6.deordr_owl_slides {
        padding-bottom: 100px;
    }
    .set_whatsapptab_section .hm_heading.hm_heading_big h3 {
        font-size: 34px;
        line-height: 44px;
    }
    .set_whatsapptab_section.set_whatsapptab_second_section {
        padding-top: 100px !important;
        padding-bottom: 100px;
    }   
    section.footer-bar-up.whatsapp_footer_bar {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .footer-bar-up.whatsapp_footer_bar .subheading {
        font-size: 34px;
        line-height: 44px;
    }
    section.sec-8.faq_btm.set_faq_section {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .set-know-invt .hm_heading h3 {
    margin-bottom: 30px;
}

section.sec-8.faq_btm.faq_btm_pros .col-lg-8 {
    flex: 0 0 100%;
    max-width: 100%;
}

section.sec-8.faq_btm.set_faq_section .col-lg-7 {
    flex: 0 0 100%;
    max-width: 100%;
}


}
@media(max-width:767px){
    .ban-cta {
        margin-bottom: 10px;
    }
    .set_big_slides_section .sec-6 .test-img {
        bottom: 150px;
        top: auto;
    }
    .set_faq_section .accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link.collapsed::before {
        top: 33px;
        height: 2px;
    }
    .set_faq_section .accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link::after {
        top: 48px !important;
    }
    .set_faq_section .accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link.collapsed::after {
        top: 33px !important;
    }
    .set_invt_choose_rista .point_box {
        border-bottom: none;
    }
    .set_banner.set_inventory_banner span.banner-subheading.home_banner_subheading {
        font-size: 17px;
    }
    .set_invt_review_section .inventery_mcrousel {
        max-width: 100%;
    }
    .set_invt_review_section .sec-6 .test-img {
        bottom: 150px;
        top: auto;
    }
    .inverntry_m_review .sec-6 .sec-2-item {
        gap: 40px;
        flex-direction: inherit;
    }
    .sec-3.set_invt_choose_rista .subheading {
        font-size: 30px;
        line-height: 44px;
    }
    .sec-3.set_invt_choose_rista .nav.nav-pills {
        margin-top: 0;
    }
    section.sec-3.sec_space.sec3_home.inven_choose_rista.set_invt_choose_rista {
        background: #fff;
    }
    .inventory-box {
        margin-bottom: 60px;
        max-width: 100%;
    }
    .inventory-box-heading {
        margin-bottom: 26px;
        min-height: auto;
    }
    .set_invt-footerbar .subheading {
        font-size: 38px;
        line-height: 48px;
        max-width: 100%;
    }
    section.banner.banner_home.set_inventory_banner .banner-heading {
        max-width: 100%;
    }

   .sec-5.set_whatsapptab_section .accordion_rista .card .card-header .btn-header-link {
    padding: 28px 0;
    background: none !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    padding-right: 25px;
}
    .set_whatsapptab_section .accordion_rista .card .collapse.show {
        background: none;
        line-height: 30px;
        color: #222;
    }
    .set_whatsapptab_section .accordion_rista .card .card-header {
        background: none;
        box-shadow: none ;
    }
    .set_whatsapptab_section .accordion_rista .card {
        border: none !important;
        border-bottom: 1px solid rgb(0 0 0 / 12%) !important;
        border-radius: 0 !important;
        margin-bottom: 0;
    }
    .set_whatsapptab_section .accordion_rista .card .card-header .btn-header-link.collapsed::after {
        top: 29px;
        right: 0;
        left: 96%;
    }
    .set_whatsapptab_section .accordion_rista .card .card-header .btn-header-link::after {
        top: 29px;
        right: 0;
        left: 96%;
    }
    .set_whatsapptab_section .accordion_rista .card .card-header .btn-header-link {
        color: #000;
    }
   .set_whatsapptab_section .accordion_rista .card-body {
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: rgb(0 0 0 / 80%);
    margin-bottom: 24px;
}
    .set_whatsapptab_section .sec-5-img.show-1 {
        margin-top: 28px;
    }
    .set_whatsapptab_section .accordion_rista .card:last-child{
border: none  !important;
    }
    .set_whatsapp_brand_section .whatsapp_grid .take_conbtn.whatsapp {
        display: none;
    }
    .set_whatsapp_banner_section span.banner-subheading.home_banner_subheading {
        width: auto;
        display: inline-block;
    }
    .set_whatsapp_brand_section .whatsapp_box {
        margin-top: 25px;
        margin-bottom: 0;
    }
 .footer-bar-up .subheading {
    max-width: 310px;
}
    .set_whatsapp_banner_section .banner-heading span {
        display: inline;
        margin-left: 7px;
    }
    section.banner.banner_home .banner-heading {
        max-width: 80%;    
    }
    .set_logo_section.set_whatsapp_logo_section {
        margin-bottom: 75px;
    }
    .set_customer_monial .hm_heading.hm_heading_big h3 {
        font-size: 30px;
        line-height: 40px;
    }
    .set_customer_monial .sec-6.deordr_owl_slides {
        padding-top: 55px;
        padding-bottom: 70px;
    }
    .set_whatsapptab_section .hm_heading.hm_heading_big h3 {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 50px;
    }
    section.sec_space.whats_connect.set_whatsapp_brand_section {
        padding-bottom: 62px;
    }
    .set_whatsapptab_section.set_whatsapptab_second_section {
        padding-top: 70px !important;
        padding-bottom: 50px;
    }
    section.footer-bar-up.whatsapp_footer_bar {
        padding-top: 70px;
        padding-bottom: 90px;
    }
    section.sec-8.faq_btm.set_faq_section {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link.collapsed {
        padding-right: 30px;
        position: relative;
        z-index: 1;
        padding-left: 0;
    }

    img.banr-img {
        max-width: 100%;
        width: 70%;
        margin: 0 auto;
    }

    .set-inventory-logo-section p.para.hmpara_btm {
        margin-bottom: 0;
        margin-top: 30px;
    }
    .mobile-design-accordian .card-header span.accicon 
    b.minus_icon {
       position: relative;
       margin-top: -20px;
       /* display: inline-block; */
       top: -16px;
   }
   
   .whats_customr .sec-6 .whats_star img {
    width: 16px;
}

.sec-5.set_whatsapptab_section .accordion_rista .card .card-header a.btn.btn-header-link:after {

    top: 11px;
}

.sec-5.set_whatsapptab_section .accordion_rista .card .card-header a.btn.btn-header-link.collapsed:after {
    top: 31px;
}
/* 24 june  */
.set_big_slides_section .sec-6 .sec-2-item .test-1 .client-img {
    position: relative;
    width: 110px !important;
    height: auto;
    bottom: 25px;
    margin: 0;
}
.set_big_slides_section .test-1 p {
    padding-bottom: 0;
}
.set_big_slides_section .sec-6 .sec-2-item {
    gap: 0;
}
.set_big_slides_section .inventery_mcrousel {
    max-width: 100%;
}

.inverntry_m_review.set_invt_review_sectionn.set_big_slides_section .owl-nav {
    width: 100px;
    z-index: 1;
    margin-top: 15px;
}

.inverntry_m_review.set_invt_review_sectionn.set_big_slides_section .owl-nav button {
    border: 2px solid #000 !important;
    height: 40px;
    width: 40px;
}

.inverntry_m_review.set_invt_review_sectionn.set_big_slides_section .owl-nav button span {
    line-height: normal;
    font-size: 37px;
    top: -9px;
    color: #000 !important;
}
.inverntry_m_review.set_invt_review_sectionn.set_big_slides_section .owl-dots {
    position: relative;
    right: 0;
    text-align: left;
    top: 30px;
}
.inverntry_m_review.set_invt_review_sectionn.set_big_slides_section .owl-dots .owl-dot span {
    background: #C7C7C7;
}
.pos-testimonial-slider .test-1 .client-img {
    position: relative;
}
.sec-6 .pos-testimonial-slider .test-img {
    bottom: 40px;
}
.sec-6 .pos-testimonial-slider .name_profile {
    padding: revert-layer;
    position: relative;
    top: 100px;
}
.whatsdegi-name {
    min-height: 215px;
}

section.sec3new_upsec.sec_space.set_big_slides_section .owl-carousel.owl-loaded {
    background: none;
    border-radius: 12px;
}


section.sec3new_upsec.sec_space.set_big_slides_section .sec-6.deordr_owl_slides .owl-slider {
    overflow: inherit;
    border: none;
}

section.sec3new_upsec .sec-6 .sec-2-item {
    margin-top: 0;
    border: solid 1px #CECECE;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 20px;
    border-right: solid 1px #CECECE;
    padding: 20px 30px 0 30px;
}

section.sec3new_upsec.sec_space .sec-6.deordr_owl_slides {
    background: none;
    padding: 0 10px;
}
.mobile-design-accordian .card-header {
    padding-left: 21px;
    padding-right: 28px;
}

.set_invt_choose_rista .tab-content {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 0;
}

section.banner.banner_home.set_banner {
    padding-top: 52px;
}

    .hm_heading h1 span, .hm_heading h3 {
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -1px;
}

.sec4newa_grid.do_delivr_grid {
    margin: 40px 0 0 0;
    padding: 0 30px 80px 30px;
    display: none;
}

.create_meanbr {
    display: none;
}

.sec-5.set_whatsapptab_section.deliver_accordians_mob {
    padding-top: 0;
    padding-bottom: 0;
}

.sec-5.set_whatsapptab_section.deliver_accordians_mob {
    display: block;
}

.doyunow {
    text-align: center;   margin-top: 120px;
}


.doyunow .dou_heading {
    font-size: 22px;
}

.doyunow .douinfos p {
    font-size: 18px;
    color: #4c4c4c;
    margin: 10px auto 0 auto;
    max-width: 90%;
}

.doyunow .douinfos p b{ font-weight: 700;}

.set_banner.directnewmob .banner-heading {
    font-size: 33px;
    line-height: 41px;
}

.set_banner.directnewmob .banner-heading span {
    font-size: 32px !important;
    line-height: 41px !important;
}

.hmtabs_mainbox_redesign .points_tick {
    padding: 0 0 30px 0;
}

section.sec-5 .hm_heading p {
    max-width: 90%;
}

section.footer-bar-up.set_invt-footerbar .subheading {
    border-bottom: 1px dashed rgba(255,255,255,0.5);
    padding-bottom: 30px;
}


section.footer-bar-up.pos_footer_page .heading {
    max-width: 100%;
}

.doyunow_starline p {
    text-align: center;
    line-height: 17px;
}


}
@media(max-width:576px){
    .mobile-imag{
        display: block;
    }
    .pc-imag{
        display: none;
    }
 section.banner.banner_home.set_banner {
    padding-top: 52px;
}



    .set_banner  span.home_banner_subheading {
    margin-bottom: 20px;
}
.set_banner .banner-para {
    padding-top: 24px !important;
    line-height: 20px;
    padding-bottom: 36px !important;
    font-size: 14px;
}
.sec-2.set_logo_section .container-fluids {
    flex-direction: column;
    gap: 0px;
}


section.sec-2.set_logo_section {
    margin-bottom: 72px;
    padding-right: 17px;
    padding-left: 17px;
}

.sec_space.set_elevate-section {
    padding-top: 52px;
}
.set_elevate-section .hm_heading.hm_heading_big h5 {
    margin-bottom: 10px;
}
.set_elevate-section .hm_heading_big h3 {
    font-size: 28px;
    line-height: 34px;
    font-weight: 700;
    margin-bottom: 32px;
}
.set_elevate-section .hm_heading.hm_heading_big p {
    line-height: 20px;
}
.set_elevate-section a.hm_fillbtn {
    margin-bottom: 14px;
}
section.sec3new.sec_space.direct_ordr_most.set_elevate-section {
    padding-bottom: 52px;
}
.set_big_slides_section img.client-img {
    position: relative !important;
    width: 100% !important;
    bottom: auto;
    margin: auto;
}
.set_big_slides_section .sec-6 .test-img {
    right: auto;
    top: auto;
    bottom: 70px;
    left: 20px;
}
.client-name {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    text-align: left;
}
.set_big_slides_section .test-2 {
    position: relative;
}
.client-name h5 {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 0;
}
.client-name p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}
.set_big_slides_section .sec-6 .test-2 img {
    height: 221px;
}
.set_big_slides_section .test-1 img.client-img {
    margin-bottom: 20px;
}
.set_big_slides_section  .set_big_slides_section .test-1 p {
    font-weight: 500;
}
.set_big_slides_section .sec-6 .sec-2-item {
    padding-top: 20px;
}
.sec_space.set_why_section {
    padding-top: 52px;
    margin-top: 0px;
}
.set_why_section .hm_heading.hm_heading_big h3 {
    font-size: 28px;
    line-height: 125%;
    font-weight: 700;
}
.why-imgg {
    border: 3px solid #494949;
    border-radius: 12px;
}
.whyrow {
    column-gap: 16px;
    margin-top: 44px;
    margin-bottom: 24px;
    margin-left: -92px;
}
.set_why_section .hm_heading .dordr_whysec_infos p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    max-width: 80%;
}
.set_why_section .dordr_whysec_infos {
    margin-bottom:0px;
}
.set_deliver_section .hm_heading.hm_heading_big h3 {
    font-size: 28px;
    line-height: 34px;
    font-weight: 700;
    margin-bottom: 32px;
}
section.sec5new.sec_space.set_deliver_section {
    padding-top: 0;
    padding-bottom: 36px;
    margin-bottom: 52px;
}
.set_deliver_section .hm_heading.hm_heading_big h3  small {
    font-weight: 400;
    display: inline-block;
}
.set_deliver_section .hm_heading.hm_heading_big p {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    max-width: 80%;
}
.set_deliver_section .hm_heading.hm_heading_big {
    margin-bottom: 52px;
}

.set_deliver_section .sec4newa_grid.do_delivr_grid .hm_whycards {
    margin-top: 0;
    margin-bottom: 16px;
    padding: 24px 23px;
    padding-bottom: 23px;
    position: relative;
}
.set_deliver_section .sec4newa_grid.do_delivr_grid .hm_whycards .hm_why_infos ul li {
    font-size: 14px;
    line-height: 20px;
    min-height: 24px;
}
.set_deliver_section .sec4newa_grid.do_delivr_grid .hm_whycards .hm_why_infos ul li {
    margin: 0px 0 15px 0;
}

.set_deliver_section .hm_whycard_icon img {
    max-width: 38px;
}
.set_deliver_section .hm_whycard_icon {
    margin-bottom: 30px;
    position: absolute;
    right: 23px;
}
.set_deliver_section .sec4newa_grid.do_delivr_grid .hm_whycards .hm_why_infos h4 {
    font-size: 18px;
    line-height: 25px;
    font-weight: 600;
    max-width: 55%;
}
.set_adjust_section .hm_heading.hm_heading_big h3 {
    font-size: 28px;
    line-height: 34px;
    font-weight: 700;
    margin-bottom: 24px;
}
.sec5new.dordr_adjuestsec.set_adjust_section .hm_heading p {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    max-width: 340px;
}

.dordr_adjust_left {
    padding: 0 14px;
}

.dordr_adjust_list h4 {
    margin: 0 0 12px 0;
}

.set_adjust_section .hm_heading.hm_heading_big {
    margin-bottom: 36px;
}
.set_adjust_section .adjust_grid .row {
    flex-direction: column-reverse;
}
.set_adjust_section .dordr_img {
    margin-bottom: 36px;
}
.set_adjust_section  .dordr_adjust_list h4 {
    font-weight: 600;
    line-height: 20px;
    font-size: 15px;
}
.set_adjust_section .dordr_adjust_list p {
    font-size: 12px;
    line-height: 20px;
    margin: 0 0 24px 0;
    font-weight: 400;
}
.set_adjust_section .dordr_adjust_list {
    padding: 0;
    margin-bottom: 0;
    border: none;
}
.set_second_logo_section .para.hmpara_btm {
    margin-top: 0;
}
section.sec5new.sec_space.set_adjust_section {
    padding-bottom: 48px;
}
section.sec-2.set_second_logo_section .container-fluids {
    flex-direction: column;
}
section.sec-2.set_second_logo_section {
    padding-right: 0;
}
section.sec-2.set_second_logo_section p.para.hmpara_btm {
    padding-right: 24px;
}
section.sec-2.set_second_logo_section {
    margin-bottom: 52px;
}
section.footer-bar-up.set_fbar_up_section {
    padding-top: 46px;
    padding-bottom: 52px;
}
.set_fbar_up_section h2.subheading {
    line-height: 28px;
    letter-spacing: -0.5px;
    margin-bottom: 24px;
    max-width: 80%;
}
section.footer-bar-up.set_fbar_up_section .heading {
    line-height: 22px;
    padding-bottom: 42px;
    margin-bottom: 42px;
}
.footer-bar-up.set_fbar_up_section h4 {
    font-size: 28px;
    line-height: 36px;
    font-weight: 800;
    letter-spacing: -0.5px;
}
.set_fbar_up_section .right-side {
    border-left: 0;
}
.footer-bar-up.set_fbar_up_section  .right-side .form-control {
    border: 1px solid #FFFFFF;
}
.footer-bar-up.set_fbar_up_section .form-group {
    margin-bottom: 20px;
}
section.sec-8.faq_btm.set_faq_section {
    padding-top: 52px;
    padding-bottom: 48px;
}
section.sec-8.faq_btm.set_faq_section .subheading {
    line-height: 125%;
    margin-bottom: 2px;
    max-width: 380px;
    margin: 0 auto 20px auto;
}
.set_faq_section .accordion_rista .card .card-header .btn-header-link {
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    max-width: 90%;
    padding-bottom: 27px;
    padding-left: 0 !important;
    padding-right: 0 !important;
}
/* .set_faq_section .accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link::after {
    width: 16px;
    right: -20px !important;
    top: 9px !important;
} */
.set_faq_section .accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link::after {
    width: 16px;
    right: -25px !important;
    position: absolute;
    top: 7px !important;
}
.set_faq_section .accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link.collapsed::after {
    top: 29px !important;
    right: -26px !important;
    width: 18px !important;
}
.set_faq_section .accordion_rista.accordion_rista2 .card-body {
    padding-left: 0;
}
.set_faq_section .accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link.collapsed::before {
    top: 29px;
    right: -25px;
    width: 16px;
}
.set_faq_section .card-body p, .set_faq_section .card-body li {
    font-size: 14px !important;
    line-height: 23px;
    font-weight: 400;
}
.set_why_section .container {
    padding: 0;
}

.set_why_section .dordr_whysec_infos {
    padding-left: 15px;
    padding-right: 15px;
}

.set_why_section .hm_heading.hm_heading_big h5, .set_why_section .hm_heading.hm_heading_big h3 {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 340px;
    margin: 5px auto 0 auto;
}

.set_banner  .banner-mobile {
    margin-top: 20px;
    align-items: baseline;
}

.set_elevate-section .hm_heading.hm_heading_big h2 {
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0;
    margin-bottom: 30px;
    max-width: 340px;
}
.set_deliver_section .hm_heading.hm_heading_big h4 {
    max-width: 100%;
    margin: auto;
    margin-bottom: 18px;
    font-size: 28px;
    font-weight: 700;
    line-height: 125%;
    max-width: 370px;
}
.set_deliver_section .hm_heading.hm_heading_big h4 small {
    font-weight: 400;
    text-transform: uppercase;
}
.set_adjust_section .hm_heading.hm_heading_big h5 {
    max-width: 340px;
    margin: auto;
    margin-bottom: 24px;
    font-size: 28px;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: 0;
}
.set_adjust_section .dordr_adjust_list a {
    margin-bottom: 36px ;
    display: inline-block;
    font-size: 14px;
}
section.sec3new_upsec.sec_space.set_big_slides_section .owl-carousel .owl-stage-outer {
    border-radius: 12px;
    bottom: -24px;
}
section.sec3new_upsec.sec_space.set_big_slides_section .owl-carousel.owl-loaded {
    background: none;
    border-radius: 12px;
}
.set_big_slides_section .sec-6 .sec-2-item {
    padding-top: 0px;
}
.set-know-invt .hm_heading h3 {
    font-size: 28px;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: 0;
    text-align: left;
    max-width: 80%;
    margin-left: 0;
    margin-right: auto;
}
section.sec_space.know_your_Inventry.set-know-invt {
    padding-top: 52px;
    padding-bottom: 35px;
}
.inventory-colum {
    margin-top: 42px;
}
.inventory-box-heading {
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
   
    margin-bottom: 16px;
    min-height: auto;
}
.inventory-box-pera {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}
.inventory-box {
    margin-bottom: 36px;
    max-width: 100%;
}
.col-md-4:last-child .inventory-box {
    margin-bottom: 0;
}
section.footer-bar-up.set_invt-footerbar {
    padding-top: 46px;
    padding-bottom: 66px;
}
h3.cos-text {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0;
    max-width: 90%;
}
.set_invt-footerbar .subheading {
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -1px;
}
.footer-bar-up.set_invt-footerbar .heading {
    max-width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    padding-bottom: 30px;
    margin-bottom: 30px;
}
.footer-bar-up.set_invt-footerbar h4 {
    font-size: 24px;
    font-weight: 800;
    line-height: 32px;
    letter-spacing: -0.5px;
    text-align: left;
}
.footer-bar-up.set_invt-footerbar h5 {
    max-width: 222px;
    margin: 0px 0 36px;
}
.footer-bar-up.set_invt-footerbar .heading {
    border-bottom: 1px dashed rgba(255,255,255,0.5);
}
/* inventory page  */
.set_banner span.banner-subheading.home_banner_subheading {
    font-size: 14px;
}
.banner_noimage.set_banner .banner-image.hmbanner_img {
    margin-top: 0;
}
.set-inventory-logo-section .container-fluids {
    flex-direction: column-reverse !important;
}
.set_banner .ban-bt a:last-child {
    margin-bottom: 0;
}
.banner.set_banner.set_inventory_banner {
    padding-bottom: 72px;
}
.set-inventory-logo-section p.para.hmpara_btm {
    margin-bottom: 0;
}
section.sec3new.sec_space.direct_ordr_most.inverntry_m_review.set_invt_review_section {
    background: #F5F5F5;
    padding-top: 48px;
    position: relative;
    z-index: 1;
}
.set_invt_review_section .inventery_mcrousel {
    max-width: 100%;
}
.inverntry_m_review.set_invt_review_section .sec-6 .sec-2-item {
    border-radius: 12px;
    gap: 40px;
    flex-direction: initial;
    padding-top: 20px;
}
.set_invt_review_section .test-2 {
    position: relative;
}
.set_invt_review_section .sec-6 .test-img {
    right: auto;
    top: auto;
    bottom: 70px;
    left: 20px;
}
.set_invt_review_section img.client-img {
    position: relative !important;
    width: 100% !important;
    bottom: auto;
    margin: auto;
}
.set_invt_review_section .sec-6 .sec-2-item {
    padding-top: 0px;
}
.set_invt_review_section .test-1 img.client-img {
    margin-bottom: 20px;
}
.set_invt_review_section .owl-carousel .owl-nav {
    width: 100px !important;
    bottom: -20px !important;
    z-index: 1 !important;
}
.set_invt_review_section  .owl-carousel .owl-nav button {
    border: 2px solid #000 !important;
    height: 40px;
    width: 40px;
}
.set_invt_review_section  .owl-carousel .owl-nav button span {
    line-height: normal;
    font-size: 37px;
    top: -9px;
}
.set_invt_review_section .inventery_mcrousel .sec-6 .owl-theme .owl-nav button span {
    color: #000 !important;
}
.set_invt_review_section .inventery_mcrousel .sec-6 .owl-theme .owl-nav button {
    border: solid 1px #000000 !important;
}
.set_invt_review_section  .owl-theme .owl-dots .owl-dot span {
    background: #C7C7C7 !important;
}
.set_invt_review_section .owl-theme .owl-dots .owl-dot.active span {
    background: #000 !important;
}
.set_invt_review_section .sec-6 .owl-dots {
    bottom: -60px;
}
section.sec_space.take_control_sec.set-invt-control-section {
    margin-top: 21px;
    padding-top: 52px;
    padding-bottom: 25px;
}
section.sec3new.sec_space.direct_ordr_most.inverntry_m_review.set_invt_review_section:before {
    background: #fff;
    width: 100%;
    height: 55%;
    bottom: 0;
    z-index: 0;
    position: absolute;
    content: "";
}
.set-invt-control-section .hm_heading h3 {
    font-size: 28px;
    line-height: 125%;
    font-weight: 700;
    margin-bottom: 0;
}
.mobile-design-accordian{
    display: block;
}
.desktop-design-slides{
    display: none;
}
.set-invt-control-section .take_conbtn {
    display: none;
}
 .take_control_sec.set-invt-control-section .hm_heading {
    margin-bottom: 61px;
}
.set-invt-control-section .take_con_left {
    padding-bottom: 0;
}
.mobile-design-accordian .card:first-child .card-header {
    padding-top: 0;
}
.col-md-6.mobile-pading-empty {
    padding: 0;
}
.sec-3.set_invt_choose_rista .subheading {
    font-size: 28px;
    line-height: 125%;
    letter-spacing: 0;
}
.product-suit {
    display: block;
}
.inven_choose_rista.set_invt_choose_rista {
    padding-top: 60px;
    background: #fff;
    padding-bottom: 42px;
}
.sec-3.set_invt_choose_rista .subheading {
    margin-bottom: 42px;
}
.sec-3 .nav.nav-pills {
    margin: 42px 0px 24px 23px;
}
.set_invt_choose_rista .tab-content {
    padding-left: 7px;
    padding-right: 7px;
    margin-top: 0;
}
.set_invt_choose_rista .point_box h3 {
    font-weight: 700;
    margin-bottom: 24px;
}
.tabs-mobile-image {
    display: block;
    margin-bottom: 24px;
}
.tabs-desktop-image {
    display: none;
}
.tab-heading-for-mobile {
    display: block;
}
.point_box .tab-heading-for-mobile {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    line-height: normal;
    margin-bottom: 8px;
}
.set_invt_choose_rista .points_tick ul li {
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 24px;
}
.set_invt_choose_rista .point_box p {
    max-width: 100%;
}
.set_whatsapp_banner_section .banner-heading span {
    display: inline;
    font-size: 28px;
    line-height: 125%;
    margin-left: 7px;
    font-weight: 500;
}
.set_banner.set_whatsapp_banner_section {
    padding-bottom: 52px !important;
}
.direct_ordr_most.whats_customr.set_customer_monial {
    background: #F5F5F5 !important;
    padding-top: 52px !important;
    padding-bottom: 52px !important;
}
.set_customer_monial .hm_heading.hm_heading_big h3 {
    font-size: 28px;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: 0;
    text-align: center;
    max-width: 60%;
}
.set_customer_monial .sec-6.deordr_owl_slides {
    padding-top: 36px;
    padding-bottom: 0;
}
.set_customer_monial .customers_starbox {
    background: #fff;
    padding: 20px;
}
.set_customer_monial .customers_starbox p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}
.set_whatsapptab_section .hm_heading.hm_heading_big h3 {
    max-width: 286px;
    font-size: 28px;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: 0;
    text-align: center;
}
.whatstabimg_sec.set_whatsapptab_section {
    padding-top: 52px;
    padding-bottom: 22px;
}
.set_whatsapptab_section .accordion_rista .card .card-header .btn-header-link.collapsed::after {
    left: 94%;
}
.set_whatsapptab_section .accordion_rista .card .card-header .btn-header-link::after {
    top: 30px;
    left: 94%;
}
.set_whatsapptab_section .hm_heading.hm_heading_big h3 {
    margin-bottom: 24px;
}
.mobile-pad-none {
    padding: 0 !important;
}
.set_whatsapptab_section .accordion_rista .card {
    padding-left: 13px;
    padding-right: 13px;
}
section.sec_space.whats_connect.set_whatsapp_brand_section {
    padding-top: 52px;
    padding-bottom: 52px;
}
section.sec_space.whats_connect.set_whatsapp_brand_section .hm_heading h3 {
    font-size: 28px;
    font-weight: 340;
    line-height: 125%;
    letter-spacing: 0;
    text-align: center;
    max-width: 340px !important;
    margin-left: auto;
    margin-right: auto;
}
section.sec_space.whats_connect.set_whatsapp_brand_section .hm_heading h3 b {
    font-weight: 700;
}
.set_whatsapp_brand_section .hm_heading h5 {
    background: #1F1F1F;
    border-radius: 11px;
    padding: 18px 17px;
    font-size: 14px;
    font-weight: 500;
    line-height: 23px;
    text-align: center;
    max-width: 260px;
    margin: 42px   auto;
}
.set_whatsapp_brand_section .whatsapp_box h4 {
    font-size: 15px;
    font-weight: 600;
    line-height: normal;
    text-align: left;
}
.set_whatsapp_brand_section .whatsapp_box p {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
}
.set_whatsapp_brand_section .whatsapp_box {
    margin-top: 0;
    margin-bottom: 24px;
    padding-left: 9px;
    padding-right: 9px;
    border-bottom: 1px solid #5A5A5A;
}
.set_whatsapp_brand_section .col-md-4:last-child .whatsapp_box {
    margin-bottom: 0px;
    border-bottom: none;
}
.set_whatsapp_brand_section .col-md-4:last-child .whatsapp_box  p{
    margin-bottom: 0px;
}
.set_whatsapptab_section.set_whatsapptab_second_section {
    padding-top: 72px !important;
    padding-bottom: 64px;
}
section.sec-5.set_whatsapptab_second_section .hm_heading p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    max-width: 340px;
    margin: 0 auto 24px;
}
section.footer-bar-up.whatsapp_footer_bar {
    padding-top: 52px;
    padding-bottom: 52px;
}
.footer-bar-up.whatsapp_footer_bar .subheading {
    margin-bottom: 26px;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.5px;
    max-width: 70%;
}
.footer-bar-up.whatsapp_footer_bar h5.order-txt {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 0;
    padding-bottom: 30px;
    border-bottom: 1px dashed rgb(255 255 255 / 50%);
    margin-bottom: 0px;
    font-family: "Manrope", sans-serif !important;
    padding-right: 22%;
}
section.footer-bar-up.whatsapp_footer_bar h4 {
    font-size: 28px;
    font-weight: 800;
    line-height: 125%;
    letter-spacing: -0.5px;
}
section.footer-bar-up.whatsapp_footer_bar h5 {
    margin: 28px 0 32px;
}
.monial-container-p0{
    padding-left: 0  !important;
    padding-right: 0 !important;
}
/* 24 june  */
.set_big_slides_section .sec-6 .sec-2-item .test-1 .client-img {
    width: auto !important;
    max-height: 55px;
    bottom: 0;
    margin-bottom: 15px;
}
.set_big_slides_section .sec-6 .sec-2-item  .test-1 {
    min-height: 250px;
    padding-bottom: 20px;
}
.set_big_slides_section .sec-6 .sec-2-item .test-2 img {
    height: 195px;
    width: 215px !important;
}
.set_big_slides_section .sec-6 .test-img {
    bottom: 30px;
    max-width: 50px;
}
.set_big_slides_section .sec-6 .sec-2-item {
    overflow: hidden;
}
.set_big_slides_section .inventery_mcrousel  .sec-6 .sec-2-item {
    padding-top: 21px;
}
.set_big_slides_section .inventery_mcrousel {
    max-width: 100%;
}
.degi-name {
    margin-top: 15px;
}
.degi-name h5 {
    font-size: 14px;
    font-weight: 600 !important;
    line-height: 24px;
}
.degi-name p {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
}
/* 25 june */
.hm_srvc_infos h4 {
    font-size: 14px;
    line-height: 19.12px;
    font-weight: 700;
}
.point_box h3 {
    font-weight: 700;
}
.perfect-food-cnt {
    max-width: 340px !important;
}
.hm_3boxes .point_box h3 {
    max-width: 340px;
    font-weight: 800;
    letter-spacing: -1px;
    margin-top: 8px;
}
.hm_3boxes .point_box p {
    padding: 0 11px 28px 30px;
}
.set_banner .banner-heading {
    max-width: 90% !important;
}
.set_why_section .hm_heading .dordr_whysec_infos p {
    max-width: 340px;
}
.set_deliver_section .hm_heading.hm_heading_big p {
    max-width: 100%;
}
.set_deliver_section .sec4newa_grid.do_delivr_grid .hm_whycards .hm_why_infos h4 {
    max-width: 200px;
}
p.para.hmpara_btm {
    max-width: 340px;
    margin: 30px auto 0 auto;
}
.sec-8 .accordion.accordion_rista {
    padding-left: 8px;
}
.sec-8 .accordion.accordion_rista .card-body p, .sec-8 .accordion.accordion_rista .card-body li {
    padding-right: 25px;
}
section.banner.banner_home.banner_noimage.banner_direct_ordering.set_banner.set_inventory_banner .banner-heading {
    max-width: 340px !important;
}
.set-invt-control-section .hm_heading h3 {
    max-width: 340px;
}
.sec-8 .card-body p, .card-body li {
    text-align: left;
    padding-right: 25px;
}
.sec-3.set_invt_choose_rista .subheading {
    max-width: 340px;
}
.sec-3 .nav.nav-pills::-webkit-scrollbar {
    height: 0;
}
.footer-bar-up.set_invt-footerbar .heading {
    opacity: 0.8;
}
.set_customer_monial .hm_heading.hm_heading_big h3 {
    max-width: 340px;
}
.set_whatsapp_brand_section .hm_heading h5 img {
    max-height: 14px;
    margin: 0 3px 0 3px;
    position: relative;
    top: -2px;
}
.set_whatsapptab_section .hm_heading.hm_heading_big h3 {
    max-width: 340px;
}
.footer-bar-up.whatsapp_footer_bar .subheading {
    max-width: 340px;
}
.about_head h2 {
    max-width: 100%;
}
.about_head p {
    max-width: 300px;
}
.about4infos h3 {
    max-width: 100%;
}
.about4infos p {
    max-width: 100%;
    padding-left: 7px;
    padding-right: 3px;
}
.about_head.about_head_last h2 {
    max-width: 100%;
    padding: 0 15px;
}
.about_head.about_head_last p {
    max-width: 295px;
}
p.para.hmpara_btm.ab-crousel-text {
    max-width: 340px;
}
p.tab_lastline a {
    padding: 10px  15px !important;
    margin: 15px 0px 30px 0px !important;
    width: 100%;
    max-width: 100% !important;
    display: block !important;
}

p.tab_lastline {
    padding: 20px 24px 0 24px;
}
section.sec-2.set_logo_section {
    padding-right: 0;
    padding-left: 0;
}

.set_banner .banner-heading {
    font-size: 40px;
    line-height: 45px;
    letter-spacing: 0;
    max-width:85% !important;
    margin-bottom: 36px !important;
}

.banner_direct_ordering.set_banner .banner-heading span {
    font-size: 40px;
    line-height: 45px;
    font-weight: 700;
    top: 0;
}

.footer-bar-up .fotr_barup_infos .subheading {
    max-width: 100%;
}

}
@media (max-width:420px){
    .set_big_slides_section .sec-6 .sec-2-item  .test-1 {
        min-height: 282px;
    }

p.tab_lastline strong {
    flex: 0 0 100%;
    max-width: 100%;
}

}
@media (max-width:370px){
    .set_banner  .banner-mobile {
        flex-wrap: nowrap;
    }
}
