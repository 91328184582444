@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Manrope:wght@200..800&display=swap');

/* font-family: "Inter", sans-serif;*/

/*font-family: "Manrope", sans-serif;*/

body{
	font-family: "Manrope", sans-serif !important;
    color: #000 !important;
}
.topheader-inner-a a {
	padding: 6px 14px;
	font-family: Inter;
	font-size: 14px;
	font-weight: 600;
	line-height: 24px;
	text-align: center;
	color: #fff;
	border-radius: 4px;
	background: #FFFFFF3D;
    width: 100% !important; 
}
.topheader-inner-a a:hover {
	color: #fff;
}
.topheader-inner-a {
	flex: 0 0 150px;
	max-width: 150px;
}
.topheader-inner p {
	font-family: Manrope;
	font-size: 16px;
	font-weight: 700;
	line-height: 26px;
	letter-spacing: 0.4000000059604645px;
	text-align: left;
	color: #fff;
	margin: 0;
}
.topheader-1 {
	display: flex;
	justify-content: center;
    align-items: center;
	gap: 24px;
}
.topheader {
	background: #0339A2;
	border-bottom: 0.4px solid #C4C4C4;
	padding: 17px 0;
}
.pos_playgrid_right-aliyn .pos_plygridinfos h3 {
	max-width: 70%;
}
.pos_playgrid.pos_playgrid_right.pos_playgrid_right-aliyn .row {
	align-items: center;
}
.sticky-nav {
    position: sticky;
    top: 20px; 
    z-index: 1000; 
    background-color: #fff; 
    width: 100%; 
  }
  .section-animate {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .section-animate.active {
    opacity: 1;
  }
  
  .sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
 
  }
h1,h2,h3,h4,h5,h6{
	font-family: "Manrope", sans-serif !important;
}

.header {
	font-family: "Manrope", sans-serif;
}
a{
    text-decoration: none !important;
}
.header .menu, .drop-menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
section{
    width: 100%;
    float: left;
}
.header .menu-item {
    display: inline-block;
    position: relative;
}
.header .navbar {
	padding: 0;
}
.header .dropdown-toggle::after {
	display: none;
}
.header .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 19px;
    padding-left: 19px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #000 !important;
}
.header .menu-item a {
	text-decoration: none;
	padding: 0;
	color: #fff;
	display: block;
}
.header .drop-menu {
	display: none;
	position: absolute;
	background-color: #fff;
	min-width: 100px;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
	padding: 10px;
}
.header .menu {
	display: flex;
	gap: 38px;
	justify-content: center;
}
.header .drop-menu-item {
    width: 100%;
}
.header .menu-wrap .menu-item a {
	font-family: Inter;
	font-size: 16px;
	font-weight: 700;
	line-height: 26px;
	text-align: left;
	color: #1D1D1D;
}

.header .drop-menu-item:hover {
    background-color: #eee;
}





.drop-menu-item a {
    color: #555;
}

.menu-item:hover .drop-menu {
    display: block;
}
.header {
    padding: 22px 0 22px 0;
    border-bottom: 1px solid #E6E6E6;
}
.banner-subheading {
	font-family: "Manrope", sans-serif;
	font-size: 20px;
	font-weight: 600;
	line-height: 29px;
	text-align: left;
	padding: 16px 16px 17px 15px;
	display: block;
	background: #f2f7ff;
	border-radius: 50px;
	max-width: fit-content;
}
.banner-heading {
    font-family: "Manrope", sans-serif;
    font-size: 58px;
    font-weight: 700;
    line-height: 64px;
    text-align: left;
    color: #000000;
    margin: 0px 0 35px 0;
}
.banner-para {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
    color: rgba(0,0,0,0.70);
    margin-right: 84px;
    margin-bottom: 34px;
    max-width: 72%;
}

.banner {
    padding: 77px 0 115px 0;
}
.ban-bt a {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    padding: 16px 64px;
    background: #1D4ED8;
    float: left;
    color: #fff;
    border-radius: 4px;
    position: relative;
    z-index: 1;
    transition: all 0.7s ease;
}

/* .ban-bt a::after{
	    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #153aa1;
    height: 0;
    width: 100%;
    content: "";
    z-index: -1;
    border-radius: 4px;
    transition: all 0.7s ease;
}

.ban-bt a:hover:after {
    height: 100%;
} */

.ban-bt a:hover {
	color: #fff;
     background: #153aa1;
}

/* .ban-bt a:hover{
	background: transparent;
} */
.ban-bt {
	width: 100%;
	float: left;
}
.ban-last {
	margin: 24px 0 0px 0;
    width: 100%;
	float: left;
}
.ban-last {
    font-family: "Manrope", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 100%;
    text-align: left;
    color: #000;
}

.ban-last span {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    color: rgba(0,0,0,0.8);
    margin-left: 2px;
}
.ban-last img {
    margin-right: 2px;
    position: relative;
    top: -3px;
}
.ban-last-1 span {
	font-family: Inter;
	font-size: 12px;
	font-weight: 400;
	line-height: 32px;
	text-align: left;margin-left: 2px;
}

.ban-last.ban-last-1 {
    margin: 0;
}

.banner-image {
	margin-top: 150px;
}
.sec-2 p {
	font-family: "Manrope", sans-serif;
	font-size: 24px;
	font-weight: 700;
	line-height: 100%;
	text-align: center;
	margin: 0 0 36px 0;
}
.sec-2 {
    margin-bottom: 115px;
}

.sec-2 p.para small {
    font-size: 24px;
}


.heading {
    font-family: "Manrope", sans-serif;
    font-size: 22px;
    font-weight: 500;
    line-height: 34px;
    text-align: center;
    color: #000000;
    margin: 0;
}
.subheading {
    font-family: "Manrope", sans-serif;
    font-size: 42px;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: -1px;
    text-align: center;
}
.subheading span{
    display: block;
}
.sec-3 .term {
	display: flex;
	gap: 84px;
	justify-content: center;
}
.sec-3 .term h3 {
	font-family: "Manrope", sans-serif;
	font-size: 20px;
	font-weight: 700;
	line-height: 35px;
	text-align: left;
	margin: 34px 0 24px;
}
.sec-3 .term p {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    text-align: left;
    color: #000;
    margin-bottom: 35px;
    font-family: "Manrope", sans-serif;
    margin-top: 20px;
}
.sec-3 .nav.nav-pills .nav-item a {
    font-family: "Manrope", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 35px;
    text-align: center;
    color: #000000;
    padding: 10px 22px;
    border: 1px solid #D5D5D5;
    background: rgba(255,255,255,0.09);
    border-radius: 62px;
    transition: all 0.5s ease;
}
.sec-3 .nav.nav-pills {
	display: flex;
	gap: 12px;
	justify-content: center;
	margin: 0px 0;
}
.sec-3 .term .term-1 img {
	width: 100%;
}
.term-new{
    margin-top: 24px;;
}
.term-1 {
	flex: 0 0 47%;
	max-width: 50%;
}
.sec-3 .nav.nav-pills .nav-item a.active, .sec-3 .nav.nav-pills .nav-item a:hover {
    background: #000000;
    color: #fff;
    border: 1px solid #000000;
    font-weight: 700;
}
.sec-4 {
    padding: 96px 0 33px;
    background: #0C0C0C;
    color: #fff;
    margin: 96px 0 0 0;
    position: relative;
    z-index: 1;
}
.sec-4 .heading {
	text-align: left;
	color: #fff;
}
.subheading {
	color: #fff;
	text-align: left;
}
.sec-4 .tab-content h4 {
    font-family: "Manrope", sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 125%;
    text-align: left;
    margin: 56px 0 8px;
    color: rgba(255,255,255,0.50);
}
.sec-4 .tab-content h5 {
	font-family: "Manrope", sans-serif;
	font-size: 28px;
	font-weight: 700;
	line-height: 125%;
	text-align: left;
	margin-bottom: 56px;
}
.sec-4 .block {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
}
.sec-4 .block-3 {
    padding: 30px 27px;
    background: #1E1E1E;
    margin-bottom: 20px;
    padding-bottom: 0;
}
.sec-4 .block-3 h3 {
    font-family: "Manrope", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 125%;
    text-align: left;
    margin: 16px 0;
}

.sec-4 .block-1.mincontt .block-3 h3 {
    min-height: 49px;
}
.sec-4 .block-1.mincontt .block-3 p {
    min-height: 93px;
}


.sec-4 .block-1.mincontt2 .block-3 p {
    min-height: 78px;
}


.sec-4 .block-3 p {
   font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #FFFFFF;
    opacity: 70%;
    margin-bottom: 0px;
   
}
.sec-4 .block-1 {
    flex: 0 0 60%;
    max-width: 60%;
    padding-left: 13px;
}
.sec-4 .block-2 {
	flex: 0 0 40%;
	max-width: 40%;
}
.sec-4 .block-2 img {
	width: 100%;
}
.sec-4 .nav.nav-pills {
    padding: 46px 0 0px;
    border-bottom: none;
}
.sec-4 .nav-pills .nav-link {
    border: 1px solid #545454;
    background: #545454;
    font-family: "Manrope", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    padding: 15px 40px;
    color: #FFFFFF;
    border-radius: 68px;
}
.sec-4 .nav-pills .nav-link.active {
	background: #fff;
	color: #000;
}
.sec-4 .nav.nav-pills {
	display: flex; 
	gap: 16px;
}
.sec-4-bt a {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    background: #1D4ED8;
    padding: 16px 42px;
    color: #fff;
    border-radius: 4px;
    margin-top: 37px;
    float: left;
    text-align: center;
    position: relative;
    z-index: 1;
    transition: all 0.7s ease;
}


/* .sec-4-bt a:after{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #153aa1;
    height: 0;
    width: 100%;
    content: "";
    z-index: -1;
    border-radius: 4px;
    transition: all 0.7s ease;
}

.sec-4-bt a:hover:after {
    height: 100%;
} */

.sec-4-bt a:hover {
     background: #153aa1;
    color: #fff;
}

.sec-4-bt {
	margin: auto;
}
.sec-5-img img {
	width: 100%;
}
.sec-5 .subheading {
    color: #000;
    text-align: center;
    max-width: 60%;
    margin: 0 auto 64px auto;
}
.sec-5 .heading {
	text-align: center;
}
.sec-5 {
    padding: 90px 0 90px;
}
.sec-4-bt a {
	color: #fff;
}
.client-img {
	width: auto !important;
	height: 55px;
	margin: 0 0 28px 0;
}
/* start faq */




.accordion_rista .card .card-header {
    border: 0;
    -webkit-box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
            box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
    border-radius: 2px;
    padding: 0;
  }
.accordion_rista .card .card-header .btn-header-link {
    /* color: #fff; */
    display: block;
    text-align: left;
    background: #F9F9F9;
    color: #000;
    padding: 20px 20px 16px 52px;
    font-family: "Manrope", sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 35px;
    /* text-align: left; */
    position: relative;
    box-shadow: none;
}
.accordion_rista .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0 1.25rem 1.25rem 52px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    background: #f9f9f9;
    color: #223140;
}
.accordion_rista .card .card-header .btn-header-link::after {
	content: "+";
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	float: right;
	transform: rotate(0deg);
	position: absolute;
	left: 22px;  
	font-size: 28px;content: "_";
    top: 6px;
    
}

.accordion_rista .card .card-header .btn-header-link.collapsed::after{
color: #1D4ED8;content: "+";top: 21px;

}

.accordion_rista .card {
    margin-bottom: 16px;
    border: 1px solid #e0e0e0 !important;
    border-radius: 4px !important;
}
.accordion_rista .card .card-header .btn-header-link.collapsed {
    background: #FFF;
    font-family: "Manrope", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 35px;
    text-align: left;
    color: #000;
}
.accordion_rista .card .card-header .btn-header-link.collapsed::after {
	content: "+";
	transform: rotate(0deg);
	font-size: 28px;
}
  
 .accordion_rista .card .collapsing {
    background: #FFE472;
    line-height: 30px;
  }
  
 .accordion_rista .card .collapse {
    border: 0;
  }
  
.accordion_rista .card .collapse.show {
    background: #FFE472;
    line-height: 30px;
    color: #222;
  }
/* end faq */
.sec-6 {
    background: #0C0C0C;
    text-align: center;
    padding: 96px 0 100px;
}
.sec-6 .heading {
	color: #fff;
	text-align: center;
}

.sec-6 .sec-2-item {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 40px;
	position: relative;
	background: #fff;
	padding-left: 45px;
	padding-top: 41px;
	padding-right: 54px;
	border-radius: 5px;
	margin-top: 54px;
}

.sec-6 .test-1 p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: #000;
    margin: 0px 0 25px;
}
.sec-6 .owl-dots {
	position: absolute;
	right: 0;
}
.sec-7 {
    padding: 81px 0;
    background: #1B2A3F;
    color: #fff;
}
.sec-4-bt a:hover {
	color: #fff;
}

.sec-7 .sec-7-block h3 {
	margin: 54px 0 25px;
	font-family: "Manrope", sans-serif;
	font-size: 20px;
	font-weight: 700;
	line-height: 28px;
	text-align: center;
}
.sec-7 .sec-7-block {
	display: flex;
	justify-content: center;
	text-align: center;
	gap: 70px;
}
.sec-7 .sec-7-block p {
	font-family: Inter;
	font-size: 14px;
	font-weight: 700;
	line-height: 22px;
	text-align: center;
	opacity: 60%;
}
.sec-7 .sec-7-block .sec-7-img img {
	height: 40px;
	width: auto;
}
.sec-8 .accordion_rista .card .card-header .btn-header-link.collapsed::after {
    content: "+";
    transform: rotate(0deg);
    font-size: 34px;
    bottom: inherin !important;
    top: 0 !important;
    height: 100%;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    position: absolute !important;
    float: none;
    /* width: 60%; */
    top: inherit !important;
    bottom: inherit !important;
}


.sec-8 .accordion_rista .card .card-header .btn-header-link::after {
    content: "_";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    float: none;
    transform: inherit;
    position: inherit;
    left: inherit;
    font-size: 28px;
    height: 100%;
    right: 0 !important;
    position: inherit !important;
    left: 0;
}
.sec-8 .accordion_rista .card .card-header .btn-header-link {
    display: block;
    padding: 34px 20px 34px;
    font-family: "Manrope", sans-serif;
    font-weight: 600;
    text-align: left;
    font-size: 24px;
    line-height: 32px;
    background: #fff;
    border: 0px;
}
.sec-8 .card-body p, .card-body li {
    font-family: "Manrope", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    padding-right: 50px;
}
.sec-8 .card-body ul {
	padding: 0 0 0 17px;
}
.sec-8 .accordion_rista .card-body {
	padding: 0 1.25rem 1.25rem 20px;
	background: #fff;
}
.sec-8 .accordion_rista .card {
	border: 0;
	border-bottom: 1px solid #dbdbdb;
}
.sec-8 {
	padding: 120px 42px 46px;
}
.sec-8 .subheading {
	color: #000;
	text-align: center;
}
.mid {
	margin: auto;
	justify-content: center;
}
.sec-6 .test-img {
	position: absolute;
	width: 54px !important;
	right: 45px;
	top: 45px;
}
.sec-6 .test-2 img {
	height: 300px;
	object-fit: cover;
	width: 231px !important;
}
.sec-9 {
    padding: 192px 0;
}
.sec-9 .subheading {
	color: #000;
	text-align: center;
}
.sec-3 .subheading {
    color: #000;
    text-align: center;
    max-width: 80%;
    margin: 0 auto;
}
.sec-3 .heading {
    color: #000;
    text-align: center;
    margin-bottom: 8px;
    text-transform: uppercase;
}
.sec-8 .ban-bt {
	margin: 42px 0 0 0;
	text-align: center;
	display: flex;
	justify-content: center;
}
.sec-para p {
	font-family: "Manrope", sans-serif;
	font-size: 14px;
	font-weight: 700;
	line-height: 125%;
	text-align: left;
	margin: 40px 0 0 0;
	color: #1D1D1D;
}
.sec-para p span {
	font-family: Inter;
	font-size: 11px;
	font-weight: 700;
	line-height: 32px;
	text-align: left;
	color: #223140;
	opacity: 80%;
}
.sec-para p img {
	margin: 0 8px;
	position: relative;
	top: -3px;
}
.sec-para p img.border-2 {
	padding: 0 8px;
	position: relative;
	top: -3px;
	border-left: 1px solid #D5D5D5;
	margin: 0 0 0 5px;
}
.footer-bar {
	padding: 62px 0 70px;
	background: #121212;
}
.footer-bar h3 {
	font-family: "Manrope", sans-serif;
	font-size: 16px;
	font-weight: 700;
	line-height: 28px;
	text-align: left;
	color: #7D7D7D;
	margin: 0 0 32px 0;
}
.footer-bar .foot ul li a {
	font-family: "Manrope", sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 21.86px;
	text-align: left;
	color: #fff;
	margin: 20px 0;
	display: block;
}
.footer-bar .foot ul {
	list-style-type: none;
	padding-left: 0;
}
.footer-bar .foot {
	display: flex;
	justify-content: center;
	gap: 179px;
}
.footer-bar .foot .foot-1 {
	flex: 0 0 180px;
	max-width: 180px;
}
.footer-bar {
	padding: 62px 0 70px;
	background: #121212;
	border-top: 1px solid #383838;
	border-bottom: 1px solid #383838;
}
.footer-bar-low {
    background: #121212;
    padding: 40px 0 40px;
}
.footer-bar-low .row {
	justify-content: end;
}
.footer-bar-low ul {
	list-style-type: none;
	display: flex;
	gap: 59px;
            justify-content: flex-end;
}
.footer-bar-low ul li a {
   font-family: "Manrope", sans-serif !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 21.86px;
    text-align: left;
    color: #fff;
}
.footer-bar-up {
    padding: 125px 0;
    background: #121212;
}
.footer-bar-up .heading {
	color: #fff;
	opacity: 70%;
	line-height: 42px;
	text-align: left;
}
.footer-bar-up .heading span {
	display: block;
}
.footer-bar-up .subheading {
    margin-right: 100px;
    margin-bottom: 16px;
    font-size: 36px;
    line-height: 48px;
}
.footer-bar-up h4 {
    font-family: "Manrope", sans-serif;
    font-size: 36px;
    font-weight: 700;
    line-height: 46px;
    letter-spacing: 0px;
    text-align: left;
    color: #fff;
    margin: 0;
}
.footer-bar-up h5 {
    font-family: "Manrope", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #fff;
    margin: 10px 0 20px;
}
.footer-bar-up .right-side {
    padding: 0 50px 0 100px;
    border-left: solid 1px #1F1F1F;
}
.footer-bar-up .right-side .form-control {
    border: 1px solid #FFFFFF66;
    padding: 26px 22px;
    background: #12121200;
    font-family: Inter;
    font-size: 17px;
    font-weight: 500;
    color: #fff;
}
.footer-bar-up .right-side .bt-form {
    padding: 16px;
    width: 100%;
    background: #1D4ED8;
    border: 0;
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    position: relative;
    z-index: 1;
    transition: all 0.5s ease;
}

/* .footer-bar-up .right-side .bt-form:after{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #153aa1;
    height: 0;
    width: 100%;
    content: "";
    z-index: -1;
    border-radius: 4px;
    transition: all 0.7s ease;
}

.footer-bar-up .right-side .bt-form:hover:after {
    height: 100%;
} */

.footer-bar-up .right-side .bt-form:hover {
    background: #153aa1;
    color: #fff;
}

.footer-bar-up .right-side .form-control::placeholder {
	font-family: Inter;
	font-size: 17px;
	font-weight: 500;
	color: #fff;
}
.sec-2 .restaurants-img img {
	height: 64px;
	width: 64px;
}
.sec-2 .restaurants-img {
	display: flex;
	justify-content: center;
}
.sec-2 .restaurants-img img {
	height: 64px;
	width: 64px !important;
	/* filter: grayscale(100%);
	opacity: 0.7; */
}
.sec-9 .restaurants-img img {
    width: auto !important;
    height: 37px;
}
.sec-9 .restaurants-img {
	display: flex;
	justify-content: center;
}
.brand-logo-slide {
	display: flex;
	flex-direction: column;
	gap: 60px;
	text-align: center;
}
.sec-9 .heading {
    line-height: 125%;
    margin: 20px 0 80px;
}
.sec-6 .owl-theme .owl-dots .owl-dot span {
    width: 7px;
    height: 7px;
    margin: 5px 2px;
    background: #9d9c9c;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity 200ms ease;
    border-radius: 30px;
}
.sec-6 .owl-theme .owl-dots .owl-dot.active span, .sec-6 .owl-theme .owl-dots .owl-dot:hover span {
	background: #fff;
}
.sec-6 .owl-nav button {
	width: 50px;
	height: 50px;
	border: 2px solid #fff !important;
	border-radius: 50% !important;
}
.sec-6 .owl-nav button span {
    color: #fff !important;
    font-size: 40px;
    position: relative;
    top: -13px;
    line-height: 66px;
    display: inline-block;
}
.sec-6 .owl-prev {
	position: absolute;
	left: -127px;
	top: 50%;
}
.sec-6 .owl-next {
	position: absolute;
	right: -127px;
	top: 50%;
}
.sec-6 .owl-theme .owl-nav [class*="owl-"]:hover {
	background: #fff0;
	color: #FFF;
	text-decoration: none;
}
.sec-9 .owl-stage {
	align-items: center;
	display: flex;
}
.sec-9 .restaurants-img .big-img {
	width: auto !important;
	height: 20px;
	margin: auto;
}
.sec-4 .block-2 img {
	width: 100%;
	height: 435px;
	margin-bottom: 20px;
}
.banner-heading span {
	display: block;
}
.show-1{
	display: none;
}
.social-link a img {
    width: auto;
    height: 21px;
}
.social-link {
	display: flex;
	gap: 40px;
}
.footer-bar-low ul {
	margin-bottom: 0;
}
.footer-bar-low .row {
	align-items: center;
}

/*home page new*/

span.banner-subheading.home_banner_subheading {
    background: none;
    font-size: 24px;
    padding: 0;
    display: block;
    font-size: 16px;
    font-weight: 600;
    background: #E9EFFB;
    color: #000;
    padding: 5px 16px;
    line-height: 29px;
    margin: 0 0 16px 0;
}
.banner-heading span {
    display: block;
    /* color: #1D4ED8; */
}

.sec3new {
    background: #F5F5F5;
}

.sec_space {
    padding: 90px 0;
}

.hm_heading h5 {
    font-size: 22px;
    line-height: 34px;
    text-transform: uppercase;
}

.hm_heading {
    text-align: center;
    color: #000;
}

.hm_heading h3 {
    font-size: 36px;
    line-height: 46px;
    font-weight: 800;
    max-width: 60%;
    margin: 0 auto 20px auto;
}

.hm_heading p {
    font-size: 15px;
    line-height: 26px;
    max-width: 77%;
    margin: 0 auto 30px auto;
}



.sec3_n_btns {
    text-align: center;
    padding: 30px 0 0 0;
}

.sec5a_posinfos .sec3_n_btns {
    padding: 60px 0 0 0;
}

.sec3_n_btns a {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    padding: 16px 42px;
    background: #1D4ED8;
    color: #fff;
    border-radius: 4px;
    margin: 0 15px;
    transition: all 0.5s ease;
    position: relative;
    z-index: 1;
    overflow: hidden;
    display: inline-block;
}

.sec3_n_btns a:hover{ color: #fff;	
	background: none;

}

.sec3_n_btns a.hm_linkbtn {
    background: none;
    color: #1361F5;
    padding: 16px 32px;
}

.sec3_n_btns a.hm_linkbtn:hover {
	color:#fff;
}

/* .sec3_n_btns a:after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #153aa1;
    height: 0;
    width: 100%;
    content: "";
    z-index: -1;
    border-radius: 4px;
    transition: all 0.7s ease;
}

.sec3_n_btns a:hover:after {
    height: 100%;
} */

.sec3_n_btns a:hover {
    height: 100%;
    background: #153aa1;
}

.hm_heading.hm_heading_big h3 {
    font-weight: 700;
    font-size: 42px;
    line-height: 52px;
}

.hm_why_infos h4 {
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
    transition: all 0.5s ease;
}


.sec4newa_grid {
    padding: 0px 0 0 0;
}

.hm_whycard_icon span {
    width: auto;
    height: 44px;
    background: none;
    display: inline-flex;
    margin: 0 0 13px 0;
    align-items: flex-start;
}

.hm_whycard_icon.hm_whycard_icon2 span {
    position: relative;
    top: -20px;
    overflow: hidden;
    height: 44px;
}

.hm_whycards {
    margin-top: 46px;
    max-width: 80%;
}

.sec5new_top {
    background: #121212;
    padding-top: 96px;
    padding-bottom: 96px;
}

section.sec5new.sec_space {
    padding-top: 0;   padding-bottom: 96px;
}




.hm_srvc_infos h4 {
    font-size: 18px;
    color: #fff;
    line-height: 26px;
    margin: 0 0 12px 0;
}

.sec5newa_grid .col-md-2 {
    flex: 0 0 20%;
    max-width: 20%;
}

.hm_srvccards .hm_srvccard_icon span {
    display: flex;
    height: 56px;
    justify-content: center;
    align-items: center;
    margin: 0 0 20px 0;
}

.hm_srvccards {
    text-align: center;
}

.hm_srvccards .hm_srvc_infos p {
    color: rgba(255,255,255,0.69);
    font-size: 14px;
    line-height: 21px;
    font-family: "Inter", sans-serif;
    max-width: 60%;
    margin: 0 auto;
}
.sec5new_bottom {
    background: #121212;
    color: #fff;
    border-top: solid 1px #313131;
    padding: 16px 0;
    text-align: center;
}

.sec5new_bottom .hm_staricon {
    margin: 0 7px;
}

.sec5new_bottom small.et_customr {
    font-size: 11px;
    margin: 0 0 0 7px;
}

.sec5new_bottom b {
    padding: 0px;
}

.sec5new_bottom .hm_starline {
    padding-right: 1px;
    line-height: 30px;
    font-size: 20px;
    position: relative;
    top: 2px;
    padding-left: 8px;
}
/* .sec4newa_grid .hm_whycards:hover .hm_why_infos h4 {
    margin: 0 0 20px 0;
} */


.sec6_perfect_points ul li{
background: url(../../images/perfect_arrow.svg) left center no-repeat;
background-size: 18px 18px;
font-family: "Inter", sans-serif;
font-size: 16px;
line-height: 24px;     padding: 0 0 0 28px;
    margin: 0 0 10px 0;
}

.sec6_heading {
    font-size: 24px;
    line-height: 35px;
    font-weight: bold;
}

.sec6_perfect_points ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.sec6_perfect_grid {
    border-bottom: solid 1px rgba(0,0,0,0.11);
    padding: 15px 0 15px 0;
}

.sec6_imgbox span {
    height: auto;
    display: inline-block;
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
    background: none;
}

.sec6_perfect_grid .row {
    align-items: center;
}

section.sec6new.sec_space {
    padding-top: 96px;
    padding-bottom: 96px;
}
.sec6new .hm_heading.hm_heading_blue h3 span {
    color: #1D4ED8;
}

.sec6new .hm_heading p {
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    margin-bottom: 36px;
}

.sec6new .hm_heading.hm_heading_blue h3 {
    margin-bottom: 20px;
}

.sec6_perfect_grid:last-child {
    border: none;
}

.hm_hidesec{ display: none;}

.sec6_perfect_grid:last-child {
    border: none;
}

section.sec-5 .hm_heading p {
    font-size: 24px;
    max-width: 100%;
    font-weight: 500;
    font-family: "Inter", sans-serif;
    margin: 0 0 50px 0;
}

.point_block_main {
    background: #F5F5F5;
    padding: 60px;
    border-radius: 12px;
}
.sec3_home_tabs .point_block_main {
    padding-right: 0;
}

.sec3_home_tabs .point_row_grid  .point_img {
    text-align: right;
}

.point_box h5 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 0;
}

.point_box h3 {
    font-size: 24px;
    font-weight: 900;
    max-width: 77%;
    line-height: 34px;
    margin: 8px 0 34px 0;
}

.point_box p {
    font-size: 14px;
    line-height: 24px;
    font-family: "Inter", sans-serif;
    color: #000;
    max-width: 85%;
}



.point_box .point_btns a {
    color: #1361F5;
    font-size: 18px;
    line-height: 24px;
    padding: 13px 35px;
    border: solid 1px #1361F5;
    border-radius: 4px;
    display: inline-block;
    margin-top: 50px;
    transition: all 0.5s ease;
    font-weight: 600;
}

.point_box .point_btns a:hover {
    background: #1D4ED8;
    color: #fff;
}

.hm_3boxes .point_box h5 {
    font-size: 18px;
    margin-bottom: 8px;
}

.hm_3boxes .point_box h3 {
    font-size: 48px;
    max-width: 100%;
    line-height: 56px;
    margin: 0 0 20px 0;
}

.hm_3boxes .point_box p {
    font-size: 18px;
    line-height: 32px;
    font-weight: 400;
    margin: 0 0 42px 0;
}

.point_acordimg span {
    background: none;
    height: auto;
    display: inline-block;
    width: auto;
}


.hm_3boxes {
    margin-top: 70px;
}

.point_block_main.point_block_main2 {
    margin-top: 50px;
    border: solid 1px #ccc;
    background: none;
}

.point_block_main.point_block_main2.point_block_main3 {
    background: #F5F5F5;
}

.point_img span {
    background: none;
    height: auto;
    width: auto;
    display: inline-block;
    margin-top: 10px;
}

.sec-3.sec3_home .tabs_owl_crousel .owl-theme .owl-nav button {
    background: #fff;
    border: solid 1px #C3C3C3;
    width: 52px;
    height: 52px;
    border-radius: 100%;
    line-height: 46px;
    transition:all 0.5s ease
}

.sec-3.sec3_home .tabs_owl_crousel .owl-theme .owl-nav button span {
    font-size: 36px;
    color: #CCCCCC;
    transition:all 0.5s ease
}

.sec-3.sec3_home .tabs_owl_crousel .owl-theme .owl-nav button:hover{
    border: solid 1px #1361F5;
}

.sec-3.sec3_home .tabs_owl_crousel .owl-theme .owl-nav button:hover span {
    color: #1361F5;
}

.banner-image.hmbanner_img span {
    background: none;
    height: auto;
    width: 100%;
    display: inline-block;
    margin-top: 30px;
}

.banner-image.hmbanner_img span.hmbanner_img2 {
    background: none;
    border: none;
    border-radius: 0;
    position: absolute;
    height: auto;
    bottom: -20px;
    left: -180px;
    width: 100%;
}


.banner-image.hmbanner_img {
    margin: 0;
    position: relative;
    text-align: right;
}
.sec-3.sec3_home .tabs_owl_crousel .owl-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 100%;
    bottom: 50%;
}

.sec-3.sec3_home .tabs_owl_crousel .owl-nav button.owl-prev {
    left: -80px;
    position: relative;
}

.sec-3.sec3_home .tabs_owl_crousel .owl-nav button.owl-next {
    right: -80px;
    position: relative;
}

.sec-3.sec3_home .tabs_owl_crousel:hover .owl-nav button.owl-prev {
    left: -60px;
}

.sec-3.sec3_home .tabs_owl_crousel:hover .owl-nav button.owl-next {
    right: -60px;
}

.accordion_rista.accordion_rista2 .card .card-header .btn-header-link.collapsed::after {
    left: inherit;
    right: 0px;
    color: #1D4ED8;
}

.accordion_rista.accordion_rista2 .card .card-header .btn-header-link {
    padding: 26px 10px;
    background: none;
    color: #000;
}


.accordion_rista.accordion_rista2 .card .card-header .btn-header-link::after {
    left: inherit;
    right: 0px;
}

.accordion_rista.accordion_rista2 .card .card-header {
    background: none !important;
    border: none;
    box-shadow: none;
}

.accordion_rista.accordion_rista2 .card {
    background: none;
    border: none !important;
    border-bottom: solid 1px #EAEAEA !important;
    margin: 0;
}

.accordion_rista.accordion_rista2 .card .collapse.show, .accordion_rista.accordion_rista2 .card .collapsing {
    background: none;
}

.accordion_rista.accordion_rista2 .card-body {
    background: none;
    padding-left: 16px;
}

.accordion_rista .card .collapsing{
	background: none;
}

.fotr_barup_infos p {
    color: rgba(255,255,255,0.70);
    font-size: 16px;
    line-height: 32px;
}

.accordion_rista.accordion_rista2 .card .card-header .btn-header-link.collapsed {
    color: #000;
}

.footer-bar-up .fotr_barup_infos  h4 {
    font-size: 32px;
    font-weight: 300;
    line-height: 42px;
}

.footer-bar-up .fotr_barup_infos .subheading {
    margin: 0 0 30px 0;
}

.banner_contt_mob{ display: none;}


/*mobile accordian for fast-track*/


.fasttrack_accordians{ display:none;}


.fasttrack_accordians .fast_img_shape {
    width: auto;
    height: auto;
    background: transparent;
    display: inline-block;
    margin: 0 10px 0px 0;
}
.fasttrack_accordians .accordion_rista.accordion_rista2 .card .card-header a.btn.btn-header-link {
    height: auto;
    display: flex;
    align-items: center;
    padding: 28px 0;
    transition: all 0.5s ease;
}

.fasttrack_accordians .accordion_rista.accordion_rista2 .card .card-header a.btn.btn-header-link {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all 0.5s ease;
}

.fasttrack_accordians .accordion_rista.accordion_rista2 .card .card-header a.btn.btn-header-link.collapsed {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.5s ease;
    padding-right: 32px;
}


p.para.hmpara_btm {
    margin-top: 46px;
    margin-bottom: 0;
}

.banner_direct_ordering .banner-heading {
    max-width: 92%;
}

.banner_direct_ordering .banner-heading span {
    display: inline;
    /* margin-left: 7px; */
}

section.sec3new_upsec.sec_space {
    padding: 0;
}

section.sec3new_upsec.sec_space .sec-6.deordr_owl_slides {
    background: none;
    padding: 0;
}

section.sec3new_upsec.sec_space .sec-6.deordr_owl_slides .owl-slider {
    border: solid 1px #CECECE;
    border-radius: 12px;
    max-width: 81%;
    margin: -170px auto 0 auto;
    overflow: hidden;
}

/* .sec3new.sec_space.direct_ordr_most {
    padding-bottom: 240px;
} */

section.sec3new_upsec .sec-6 .sec-2-item {
    margin-top: 0;
}


.dordr_whysec_pics span {
    background: #E4E4E4;
    border: solid 6px #494949;
    display: inline-block;
    border-radius: 24px;
    margin: 50px 15px 40px 15px;
}

.dordr_whysec_pics span.left_img {
    width: 50%;
    height: 50vh;
}

.dordr_whysec_pics span.right_img {
    width: 20%;
    height: 50vh;
}

.hm_heading .dordr_whysec_infos p {
    font-weight: 500;
    font-size: 20px;
    color: rgba(0,0,0,0.70);
    line-height: 35px;
    max-width: 67%;
}

.dordr_deliversec .hm_heading.hm_heading_big p {
    font-size: 24px;
    line-height: 36px;
    max-width: 86%;
}


section.sec5new.dordr_deliversec {
    position: relative;
    z-index: 1;
    margin-bottom: 90px;
}

section.sec5new.dordr_deliversec:after {
    background: #1D4ED8;
    height: 50%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    content: "";
    z-index: -1;
}

.sec4newa_grid.do_delivr_grid .hm_whycards {
    background: #fff;
    border-radius: 12px;
    border: solid 1px #E3E3E3;
    padding: 35px;
}

.sec4newa_grid.do_delivr_grid .hm_whycards .hm_whycard_icon span {
    width: 76px;
    height: 76px;
}

.sec4newa_grid.do_delivr_grid .hm_whycards .hm_why_infos h4 {
    font-size: 24px;
    line-height: 35px;
    color: #000;
}

.sec4newa_grid.do_delivr_grid .hm_whycards .hm_why_infos ul {
    margin:25px 0 0 0;
    padding: 0;
    list-style: none;
}

.sec4newa_grid.do_delivr_grid .hm_whycards .hm_why_infos ul li {
background: url(../../images/perfect_arrow.svg) left 2px no-repeat;
    background-size: 18px 18px;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    line-height: 23px;
    padding: 0 0 0 28px;
    margin: 0 0 10px 0;
}


.hm_heading.hm_heading_big h3 {
    max-width: 65%;
}

.sec5new.dordr_adjuestsec .hm_heading p {
    font-size: 24px;
    line-height: 36px;
    font-weight: 500;
    max-width: 80%;
}

.dordr_adjust_list h4 {
    font-size: 24px;
    font-weight: 800;
    color: #000;
    margin: 0 0 14px 0;
}

.dordr_adjust_list p {
    font-size: 15px;
    line-height: 24px;
    margin: 0 30px 20px 0;
    max-width: 93%;
}

.dordr_adjust_list {
    border-bottom: solid 1px rgba(0,0,0,0.11);
    padding: 0 40px 30px 0px;
    margin-bottom: 30px;
}

.dordr_adjust_list a {
    font-size: 16px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
    color: #1361F5;
    transition: all 0.5s ease;
}

.dordr_adjust_list a:hover {
    color: #000;
}

.dordr_img span {
    background: #E4E4E4;
    display: inline-block;
    margin: 50px 15px 40px 15px;
    width: 100%;
    height: 60vh;
}

.adjust_grid {
    padding: 70px 0 0 0;
}

section.sec-8.faq_btm {
    padding: 96px 0;
}

section.sec-8.faq_btm .subheading {
    margin: 0 0 50px 0;
}

.accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link.collapsed {
    color: #000;
}

/* .accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link::after {
    font-size: 0;
    font-weight: 300 !important;
    color: transparent;
    background:#1361F5;
    height: 3px;
    border-radius: 5px;
    top: 18px;
    transition: all 0.5s ease;
    width: 32px;
} */

/* .accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link.collapsed::after {
    font-size: 0;
    color: #252B42;
    background: none;
    top: 18px;
    transition: all 0.5s ease;
    width: 28px;
    height: 3px;
    background: #252B42;
} */

/* .accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link.collapsed::before {
    font-size: 0;
    color: #252B42;
    background: none;
    top: 38px;
    transition: all 0.5s ease;
    width: 28px;
    height: 3px;
    background: #252B42;
    position: absolute;
    content: "";
    right: 23px;
    transform: rotate(90deg);
} */

section.banner.banner_renew_posprobg {
    background: #151515;
}

section.banner.banner_renew_posprobg .banner-heading {
    color: #fff;
    /* font-size: 34px; */
    font-weight: 700;
    /* line-height: 48px; */
    max-width: 75%;
    margin-bottom: 35px;
}

section.banner.banner_renew_posprobg .banner-para {
    color: rgba(255,255,255,0.53);
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
}

section.banner.banner_renew_posprobg .banner-heading span {
    color: #fff;
    display: inline;
}

section.banner.banner_renew_posprobg span.banner-subheading {
    font-size: 16px;
    font-weight: 600;
    background: #252525;
    color: #fff;
    padding: 5px 25px;
    line-height: 29px;
    margin: 0 0 17px 0;
}

section.banner.banner_renew_posprobg .ban-last {
    color: #fff;
    margin: 29px 0 4px 0;
}

section.banner.banner_renew_posprobg .ban-last-1 {
    color: #fff;
    margin-top: 0;
}

section.banner.banner_renew_posprobg .banner-image {
    position: absolute;
    right: 0;
    bottom: 13%;
    margin-top: 30px;
}

/* section.banner.banner_renew_posprobg .container {
    max-width: 100% !important;
    padding: 0 0 0 17%;
} */

section.banner.banner_renew_posprobg .banner-image img {
    width: 618px;
}
section.banner.banner_renew_posprobg .container .col-lg-5 {
    padding-right: 0;
}

section.banner.banner_renew_posprobg .container .row {
    margin-right: 0;
}

section.sec-2 {
    padding-top: 0px;
    margin-bottom: 100px;
}

.selected-image img {
    width: auto;
}

/*header*/

.header.headermain.headerdark {
    background: #151515;
    border: none;
    padding: 20px 0;
}
.tabs_btns button {
    border-radius: 35px 0 0 35px;
    font-size: 16px;
    font-weight: 700;
    padding: 15px 32px 15px 20px;
    /* position: absolute; */
    right: 0;
    bottom: 20px;
    transition: all 0.5s ease;
    border: solid 1px #fff;
}

.tabs_btns button.tab_middle_single {
    position: relative;
    right: -15px;
    padding-right: 20px;
}



.tabs_btns button:hover {
    background: #0C0C0C;
    color: #fff;
    border-color: rgba(255,255,255,0.25);
}

.tabs_btns button span {
    padding: 0 0 0 10px;
}

.tabs_btns button:hover span img {
    filter: brightness(0) invert(1);
}

.tabs_btns button span img {
    transition: all 0.5s ease;
    transform: translateY(0px);
}

.inverntry_m_review .hm_heading h3 {
    max-width: 85%;
}

.inventery_mcrousel .sec-6 {
    padding: 0;
    background: none;
}

.inventery_mcrousel .sec-6 .owl-theme .owl-nav button {
    border: solid 1px #999999 !important;
    transition: all 0.5s ease;
}

.inventery_mcrousel .sec-6 .owl-theme .owl-nav button span {
    color: #999 !important;
    display: block;
}

.inventery_mcrousel .sec-6 .owl-theme .owl-nav button:hover {
    border-color: #000 !important;
}

.inventery_mcrousel .sec-6 .owl-theme .owl-nav button:hover span {
    color: #000 !important;
}

section.sec3new.sec_space.direct_ordr_most.inverntry_m_review {
    padding-bottom: 96px;
    background: none;
    padding-top: 0;
}

.inverntry_m_review .sec-6 .sec-2-item {
    border: solid 1px #C7C7C7;
    border-radius: 12px;
    overflow: hidden;
}

.inventery_mcrousel {
    max-width: 75%;
    margin: 0 auto;
}

.inventery_mcrousel .sec-6 .owl-theme .owl-dots .owl-dot.active span, .inventery_mcrousel .sec-6 .owl-theme .owl-dots .owl-dot:hover span {
    background: #4b4b4b;
}

section.sec_space.take_control_sec {
    background: #f5f5f5;
}

.take_control_sec .hm_heading {
    text-align: left;
    color: #000;
}

.take_control_sec .hm_heading h3 {
    max-width: 75%;
    margin-left: 0;
    margin-right: auto;
}

.take_con_left {
    height: 380px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.take_conbtn a {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    padding: 16px 42px;
    background: #1D4ED8;
    color: #fff;
    border-radius: 4px;
    position: relative;
    z-index: 1;
    transition: all 0.5s ease;
    display: inline-block;
}

/* .take_conbtn a:after{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #153aa1;
    height: 0;
    width: 100%;
    content: "";
    z-index: -1;
    border-radius: 4px;
    transition: all 0.7s ease;
}

.take_conbtn a:hover:after {
    height: 100%;
} */

.take_conbtn a:hover {
    background: #153aa1;
    color: #fff;
}


.takecon_img span {
    background: #6f6f6f;
    width: 345px;
    height: 201px;
    display: block;
    border-radius: 12px;
}

.takecon_img {
    padding: 0 0 30px 0;
}

.takecon_infos h4 {
    color: #fff;
    font-size: 24px;
    font-weight: 800;
}

.takecon_infos ul li {
    position: relative;
    z-index: 1;
    padding: 0 0 0 35px;
    color: #ababab;
    margin: 30px 0 10px 0;
    font-weight: 500;
}

.takecon_infos ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.takecon_infos ul li strong {
    color: #fff;
}

.takecon_infos ul li:after {
    position: absolute;
    left: 7px;
    top: 4px;
    bottom: 0;
    right: 0;
    width: 8px;
    height: 17px;
    content: '';
    border-style: solid;
    border-color: transparent #fff #fff transparent;
    border-width: 0px 2px 2px 0px;
    z-index: 1;
    transform: rotate(40deg);
}


.inven_choose_rista .point_img span {
    background: #6F6F6F;
}

.inven_choose_rista ul li {
    position: relative;
    z-index: 1;
    padding: 0 0 0 35px;
    color: #000;
    margin: 30px 0 10px 0;
    font-weight: 500;
}

.inven_choose_rista  .points_tick  ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.inven_choose_rista .points_tick ul li strong {
    color: #fff;
}

.inven_choose_rista  .points_tick  ul li:after {
    position: absolute;
    left: 7px;
    top: 4px;
    bottom: 0;
    right: 0;
    width: 8px;
    height: 17px;
    content: '';
    border-style: solid;
    border-color: transparent #1D4ED8 #1D4ED8 transparent;
    border-width: 0px 2px 2px 0px;
    z-index: 1;
    transform: rotate(40deg);
}

section.sec_space.know_your_Inventry {
    background: #F8F8F8;
}

.knowyour_grid {
    padding: 30px 0 0 0;
}

.know_box h4 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    max-width: 50%;
    margin: 0 auto;
    text-align: center;
    color: #000;
}

.know_box p {
    font-size: 16px;
    line-height: 25px;
    font-weight: 600;
    color: #434343;
    max-width: 80%;
    text-align: center;
    margin: 20px auto 0 auto;
    min-height: 100px;
}

/*whatsapp*/

.banner_direct_ordering.banner_whatsapp .banner-heading span {
    color: #00C141;
}

.sec3new.sec_space.direct_ordr_most.whats_customr {
    background: none;
    padding-top: 30px;
    padding-bottom: 0;
}

.sec3new.whats_customr .hm_heading h3 {
    max-width: 100%;
}


.whats_customr .sec-6 {
    background: none;
    padding: 40px 0 85px 0;
}

.whats_customr .sec-6 .whats_star img {
    width: 22px;
}

.whats_customr .sec-6 .whats_star {
    display: flex;
    flex-direction: row;
    padding: 0 0 30px 0;
}

.customers_starbox {
    background: #F8F8F8;
    border-radius: 12px;
    text-align: left;
    padding: 60px 40px;
}

.customers_starbox p {
    font-size: 18px;
    font-weight: 600;
    color: rgba(0,0,0,0.8);
}

.whatstabimg_sec .hm_heading.hm_heading_big h3 {
    max-width: 65%;
    padding: 0 0 50px 0;
}

section.sec_space.whats_connect {
    background: #000;
}

section.sec_space.whats_connect .hm_heading {
    color: #fff;
    text-align: left;
}

section.sec_space.whats_connect .hm_heading h3 {
    max-width: 65%;
    font-weight: 400;
    margin: 10px auto 0 0;
}

.whatsapp_box h4 {
    color: #4AF884;
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
}

.whatsapp_box p {
    font-size: 14px;
    color: #fff;
}

.whatsapp_box {
    margin: 60px 0 50px 0;
}


/*form-css*/




/*header change*/

.header.headermain .right_menu .nav-item a.nav-link {
    color: #1D4ED8 !important;
    border: solid 1px #1D4ED8;
    font-size: 18px;
    font-weight: 700;
    border-radius: 4px;
    padding: 13px 30px;
    transition: all 0.5s ease;
}

.header.headermain .right_menu .nav-item a.nav-link:hover {
    background: #1D4ED8;
    color: #fff !important;
}


.header.headermain.headermain_scroll .right_menu .nav-item a.nav-link {
    color: #fff !important;
    border: solid 1px #1D4ED8;
    font-size: 18px;
    font-weight: 600;
    border-radius: 4px;
    padding: 13px 30px;
    transition: all 0.5s ease;
    background: #1D4ED8;
}


.header.headermain.headermain_scroll .right_menu .nav-item a.nav-link:hover {
    background: #153aa1;
    color: #fff !important;
    border-color:#153aa1;
}

.header.headermain.headermain_scroll {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9;
    background: #000;
    animation: slide-down 0.7s;
    border-bottom: solid 1px #2A2A2A;
}

.header.headermain a.navbar-brand img.logo_onscroll{
      width: 0;
    transition: all 0.5s ease;
}

.header.headermain.headermain_scroll a.navbar-brand img {
    transition: all 0.5s ease;
    width: 0;
}

.header.headermain.headermain_scroll a.navbar-brand img.logo_onscroll {
    width: auto;
    transition: all 0.5s ease;
}


.header.headermain_scroll .navbar-expand-lg .navbar-nav .nav-link {
    color: #fff !important;
}

.header.headermain_scroll .navbar-expand-lg .navbar-nav .nav-link img{

	filter: brightness(0) invert(1);
}


/*popup*/

.mobilenum {
    position: relative;
    z-index: 1;
}

.mobilenum span {
    position: absolute;
    top: 0;
    left: 0;
    width: 48px;
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-right: solid 1px #E5E5E5;
}

.dotpe_popouter.dotpe_popouter_new span.closebtn_new {
    position: absolute;
    right: -50px;
    top: 0px;
    z-index: 5;
    opacity: 1;
    transition: all 0.5s ease;
    background: rgba(255,255,255,0.25);
    width: 32px;
    height: 32px;
    border-radius: 100%;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
}
.busi_loan_form form {
    background: #fff;
    border-radius: 8px;
    padding: 35px;
    margin-right: 0;
    margin-bottom: 0px;
    position: relative;
    bottom: -20px;
}

.busi_loan_form form .form-control {
    border-color: #bfbaba;
    height: calc(54px + 0px);
    transition: all 0.5s ease;
}

.busi_loan_form form .form-group {
    margin-bottom: 16px;
    position: relative;
    z-index: 1;
}
.formlead_btn {
    text-align: right;
}

.busi_loan_form form button {
    width: auto;
    border-radius: 8px;
    height: 54px;
    background: #1D4ED8;
    transition: all 0.5s ease;
    border-color: #1D4ED8;
    padding: 0 35px 3px 35px;
    font-weight: 600;
    position: relative;
    z-index: 1;
}

.busi_loan_form form button:after{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #153aa1;
    height: 0;
    width: 100%;
    content: "";
    z-index: -1;
    border-radius: 4px;
    transition: all 0.7s ease;
}

.busi_loan_form form button:hover:after {
    height: 100%;
}

.busi_loan_form form button:hover {
    background: #153aa1;
    color: #fff;
}

.busi_loan_form form .form-control:focus {
    border: solid 2px #1D4ED8;
    box-shadow: none;
}

/* .busi_loan_form form .form-group span.error {
    position: absolute;
    bottom: -23px;
    color: red;
    font-size: 14px;
    top: inherit;
    width: auto;
    height: auto;
    display: inherit;
} */

.busi_loan_form form .form-group span.error {
    position: inherit;
    bottom: 0;
    color: red;
    font-size: 14px;
    top: inherit;
    width: auto;
    height: auto;
    display: inherit;
    text-align: left;
    border: none;
}



.dotpe_popup.dotpe_popup2 {
    overflow: hidden;
}

.form_click {
    text-align: left;
    font-size: 11px;
    line-height: 20px;
    color: #696969;
    margin: 10px auto 35px auto;
    float: left;
    width: 100%;
}

.dotpe_popup {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
}

.dotpe_popup .dotpe_left {
    flex: 0 0 45%;
    max-width: 45%;
    background: #1B2D46;
    color: #fff;
    /* height: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px 0 0 15px;
    overflow: hidden;
}

.dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos {
    /* height: 100%; */
    background: #1B2D46;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 0 0 0 40px;
}
.ReactModalPortal .ReactModal__Content {
    padding: 0 !important;
	inset: 54% auto auto 50%;
}





.ReactModalPortal .ReactModal__Content h2 {
    display: none;
}


.ReactModalPortal .ReactModal__Content {
    padding: 0 !important;
    border: none !important;
    margin-right: -5% !important;
    background: none !important;
}

.busi_loan_form form .mobilenum .form-control {
    padding-left: 59px;
}
.dotpe_left_infos h3 {
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    padding-right: 10px;
    margin: 0;
}

.dotpe_left_infos h4 {
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
    margin: 11px 30px 0px 0;
    color: #959EA9;
}

.dotpe_left_infos.dotpe_left2_mode_infos {
 font-size: 26px;
 line-height: 34px;
}

.dotpe_poplogo {
    padding: 0 0 30px 0;
    position: absolute;
    bottom: 0;
}

.dotpe_popup .dotpe_right {
    flex: 0 0 55%;
    max-width: 55%;
    padding: 0;
}

.dotpe_popouter .busi_loan_form form {
    padding: 40px 55px;
    bottom: 0;
    border-radius: 0 8px 8px 0;
    float: left;
    width: 100%;
}
.form_heading h3 {
    font-weight: 700;
    font-size: 34px;
    line-height: 42px;
}

.form_heading p {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    margin: 25px 0 30px 0;
}

.dotpe_popouter.dotpe_popouter_new  .busi_loan_form .nice-select {
    margin: 0;
    clear: inherit;
    float: left;
    position: relative;
    z-index: 99;
    width: 100%;
}

.dotpe_popouter.dotpe_popouter_new .busi_loan_form form .form-group.form_group_nice {
    z-index: 9;
    float: left;
    width: 100%;
}

.busi_loan_form .nice-select {
    width: 100%;
    margin: 0 0 20px 0;
    border-color: #E5E5E5;
    height: 51px;
    line-height: 51px;
}

.dotpe_popouter.dotpe_popouter_new .busi_loan_form form .form-group.form_group_nice .nice-select .nice-select-dropdown {
    z-index: 99;
}

.dotpe_popouter.dotpe_popouter_new .busi_loan_form form .form-group.form_group_nice label {
    float: left;
    width: 100%;
    z-index: -10;
    position: relative;
    font-weight: 600;
}

.busi_loan_form .nice-select::after {
    border-bottom: 1px solid #333;
    border-right: 1px solid #333;
    width: 7px;
    height: 7px;
    top: 45%;
}

.dotpe_popouter.dotpe_popouter_new .busi_loan_form form 
 .form-group.form_group_nice.form_group_nice2 {
    z-index: 8;
}

.formlead_btn.formlead_btn2 {
    padding-top: 50px;
    float: left;
    width: 100%;
}

.dotpe_left.dotpe_left2_mode {
    background: #0D781E;
}

.dotpe_popup.dotpe_popup2.dotpe_popup3  .dotpe_left_infos.dotpe_left2_mode_infos {
    background: #0D781E;
}


.dotpe_left_infos.dotpe_left2_mode_infos {
    align-items: center;
}

.dotpe_popcheck {
    text-align: center;
    margin-left: -40px;
}

.dotpe_popup.dotpe_popup2.dotpe_popup3  .dotpe_left_infos.dotpe_left2_mode_infos {
    align-items: center;
}

.dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos.dotpe_left2_mode_infos h4 {
    text-align: center;
    color: #9BC7A2;
}

.dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos.dotpe_left2_mode_infos .dotpe_poplogo {
    left: 40px;
}

/* .dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos.dotpe_left2_mode_infos {
    height: 600px;
} */

.sucess_right_tick span {
    background: none;
    display: block;
    text-align: center;
    margin: 60px auto 0 auto;
}

.sucess_popinfos h3 {
    font-size: 34px;
    line-height: 42px;
    font-weight: 700;
    text-align: center;
    margin: 20px auto;
    max-width: 100%;
    color: #000;
}

.sucess_popinfos p {
    font-size: 22px;
    line-height: 26px;
    color: #000;
    max-width: 100%;
    margin: 0px auto 30px auto;
}

.sucess_btn_expolor a {
    width: auto;
    border-radius: 8px;
    height: 54px;
    background: #1D4ED8;
    transition: all 0.5s ease;
    border-color: #1D4ED8;
    display: inline-block;
    color: #fff;
    font-size: 18px;
    line-height: 54px;
    padding: 0 65px;
    font-family: "Plus Jakarta Sans", sans-serif !important;
    transition: all 0.5s ease;
    margin: 10px 0 90px 0;
    position: relative;
    z-index: 1;
}


.sucess_btn_expolor a:after{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #153aa1;
    height: 0;
    width: 100%;
    content: "";
    z-index: -1;
    border-radius: 4px;
    transition: all 0.7s ease;
}

.sucess_btn_expolor a:hover:after {
    height: 100%;
}

.sucess_btn_expolor a:hover {
    background: #153aa1;
    color: #fff;
}

.sucess_right_tick {
    text-align: center;
    padding-top: 20px;
}

.dotpe_popup.sucess_popinfos {
    text-align: center;
    font-family: "Plus Jakarta Sans", sans-serif !important;
    flex-direction: column;
}

.sucess_btn_expolor a:hover {
    color: #fff;
}

section.sec-2.direct_sec2.whatsappsec {
    padding-top: 0;
}

.take_conbtn.whatsapp a {
    padding: 16px 92px;
}



.whatsapp_box.whatsapp_box2 {
    padding-left: 20px;
    position: relative;
    z-index: 1;
}

.whatsapp_box.whatsapp_box2:after {
    position: absolute;
    left: -20px;
    bottom: 0;
    width: 1px;
    height: 55px;
    content: "";
    background: #fff;
}

.whatsapp_box.whatsapp_box2:before {
    position: absolute;
    right: 40px;
    bottom: 0;
    width: 1px;
    height: 55px;
    content: "";
    background: #fff;
}

section.sec-5.whats_tabimg {
    padding-top: 90px;
}

.point_img span img {
    width: auto;
}

section.sec-5.hm_ristapos {
    padding-top: 96px;
}

.tabs_btns {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.tabs_btns button.tab_middle_single:first-child {
    border-radius: 0px 35px 35px 0px;
}





.hmtabs_mainbox {
    position: relative;
    z-index: 1;
    max-width: 100%;
    margin: 0 auto;
}

.hmtabs_arrows .row {
    justify-content: space-between;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 45%;
}

.hmtabs_arrows .row button {
    border: solid 1px #C3C3C3;
    width: 52px;
    height: 52px;
    border-radius: 100%;
    line-height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
}

.hmtabs_arrows .row .col-auto.col-auto_left {
    position: relative;
    left: -75px;
}

.hmtabs_arrows .row .col-auto.col-auto_right {
    right: -75px;
}

.hmtabs_arrows .row .col-auto.col-auto_right button.next-button, .hmtabs_arrows .row .col-auto.col-auto_left button.prev-button {
    border-color: #1361F5;
    background: none;
}

.hmtabs_arrows .row .col-auto.col-auto_left button.prev-button.disabled, .hmtabs_arrows .row .col-auto.col-auto_right button.next-button.disabled {
    border-color: #C3C3C3;
}

.banner_noimage .banner-image.hmbanner_img span {
    height: 57vh;
    background: #D9D9D9;
}

.banner_noimage .banner-image.hmbanner_img span.hmbanner_img2 {
    height: 20vh;
}


.point_img.point_noimg span {
    height: 40vh;
    background: #6F6F6F;
    display: inline-block;
    width: 100%;
}

.whats_connect .hm_heading span {
    color: #4AF884;
}

.whats_connect .hm_heading img {
    margin: 0 5px;
}

.tab_blockinfo_main {
    position: relative;
    z-index: 1;
}

.header.headermain.headerdark .navbar-nav a.nav-link {
    color: #fff !important;
}

.header.headermain.headerdark .navbar-nav a.nav-link img {
    filter: brightness(0.5) invert(1);
}

.header.headermain.headerdark ul.navbar-nav.ml-auto .nav-item .nav-link {
    color: #fff !important;
    border: solid 1px #fff;
    font-size: 18px;
    font-weight: 700;
    border-radius: 4px;
    padding: 13px 30px;
    transition: all 0.5s ease;
}

.header.headermain.headerdark ul.navbar-nav.ml-auto .nav-item .nav-link:hover {
    background: #1D4ED8;
    color: #fff !important;
    border-color: #1D4ED8;
}

.header.headermain.headerdark.headermain_scroll a.navbar-brand img {
    width: auto;
    margin: 0 0 0px 0;
}

.header.headermain.headerdark.headermain_scroll ul.navbar-nav.ml-auto .nav-item .nav-link {
    background: #1D4ED8;
    color: #fff !important;
    border-color: #1D4ED8;
    position: relative;
    z-index: 1;
}

.header.headermain.headerdark.headermain_scroll ul.navbar-nav.ml-auto .nav-item .nav-link:after {
     position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #153aa1;
    height: 0;
    width: 100%;
    content: "";
    z-index: -1;
    border-radius: 4px;
    transition: all 0.7s ease;

}

.header.headermain.headerdark.headermain_scroll ul.navbar-nav.ml-auto .nav-item .nav-link:hover:after {
height: 100%;
}


.new-classes {
  background-color: rgb(0, 255, 229) !important;  
  position: sticky;
  top: 20px;
  z-index: 1000; 
  background-color: #fff; 
  width: 100%; 
}

section.sec-3.sec3_home.visible-section .rista_tabs.new-class {
    background: #333333;
    position: sticky;
    top: 97px;
    left: 0;
    right: 0;
    padding: 40px 0;
    z-index: 2;
}



section.sec-3.sec3_home .rista_tabs {
    padding: 50px 0;
    transition: all 0.5s ease;
}

section.sec-3.sec3_home.visible-section .rista_tabs.new-class .nav.nav-pills .nav-item a {
    color: #fff;
}

section.sec-3.sec3_home.visible-section .rista_tabs.new-class .nav.nav-pills .nav-item a.nav-link.active {
    background: #fff;
    color: #000;
}

section.sec-3.sec3_home.visible-section .tab_blockinfo_main {
    margin-top: 120px;
}

.banner-image.hmbanner_img img.ban-last-img {
    border-radius: 8px;
}

.hm_heading h1 {
    font-size: 22px;
    line-height: 34px;
    font-weight: 500;
    text-transform: uppercase;
}

.hm_heading h1 span {
    font-size: 42px;
    line-height: 52px;
    max-width: 70%;
    display: block;
    margin: 8px auto 24px auto;
    font-weight: 800;    text-transform: initial;
}

.hm_heading h2 {
    font-size: 15px;
    line-height: 26px;
    max-width: 57%;
    font-family: "Inter", sans-serif;
    margin: 0 auto 30px auto;
}

.hm_heading.hm_heading_big h3.new_style span {
    font-size: 48px;
    display: block;
    line-height: 56px;
    font-weight: 800;
}

.hm_heading.hm_heading_big h3.new_style {
    font-size: 22px;
    line-height: 34px;
    font-weight: 500;
    max-width: 55%;
}
p.tab_lastline {
    margin-top: 30px;
    /* display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between; */
    font-size: 18px;
}
/* p.tab_lastline strong {
    flex: 0 0 auto;
    max-width: 100%;
    padding-right: 20px;
} */



p.tab_lastline a.hm_fillbtn {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    padding: 8px 28px 10px 28px;
    background: #1D4ED8;
    float: none;
    color: #fff;
    border-radius: 4px;
    position: relative;
    z-index: 1;
    margin: 0 0 0 10px;
    transition: all 0.5s ease;
    display: block;
}

p.tab_lastline a.hm_fillbtn:hover{background: #153aa1;}


.point_btns.point_btns_mob a {
    color: #1D4ED8;
    font-size: 16px;
    line-height: 24px;
    padding: 10px 26.2px 14px 26.2px;
    border-radius: 4px;
    display: inline-block;
    margin-top: 10px;
    transition: all 0.5s ease;
    font-weight: 700;
    background: none;
    min-width: 130px;
}

.point_btns.point_btns_mob a img {
    margin-left: 5px;
}
.point_btns.point_btns_mob {
    text-align: center; display: none;
}

/* .banner-mobile .ban-last span {
    display: none;
} */

.mobfuel{ display: none;}

/*posproductpage*/


section.banner.banner_renew_posprobg .banner-heading span.home_banner_subheading {
    display: block;
}

section.sec-2.sec2_pospro {
    padding-top: 100px;
}

section.sec-3.sec3_pospro .heading span {
    color: #1D4ED8;
}

section.sec-3.sec3_home.visible-section.sec3_pospro .rista_tabs.new-class {
    top: 93px;
}

section.sec-3.sec3_home.visible-section.sec3_pospro .tab_blockinfo_main {
    margin-top: 40px;
}

section.sec5new.sec_space.sec5new_propage {
    padding-top: 0;
    padding-bottom: 0;
}

section.sec5new.sec_space.sec5new_propage .sec5new_top {
    background: #212225;
}

section.sec5new.sec_space.sec5new_propage .sec5new_bottom {
    background: #212225;
}

.tabs_btns button.tab_left_single {
    float: right;
    text-align: right;
    margin-left: auto;
    width: 197px;
    right: -15px;
    position: relative;
}

.tabs_btns button.tab_right_single {
    margin-right: auto;
    width: 197px;
    display: flex;
    flex-direction: row-reverse;
    border-radius: 0px 35px 35px 0px;
    position: relative;
    left: -15px;
}
.tabs_btns button.tab_right_single span {
    padding: 0 10px 0 0;

}

.tabs_btns button.tab_right_single span img {
    transform: rotate(180deg) translateY(2px);
}

.tabs_btns button.tab_middle_single:first-child {
    border-left: none;
    display: flex;
    flex-direction: row-reverse;
    position: relative;
    left: -15px;
}

.tabs_btns button.tab_middle_single {
    position: relative;
    right: -15px;
}

.tabs_btns button.tab_middle_single:first-child span {
    padding: 0 10px 0 0;
}

.tabs_btns button.tab_middle_single:first-child span img {
    transform: rotate(180deg) translateY(1px);
}

.banner_renew_posprobg .banner-mobile .ban-last span {
    display: block;
}

.banner_renew_posprobg .banner-mobile .ban-last span {
    display: inline-block;
    color: rgba(255,255,255,0.8);
    margin: 0 0 0 4px;
}

section.sec-3.sec3_home.sec3_pospro .heading {
    font-size: 22px;
}

section.sec-4.sec4_pospro .heading {
    font-size: 22px;
    margin-bottom: 8px;
    text-transform: uppercase;
}

section.sec-4.sec4_pospro .subheading {
    line-height: 52px;
}
.sec-5 .heading {
    text-align: center;
    font-size: 22px;
    margin-bottom: 8px;
    text-transform: uppercase;
}

.sec-6 .heading {
    font-size: 22px;
    margin-bottom: 8px;
}

.sec-6 .subheading {
    text-align: center;
    font-size: 42px;
    line-height: 52px;
}



.name_profile {
    text-align: left;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    padding: 0 0 34px 0;
}

.name_profile span {
    display: block;
    font-size: 16px;
    font-weight: 400;
}

.test-2 {
    margin-top: 42px;
}

/* .accordion.accordion_rista.accordion_rista2.accordion_rista3.accord_plusminus .card .card-header .btn-header-link span.plusminus {position:absolute;z-index:1;width:38px;display:inline-block;right: 10px;top: 0px;}


.accordion.accordion_rista.accordion_rista2.accordion_rista3.accord_plusminus .card .card-header .btn-header-link.collapsed span.plusminus:before {
    font-size: 0;
    color: #252B42;
    background: none;
    top: 31px;
    transition: all 0.5s ease;
    width: 28px;
    height: 3px;
    background: #252B42;
    position: absolute;
    content: "";
    right: 0px;
    transform: rotate(90deg);
    display: block;
}

.accordion.accordion_rista.accordion_rista2.accordion_rista3.accord_plusminus .card .card-header .btn-header-link  span.plusminus:after {
    font-size: 0;
    font-weight: 300 !important;
    color: transparent;
    background: #1361F5;
    height: 3px;
    border-radius: 5px;
    top: 31px;
    transition: all 0.5s ease;
    width: 32px;
    position: absolute;
    content: "";
    right: -2px;
}

.accordion.accordion_rista.accordion_rista2.accordion_rista3.accord_plusminus .card .card-header .btn-header-link:after {
    display: none;
}
.accordion.accordion_rista.accordion_rista2.accordion_rista3.accord_plusminus .card .card-header .btn-header-link:before {
    display: none;
}

.accordion.accordion_rista.accordion_rista2.accordion_rista3.accord_plusminus .card .card-header .btn-header-link.collapsed  span.plusminus:after {
     background: #252B42;
}

.accordion.accordion_rista.accordion_rista2.accordion_rista3.accord_plusminus .card .card-header .btn-header-link span.plusminus:before{
    display: none;
} */


.header.headerdark .navbar-light .navbar-toggler-icon {
    filter: brightness(0.5) invert(1);
}

section.sec-8.faq_btm.faq_btm_pros .col-lg-8 {
    flex: 0 0 75%;
    max-width: 75%;
}

.accordion.accordion_rista.accordion_rista2.accordion_rista3.accord_plusminus .btn.btn-header-link {
    padding-right: 45px;
}

.pospage_mobileimg{ display: none;}

.getdemo_float{ display: none;}


.sec-4 .block-3 img.outletmob {
    display: none;
}

/*auxilary pages*/

section.temrms_main_infos {
    padding: 105px 0;
}

.auxilary_head {
    text-align: center;
}

.auxilary_head h2 {
    font-size: 48px;
    line-height: 64px;
    font-weight: 800;
    letter-spacing: -1px;
}

.auxilary_head p {
    font-size: 16px;
    font-weight: 500;
    max-width: 670px;
    margin: 20px auto 50px auto;
}

.terms_content h4 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    letter-spacing: -0.5px;
    text-transform: uppercase;
}

/****/


section.temrms_main_infos {
    padding: 105px 0;
}

.auxilary_head {
    text-align: center;
}

.auxilary_head h2 {
    font-size: 48px;
    line-height: 64px;
    font-weight: 800;
    letter-spacing: -1px;
}

.auxilary_head p {
    font-size: 16px;
    font-weight: 500;
    max-width: 670px;
    margin: 20px auto 50px auto;
}

.terms_content h4 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    letter-spacing: -0.5px;
    text-transform: uppercase;
}

.terms_block {
    margin: 0 0 30px 0;
}

section.aboutmain {
    padding: 50px 0;
}

.about_head {
    text-align: center;
}

.about_head h5 {
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;
}

.about_head h2 {
    font-weight: 900;
    font-size: 48px;
    line-height: 64px;
    text-transform: capitalize;
    max-width: 50%;
    margin: 0 auto 30px auto;
}

.about_head p {
    font-size: 16px;
    line-height: 28px;
    color: #4D4D4D;
    font-weight: 500;
    max-width: 590px;
    margin: 0 auto 10px auto;
    font-family: "Inter", sans-serif;
}

section.aboutmain {
    padding: 90px 0;
}

.about_head {
    text-align: center;
}

.about_head h5 {
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;
}

.about_head h2 {
    font-weight: 800;
    font-size: 48px;
    line-height: 64px;
    text-transform: inherit;
    max-width: 50%;
    margin: 0 auto 30px auto;
}

.about_head p {
    font-size: 16px;
    line-height: 28px;
    color: #4D4D4D;
    font-weight: 400;
    max-width: 590px;
    margin: 0 auto 10px auto;
    font-family: "Inter", sans-serif;
}

.aboutcards {
    text-align: center;
    padding: 0 0 90px 0;
}

.aboutcards .aboutcard_icon {
    min-height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6px;
}

.aboutcards .about_infos h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000;
}

.aboutcards .about_infos p {
    font-size: 15px;
    line-height: 24px;
    font-weight: 500;
    max-width: 60%;
    margin: 0 auto 0 auto;
}


.abouticons_grid {padding-bottom: 40px;}


.about4infos {
    max-width: 760px;
    padding-right: 170px;
}

.about4infos h3 {
    font-size: 48px;
    line-height: 64px;
    font-weight: 800;
    color: #000;
    margin: 0 0 30px 0;
}

.about4infos p {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: #4D4D4D;
    margin: 0 0 20px 0;
}

.about4infos p b {
    color: #000;
}

.aboutpg_images {
    position: absolute;
    top: 200px;
    right: 0;
}

.about4main {
    position: relative;
    z-index: 1;
    padding: 0 0px 520px 0;
}

.aboutpg_images .about_pgimgone {
    border-radius: 74px 0 0 74px;
    overflow: hidden;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
}

.aboutpg_images .about_pgimgone img {
    width: 576px;
}

.about_pgimgone.about_pgimgtwo {
    border-radius: 0;
    position: absolute;
    bottom: -30px;
    right: 470px;
}

.about_head.about_head_last h2 {
    line-height: 72px;
    max-width: 100%;
}

.about_head.about_head_last p {
    font-size: 15px;
    line-height: 24px;
    margin: 0 auto 70px auto;
    max-width: 57%;
}

.conatct_form form {
    max-width: 575px;
    margin: 0 auto;
}

textarea.form-control.form_textarea {
    min-height: 185px;
}

.busi_loan_form form .form_click {
    font-size: 14px;
    color: #000;
    line-height: 22px;
}

.busi_loan_form form .form_click a {
    font-size: 14px;
    font-weight: 700;
    color: #000;
}

.conatct_form form button.btn.btn-primary {
    width: 100%;
    border-radius: 4px;
    background: #1D4ED8;
    border-color: #1D4ED8;
    position: relative;
    z-index: 1;
}

.conatct_form form button.btn.btn-primary:after{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #153aa1;
    height: 0;
    width: 100%;
    content: "";
    z-index: -1;
    border-radius: 4px;
    transition: all 0.7s ease;
}

.conatct_form form button.btn.btn-primary:hover:after {
    height: 100%;
}

.conatct_form form button.btn.btn-primary:hover {
    background: #153aa1;
    color: #fff;
}

.conatct_form {
    padding: 0px 0 70px 0;
}

.contact_thakss p {
    color: #0FA76E;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
}

.contact_thakss {
    text-align: center;
}

.contact_thakss p img {
    margin: 0 6px 0 0;
}

.contact_infosin p {
    background: #EBF1FF;
    font-size: 17px;
    font-weight: 900;
    color: #000;
    display: inline-block;
    padding: 15px 35px;
    border-radius: 4px;
}

.contact_infosin p a.contactbtns {
    height: 54px;
    background: #1D4ED8;
    transition: all 0.5s ease;
    border-color: #1D4ED8;
    padding: 0 45px 3px 45px;
    font-weight: 700;
    display: inline-block;
    line-height: 51px;
    color: #fff;
    border-radius: 4px;
    font-size: 18px;
    margin: 0 0 0 40px;
    transition: all 0.5s ease;
    position: relative;
    z-index: 1;
}

.contact_infosin p a.contactbtns:after{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #153aa1;
    height: 0;
    width: 100%;
    content: "";
    z-index: -1;
    border-radius: 4px;
    transition: all 0.7s ease;
}

.contact_infosin p a.contactbtns:hover:after {
    height: 100%;
}

.contact_infosin p a.contactbtns:hover {
    background: #153aa1;
    color: #fff;
}

.aboutteam_slides {
    padding: 50px 0 30px 0;
}

.aboutteam_slides {
    padding: 50px 0 30px 0;
}

.aboutteam_slides .owl-item:nth-child(odd) .about_teamimg {
    filter: saturate(0%);
    transition: all 0.5s ease;
}

.aboutteam_slides .owl-item:nth-child(even):hover .about_teamimg {
    filter: saturate(0%);
    transition: all 0.5s ease;
}

.aboutteam_slides .owl-item:nth-child(odd):hover .about_teamimg {
    filter: inherit;
}

.aboutteam_slides .about_teamimg {
    border-radius: 8px;
    overflow: hidden;
}

.aboutpg_images.aboutpg_images_mobile {
    display: none;
}

.busi_loan_form form button {
    width: 100%;
}

@keyframes slide-down {
  0% {
    /*opacity: 0;*/
    transform: translateY(-100%);
  }
  100% {
    /*opacity: 0.9;*/
    transform: translateY(0);
  }
}

.footer_infoslinks ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer_infoslinks ul li a {
    color: #fff;
    font-size: 16px;
    line-height: 23px;
}

.footer_infoslinks ul li {
    margin: 0 0 14px 0;
}

.footerlogo_main {
    height: 240px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.footerlogo_main .contact_infos a {
    color: #fff;
}

.footerlogo {
    margin: 0 0 20px 0;
}

.contact_infos span {
    filter: brightness(0) invert(1);
    display: inline-block;
    margin: 0 17px 0 0;
}

.contact_infos span img {
    width: 23px;
    display: inline-block;
}

.contact_infos {
    margin-top: 18px;
}

.copyright_line {
    color: #A0A0A0;
}

.footerlogo_mobile .footerlogo{ display: none;}

.copyright_line.copyright_line_mobile {
    display: none;
}

li.termslink {
    display: none;
}

section.sec-8.faq_btm.faq_btm_pros .accordion_rista.accordion_rista2 .card {
    padding-right: 20px;
}

section.sec-8.faq_btm.faq_btm_pros .accordion.accordion_rista.accordion_rista2.accordion_rista3.accord_plusminus .btn.btn-header-link {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.sec-8 .accordion_rista .card .card-header .btn-header-link::after {
    content: "_";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    float: none;
    transform: inherit;
    position: inherit;
    left: inherit;
    font-size: 28px;
    height: 100%;
    right: 0px !important;
    position: absolute !important;
    left: inherit !important;
    top: 20px !important;
}

li.nav-item.mobilenavlink {
    display: none;
}

.contctt_con{ padding-top:40px;}
section.sec4new.sec_space {
    padding-bottom: 96px;
    padding-top: 96px;
}

.hm_why_infos p {
    font-size: 14px;
}

section.sec-5.hm_ristapos .accordion_rista .card .card-header .btn-header-link::after {
    top: 6px;
}

section.sec-5.hm_ristapos .accordion_rista .card .card-header .btn-header-link.collapsed::after {
    top: 21px;
}

.hm_3boxes .accordion_rista.accordion_rista2 .card .card-header .btn-header-link {
    padding-left: 0;
}

.hm_3boxes .accordion_rista.accordion_rista2 .card-body {
    background: none;
    padding-left: 3px;
}

section.sec-5.hm_ristapos .accordion_rista .card-body {
    padding-right: 40px;
    padding-bottom: 29px;
}

.sec-4 .block-3 img {
    transform: scale(1.25) translate(5px, 0px);
}

.sec-4 .block-1 .col-lg-6 {
    padding: 0 10px;
}

.sec-4 .block-2 .block2_tabimg {
    height: 500px;
    object-fit: fill;
}

.sec-4 .block-2 .block2_tabimg img {
    height: 100%;
    object-fit: cover;
}

/* .sec-4 .block-1.mincontt .block-3.block_2-topbox h3 {
    min-height: 40px;
} */


.take_conbtn {
    padding-top: 20px;
}

.set_big_slides_section .sec-6 .sec-2-item .test-1 {
    min-height: 330px;
}

section.footer-bar-up.set_invt-footerbar h3.cos-text {
    line-height: 44px;
}

.sec-4 .block-1.blockone_main .block-3 p {
    min-height: 95px;
}

.sec-4 .block-1 span.deskicon {
    min-height: 30px;
    display: inline-flex;
    align-items: flex-end;
}

.sec-4 .blockone_maintop .block-2 .block2_tabimg {
    height: 451px;
}

.outlets_tabsmain {
    position: relative;
    top: -130px;
}

.sec-4 .blockthree_maintop .block-2 .block2_tabimg {
    height: 417px;
}

section.sec-5.whats_tabimg.set_whatsapptab_section.set_whatsapptab_second_section p {
    max-width: 70%;
    margin: 0 auto 70px auto;
}

.sec6_imgbox span {
    width: 100%;
    height: 230px;
    object-fit: cover;
}

.sec6_imgbox span img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.sec3_home_tabs .point_row_grid .point_img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.sec3_home_tabs .point_row_grid .point_img span {
    width: 100%;
    height: 480px;
    object-fit: cover;
}

section.sec-5.hm_ristapos .selected-image {
    object-fit: cover;
    width: 100%;
    height: 461px;
}

section.sec-5.hm_ristapos .selected-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}


.hm_3boxes .point_block_main .point_acordimg span {
    width: 100%;
    height: 480px;
    object-fit: cover;
}

.hm_3boxes .point_block_main .point_acordimg span img {
    width: 100%;
    object-fit: contain;
}

.sec3_pospro .term .point_powerimg {
    height: 385px;
    display: inline-block;
    width: 100%;
    object-fit: cover;
}

.sec3_pospro .term .point_powerimg img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.hmtabs_arrows .row .col-auto.col-auto_left button.prev-button.disabled img {
    filter: brightness(0.5);
}

.header .navbar-expand-lg .navbar-nav a.nav-link.active {
    color: #000 !important;
    font-weight: 600;
}
.header.headerdark .navbar-expand-lg .navbar-nav a.nav-link.active {
    color: #fff !important;font-weight: 600;
}


.deaktop_takecontrol {
    height: 560px;
    overflow: scroll;
    overflow-x: hidden;
}

.deaktop_takecontrol .takecon_slide_box {
    margin-bottom: 60px;
}

.take_controlamin .owl-slider.tabs_owl_crousel {
    display: none;
}

.deaktop_takecontrol::-webkit-scrollbar {
    -webkit-appearance: none;
}

section.sec_space.take_control_sec.set-invt-control-section {
    padding: 122px 0;
}

.header div#navbarNavDropdown {
    justify-content: space-between;
}

.banner_home_new .banner-image.hmbanner_img span {
    height: 600px;
    object-fit: cover;
    width: 100%;
}

.banner_home_new .banner-image.hmbanner_img span img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.banner_home_new .banner-image.hmbanner_img span.hmbanner_img2 {
    height: 336px;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 10 !important;
}

.sec-2 .owl-dots {
    display: none;
}

.footer_centerterms {
    display: none;
}


section.sec-3.sec3_home.sec3_home_tabs {
    padding-bottom: 0px;
}

.points_tick  ul {
    margin: 20px 0 0 0;
    padding: 0;
    list-style: none;
}

.points_tick ul li {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #000;
    margin: 15px 0 -12px 0;
    position: relative;
    padding: 0 0 0 30px;
}

.points_tick  ul li:after {
    position: absolute;
    left: 7px;
    top: 3px;
    bottom: 0;
    right: 0;
    width: 7px;
    height: 13px;
    content: '';
    border-style: solid;
    border-color: transparent #1D4ED8 #1D4ED8 transparent;
    border-width: 0px 2px 2px 0px;
    z-index: 1;
    transform: rotate(45deg);
}
.footer-bar-up .fotr_barup_infos h2.subheading.subheading_top {
    margin-top:25px;
}

section.sec3new.sec_space.postestimonial.direct_ordr_most.inverntry_m_review {
    padding-top: 96px;
    background: #f5f5f5;
}

.hm_heading.posblckhm_heading {
    text-align: center;
}

.posblckhm_heading .subheading {
    text-align: center;
}

.posblckhm_heading p {
    color: #fff;
    text-align: center;
    font-size: 15px;
    line-height: 26px;
    max-width: 57%;
    margin: 24px auto 60px auto;
}

section.banner.banner_home.banner_direct_ordering.set_inventory_banner  .banner-heading span {
    margin-left: 0;
}

/*5 aug design changes*/
.perfect_grid_mobile{
    display: none;
}

.owl-carousel .owl-item .sec6_imgbox img {
    /* display: none; */
}

.pos_plygrid_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.pos_plygrid_img span {
    height: 350px;
    width: 100%;
}

.pos_plygridinfos h3 {
    font-size: 32px;
    margin: 0 0 30px 0;
    font-weight: 700;
    line-height: 42px;
    max-width: 60%;
}

.pos_plygridinfos ul li {
    font-size: 15px;
    line-height: 25px;
    margin: 0 0 20px 0;
    position: relative;
    z-index: 1;
    padding: 0 23% 0 35px;
    font-weight: 500;
}

.pos_plygridinfos ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.pos_playgrid {
    padding-top: 80px;
}

.pos_plygridinfos ul li:after {
    position: absolute;
    left: 0;
    top: 10px;
    content:"";
    width:18px;
    height:14px;
    background: url(../../images/posply_arrow.svg) left top no-repeat;
}

.pos_playgrid.pos_playgrid_right .row {
    flex-direction: row-reverse;
}

.pos_playgrid.pos_playgrid_right .row .pos_plygridinfos {
    padding: 0 0 0 30px;
}

.pos_playgrid.pos_playgrid_right .row .pos_plygridinfos li {
    padding-right: 21%;
}


.sec4newa_grid.sec4newa_grid_pospage{
    display: none;
}

.ban-last-img.hm_mainbanmobile{
    display: none;
}

.doyunow {
    border-bottom: solid 1px #CBCBCB;
    padding: 0 0 40px 0;
    margin-top: 60px;
}

.doyunow .dou_heading {
    font-size: 28px;
    line-height: 35px;
    font-weight: 700;
}

.doyunow .douinfos p {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
}

.doyunow .row {
    align-items: center;
}

.take_con_right.desktop-design-slides{
    display: none;
}

.mobile-design-accordian .card .mobile-accord-img {
    display: none;
}

section.sec-3.sec3_home.sec3_inverntry.sec3_home_tabs {
    padding-bottom: 96px;
}

.invent_mobile{ display: none;}

.take_conbtn.take_conbtn_accords{
    display: none;
}

.row.mid.hm_hidesec {
    display: none;
}

.rista_go_newinfos{ text-align: center;}

section.sec-5.sec-5_pospage .subheading {
    max-width: 80%;
    margin-bottom: 30px;
}

.rista_go_newinfos p {
    font-size: 15px;
    margin: 0;
    line-height: 26px;
    max-width: 57%;
    margin: 0 auto 0 auto;
}

.sec5a_posinfos {
    padding: 192px 0;
    background: #0C0C0C;
}

/* .sec5a_contt .hm_heading{ color: #fff;} */


.sec5a_contt .hm_heading p {
    font-size: 22px;
    margin-bottom: 0;
    max-width: 55%;
    line-height: 31px;
}

.sec5a_contt .hm_heading.hm_heading_big h3 {
    max-width: 42%;
}

.hm_srvccard_icon.hm_srvccard_icon_white img {
    filter: brightness(0) invert(1);
}

.sec5new_inner.poply_desk .sec5newa_grid .hm_srvccards.hm_srvccards2 {
    margin-top: 35px;
}

.take_control_sec.set-invt-control-section .card-header .title {
    text-transform: capitalize;
}

.doyunow_starline{  padding:5px 0 0 0;}

.doyunow_starline p small{ font-size: 12px;}

.sec5ins {
    position: relative;
    top: -2px;
}

.direct_ordr_most.set_elevate-section .hm_heading p {
    max-width: 57%;
}

.footer-bar-up.whatsapp_footer_bar h5.order-txt.order_textnewwp span {
    background: #232323;
    border-radius: 8px;
    margin: 0 10px 10px 0;
    display: inline-block;
    padding: 3px 13px;
    line-height: 25px;
    font-size: 15px;
}

section.footer-bar-up.pos_footer_page .heading {
    max-width: 60%;
}

.tabs_btns {
    display: none;
}


@media (min-width: 1024px) {

    .header.headermain.headermain_scroll {
     background:#fff;
     backdrop-filter: blur(0.5px);
     border-bottom: solid 1px #ddd;
}

.header.headermain_scroll .navbar-expand-lg .navbar-nav .nav-link {
    color: #000 !important;
}

.header.headermain.headermain_scroll a.navbar-brand img {
    transition: all 0.5s ease;
    width: auto;
}

.header.headermain.headerdark.headermain_scroll {
    background: #000;
    border-bottom: solid 1px #2A2A2A;
}

.header.headermain.headermain_scroll a.navbar-brand img.logo_onscroll {
    width: 0;
    transition: all 0.5s ease;
}



}


@media (min-width: 1366px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1280px !important;
    }
}


@media (max-width: 1780px) {
    .ReactModalPortal .ReactModal__Content {
        margin-right: -11% !important;
    }
}

/* @media (max-width: 1780px) {
    section.banner.banner_renew_posprobg .container {
    padding: 0 0 0 170px;
}

} */

.footerlogo_mobile .contact_infos {
    display: none;
}

.sec-8.faq_mainpage {
    padding: 56px 0;
}

.rista_title_faq {
    text-align: center;
}



@media (max-width: 1600px) {
	
.banner-heading {
    font-size: 42px;
    line-height: 54px;
    max-width: 84%;
    margin: 15px 0 25px 0;
}
.banner-para {
    margin-bottom: 34px;
}
.banner {
    padding: 56px 0 96px 0;
}
.sec-2 {
    margin-bottom: 74px;
}

.sec_space {
    padding: 96px 0;
}

.hm_heading h3 {
    font-size: 42px;
    line-height: 52px;
    max-width: 50%;
}

.sec3_n_btns {
    padding: 12px 0 0 0;
}

.hm_heading.hm_heading_big h3 {
    font-size: 42px;
    line-height: 54px;
}

.hm_heading.hm_heading_big h3.new_style span {
    font-size: 42px;
    line-height: 54px;
}



.sec-3 .nav.nav-pills .nav-item a {
    font-size: 15px;
    padding: 13px 34px;
}

.hm_3boxes {
    margin-top: 80px;
}

.sec-5 {
    padding: 96px 0 96px;
}

.hm_3boxes .point_box h3 {
    font-size: 42px;
    line-height: 50px;
}

.sec-8 .accordion_rista .card .card-header .btn-header-link {
    font-size: 24px;
}

section.sec-2.direct_sec2 {
    padding: 0 0;
    margin-bottom: 96px;
}

.sec4newa_grid.do_delivr_grid .hm_whycards .hm_why_infos h4 {
    font-size: 22px;
    line-height: 32px;
}

.dordr_adjuestsec .hm_heading.hm_heading_big h3 {
    max-width: 60%;
}

/* .busi_loan_form form .form-control {
    height: calc(48px + 0px);
    font-size: 14px;
} */


.dotpe_left_infos h3 {
    font-size: 27px;
    line-height: 39px;
}

.dotpe_left_infos h4 {
    font-size: 26px;
}

.form_heading p {
    font-size: 19px;
    margin: 18px 0 24px 0;
}

/* .dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos {
    height: 648px;
}

.dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos.dotpe_left2_mode_infos {
    height: 598px;
} */

section.banner.banner_renew_posprobg .banner-image img {
    width: 640px;
}

section.temrms_main_infos {
    padding: 65px 0;
}

.auxilary_head h2 {
    font-size: 42px;
    line-height: 52px;
}

.busi_loan_form form .form_click {
    padding-right: 15px;
}


}

@media (max-width: 1550px) {
.busi_loan_form form .form_click {
    padding-right: 35px;
}
/* 
.dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos {
    height: 671px;
} */

}

/* @media (max-width: 1530px) {

section.banner.banner_renew_posprobg .container {
    padding: 0 0 0 140px;
}

} */

@media (max-width: 1516px) {

	/* .dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos.dotpe_left2_mode_infos {
    height: 571px;
} */


}

@media (max-width: 1500px) {

	.dotpe_popouter .busi_loan_form form {
    padding: 40px;
}

.ReactModalPortal .ReactModal__Content {
    margin-right: -20% !important;
    inset:55% auto auto 50% !important;
}


/* .dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos.dotpe_left2_mode_infos {
    height: 556px;
} */

.dotpe_popcheck img {
    width: 120px;
}

.whatstabimg_sec .hm_heading.hm_heading_big h3 {
    max-width: 85%;
    padding: 0 0 50px 0;
}


/* section.banner.banner_renew_posprobg .container {
    padding: 0 0 0 110px;
} */

/* .dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos {
    height: 648px;
} */


}


@media (max-width: 1455px) {
	
.banner-heading {
    font-size: 40px;
    line-height: 50px;
}

.hm_heading.hm_heading_big h3 {
    font-size: 40px;
    line-height: 51px;
}

.hm_heading.hm_heading_big h3.new_style span {
    font-size: 42px;
    line-height: 52px;
    margin-top: 8px;
}

.hm_3boxes .point_box h3 {
    font-size: 36px;
    line-height: 46px;
}


section.footer-bar-up {
    padding: 120px 0;
}

/* section.banner.banner_renew_posprobg .container {
    padding: 0 0 0 87px;
} */

.outlets_tabsmain {
    position: relative;
    top: -106px;
}

section.banner.banner_renew_posprobg .banner-image img {
    width: 560px;
}
.ReactModalPortal .ReactModal__Content {
    inset: 60% auto auto 50% !important;
}
	
}

/* 
@media (max-width: 1400px) {
section.banner.banner_renew_posprobg .container {
    padding: 0 0 0 67px;
}


	
} */


@media (max-width: 1366px) {
.banner-heading {
    font-size: 42px;
    line-height: 49px;
    margin: 16px 0;
    margin-top: 0;
}
	.banner-para {
		margin-bottom: 40px;
	}
	.subheading {
		font-size: 40px;
		line-height: 125%;
	}
	.banner-image {
		margin-top: 80px;
	}
	.sec-3 .nav.nav-pills {
    margin: 46px 0;
}
	.sec-3 .nav.nav-pills .nav-item a {
		padding: 18px 40px;
	}
	.footer-bar-up h4 {
		font-size: 36px;
		line-height: 125%;
	}
	.sec-6 .test-2 img {
		height: 332px;
		object-fit: cover;
		width: 245px !important;
	}

	.hm_heading.hm_heading_big h3 {
		font-size: 38px;
		line-height: 48px;
	}

.hm_heading.hm_heading_big h3 {
    font-size: 42px;
    line-height: 52px;
    margin-bottom: 24px;
}

	.sec-3 .nav.nav-pills .nav-item a {
		padding: 15px 24px;
	}

	.whatsapp_box.whatsapp_box2:before {
    right: 20px;
}

section.sec-8.faq_btm.faq_btm_pros .col-lg-8 {
    flex: 0 0 80%;
    max-width: 80%;
}

.subheading {
    font-size: 42px;
    line-height: 52px;
}


/* section.sec-3.sec3_home.visible-section .rista_tabs.new-class {
    background: none;
    position: inherit;
    top: 0;
    left: 0;
    right: 0;
    padding: 0;
    z-index: 2;
}
section.sec-3.sec3_home.visible-section .rista_tabs.new-class .nav.nav-pills .nav-item a {
    color: #000;
}

section.sec-3.sec3_home.visible-section .tab_blockinfo_main {
    margin-top: 0;
} */

section.sec-2.sec2_pospro {
    padding-top: 96px;
}

section.sec-2 {
    margin-bottom: 96px;
}

section.sec-3.sec3_home .rista_tabs {
    padding: 0px 0;
}

/* section.sec-3.sec3_home.visible-section.sec3_pospro .rista_tabs.new-class {
    top: 0;
}

section.sec-3.sec3_home.visible-section.sec3_pospro .rista_tabs.new-class {
    top: 0;
    padding: 0;
}

section.sec-3.sec3_home.visible-section.sec3_pospro .tab_blockinfo_main {
    margin-top: 0;
} */

.sec-4 .block-3 p {
    min-height: 96px;
}

section.banner.banner_renew_posprobg .banner-heading {
    font-size: 36px;
    line-height: 46px;
    max-width: 80%;
}
/* section.banner.banner_renew_posprobg .container {
    padding: 0 0 0 97px;
} */

section.banner.banner_renew_posprobg .banner-para {
    max-width: 63%;
    line-height: 27px;
}

.footer-bar-up .heading {
    font-size: 16px;
    line-height: 32px;
}

.hm_srvccards .hm_srvc_infos p {
    max-width: 70%;
}

section.temrms_main_infos {
    padding: 55px 0;
}

.about_head h2 {
    font-size: 42px;
    line-height: 57px;
}

.about4infos h3 {
    font-size: 42px;
    line-height: 54px;
}

.aboutpg_images .about_pgimgone img {
    width: 456px;
}


.aboutpg_images .about_pgimgone.about_pgimgtwo img {
    width: 410px;
}


.aboutpg_images .about_pgimgone {
    border-radius: 44px 0 0 44px;
    bottom: -40px;
    right: 390px;
}

.about4main {
    padding: 0 0px 400px 0;
}

.footer-bar-low .col-lg-5 {
    flex: 0 0 33%;
    max-width: 33%;
}

.footer-bar-low .col-lg-7 {
    flex: 0 0 67%;
    max-width: 67%;
}

.footer-bar-low ul {
    gap: 49px;
}

section.sec-3.sec3_home.visible-section .rista_tabs.new-class .nav.nav-pills
{
    margin: 0;
}

.sec3_home_tabs .point_block_main {
    padding-right: 0;
    margin: 0 80px;
}

.hmtabs_arrows .row .col-auto.col-auto_left {
    position: relative;
    left: 0;
}

.hmtabs_arrows .row .col-auto.col-auto_right {
    right: 0;
}

.banner-para {
    font-size: 14px;
}

.hm_heading h1 {
    font-size: 18px;
    line-height: 30px;
}

.hm_heading h1 span {
    font-size: 36px;
    line-height: 46px;
    max-width: 60%;
}

.hm_heading h2 {
    font-size: 14px;
    line-height: 24px;
    max-width: 51%;
}

.hm_heading.hm_heading_big h3.new_style {
    font-size: 18px;
    line-height: 30px;
}

.hm_heading.hm_heading_big h3.new_style span {
    font-size: 36px;
    line-height: 46px;
    margin-top: 8px;
}

.hm_heading h5 {
    font-size: 18px;
    line-height: 30px;
}

.hm_heading.hm_heading_big h3 {
    font-size: 36px;
    line-height: 46px;
}

.sec6new .hm_heading.hm_heading_blue h3 {
    margin-bottom: 16px;
}

.sec6new .hm_heading p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 34px;
}

.heading {
    font-size: 18px;
    line-height: 30px;
}

.subheading {
    font-size: 36px;
    line-height: 46px;
}

.hm_3boxes .point_box h5 {
    font-size: 16px;
}

span.banner-subheading.home_banner_subheading {
     font-size: 14px;line-height: 26px;
}

section.banner.banner_renew_posprobg span.banner-subheading {
    font-size: 14px;
    line-height: 26px;
}

section.banner.banner_renew_posprobg .banner-para {
    max-width: 66%;
    line-height: 24px;
}

section.sec-3.sec3_home.sec3_pospro .heading {
    font-size: 18px; line-height: 30px;
}

section.sec-4.sec4_pospro .heading {
    font-size: 18px;
}

section.sec-4.sec4_pospro .subheading {
    line-height: 46px;
}

.sec-5 .heading {
    font-size: 18px;
}

.sec-6 .heading {
    font-size: 18px;
}


.sec-6 .subheading {
    font-size: 36px;
    line-height: 46px;
}

.sec-4 .tab-content h5 {
    font-size: 26px;
}


section.banner.banner_renew_posprobg .banner-heading {
    font-size: 42px;
    line-height: 49px;
}

.footer-bar-up .fotr_barup_infos  h4 {
    font-size: 24px;
    line-height: 35px;
    max-width: 80%;
}

.footer-bar-up .subheading {
    font-size: 34px;
    line-height: 45px;
    max-width: 80%;
}


}

@media (max-width: 1340px) {

    .busi_loan_form form .form_click {
    padding-right: 55px;
}

/* .dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos {
    height: 670px;
} */

}


@media (max-width: 1299px) {
	.banner-heading {
		font-size: 36px;
		line-height: 125%;
		margin: 28px 0;
	}
	.footer-bar .foot {
		display: flex;
		justify-content: center;
		gap: 50px;
	}
	.banner-heading span {
		display: initial;
	}
	.banner-para {
		margin-bottom: 40px;
	}
	.subheading {
    font-size: 32px;
    line-height: 125%;
}
	.banner-image {
		margin-top: 70px;
	}
	.sec-3 .nav.nav-pills {
		margin: 50px 0;
	}
.sec-3 .nav.nav-pills .nav-item a {
    padding: 14px 22px;
}
	.footer-bar-up h4 {
		font-size: 32px;
		line-height: 125%;
	}

	.sec-3 .nav.nav-pills {
    gap: 8px;
}

.hmtabs_arrows .row .col-auto.col-auto_left {
    position: relative;
    left: -27px;
}

.hmtabs_arrows .row .col-auto.col-auto_right {
    right: -27px;
}

.selected-image img {
    width: 100%;
}

.hmtabs_arrows {
    display: none;
}

.hmtabs_mainbox {
    max-width: 100%;
}



}

@media (max-width: 1245px) {

	/* .dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos.dotpe_left2_mode_infos {
    height: 570px;
} */

}

@media (max-width: 1215px) {

.dotpe_left_infos h3 {
    font-size: 24px;
}

.dotpe_left_infos h4 {
    font-size: 24px;
    line-height: 36px;
}

/* .dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos {
    height: 630px;
} */

.form_heading p {
    font-size: 18px;
}

.form_heading h3 {
    font-size: 29px;
}

/* .dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos.dotpe_left2_mode_infos {
    height: 538px;
} */

}

@media (max-width: 1199px) {
	.sec-3 .nav.nav-pills .nav-item a {
	  padding: 14px 34px;
	}
	.sec-4 .nav-pills .nav-link{
		padding: 14px 34px;		
	}
	.sec-6 .test-2 img {
		height: 332px;
		object-fit: cover;
		width: 250px !important;
		position: relative;
		bottom: -34px;
	}

	
	.hm_heading.hm_heading_big h3 {
    font-size: 36px;
    line-height: 46px;
}

.hm_srvc_infos h4 {
    font-size: 17px;
    line-height: 25px;
    margin: 0 0 18px 0;
}

.sec6new .hm_heading p {
    font-size: 22px;
    line-height: 34px;
    max-width: 70%;
}

.hm_heading.hm_heading_big h3 {
    font-size: 32px;
    line-height: 36px;
}

.hm_heading.hm_heading_big h3.new_style span {
    font-size: 32px;
    line-height: 38px;
}

.sec6_heading {
    font-size: 21px;
    line-height: 32px;
}

.sec-3 .nav.nav-pills .nav-item a {
    padding: 11px 24px;
    font-size: 15px;
}

.sec_space {
    padding: 60px 0;
}

.sec-5 {
    padding: 60px 0 62px;
}

.sec4newa_grid.do_delivr_grid .hm_whycards .hm_why_infos h4 {
    font-size: 20px;
    line-height: 28px;
}


.sec4newa_grid.do_delivr_grid .hm_whycards .hm_why_infos ul li {
    font-size: 15px;
    line-height: 22px;
}

.dordr_deliversec .hm_heading.hm_heading_big p {
    max-width: 96%;
}

.sec4newa_grid.do_delivr_grid .hm_whycards {
    padding: 20px;
    margin-top: 10px;
}

.sec4newa_grid.do_delivr_grid .hm_whycards .hm_why_infos h4 {
    font-size: 22px;
    line-height: 32px;
}

.dordr_adjuestsec .hm_heading.hm_heading_big h3 {
    max-width: 60%;
}

.sec4newa_grid.do_delivr_grid .hm_whycards .hm_why_infos h4 {
    font-size: 20px;
    line-height: 28px;
}

.sec4newa_grid.do_delivr_grid .hm_whycards {
    padding: 20px;
    margin-top: 10px;
}

.sec4newa_grid.do_delivr_grid .hm_whycards .hm_why_infos ul li {
    font-size: 15px;
    line-height: 22px;
}

.dordr_adjust_list {
    margin-bottom: 20px;
    padding-bottom: 20px;
    padding-right: 10px;
}

.dordr_adjust_list h4 {
    font-size: 20px;
}

.footer-bar-up {
    padding: 80px 0;
}

section.sec-8.faq_btm .subheading {
    margin: 0 0 30px 0;
}

.form_heading h3 {
    font-size: 26px;
}

.form_heading p {
    font-size: 16px;
    line-height: 24px;
}

.dotpe_popouter .busi_loan_form form {
    padding: 30px;
}

/* .dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos {
    height: 640px;
} */

.dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos {
    padding-left: 30px;
}

.dotpe_left_infos h3 {
    font-size: 22px;
    padding-right: 25px;
    line-height: 34px;
}

.dotpe_left_infos h4 {
    font-size: 22px;
    line-height: 33px;
}

.dotpe_poplogo img {
    width: 128px;
}

/* .dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos.dotpe_left2_mode_infos {
    height: 530px;
} */

.point_acordimg img {
    width: 100%;
}

.inverntry_m_review .hm_heading h3 {
    max-width: 340px;
    margin-bottom: 0;
}

.whatsapp_box h4 {
    font-size: 16px;
    line-height: 25px;
}

.whatsapp_box.whatsapp_box2:before {
    right: 0;
}

section.sec_space.whats_connect .hm_heading h3 {
    max-width: 85%;
    font-size: 29px;
    line-height: 37px;
}

.point_img span img {
    width: 100%;
}

.banner-image.hmbanner_img img.ban-last-img {
    border-radius: 8px;
    width: 100%;
}

.footer-bar-up .heading {
    font-size: 19px;
}

.footer-bar-up h4 {
    font-size: 29px;
    line-height: 125%;
}

section.banner.banner_renew_posprobg .banner-image img {
    width: 100%;
}

section.banner.banner_renew_posprobg .banner-image {
    bottom: 18%;
}

section.banner.banner_renew_posprobg .banner-para {
    max-width: 100%;
    line-height: 20px;
    margin-right: 15px;
}

section.banner.banner_renew_posprobg .banner-heading {
    font-size: 24px;
    line-height: 36px;
    max-width: 100%;
    padding-right: 18px;
}
.subheading {
    font-size: 29px;
}

section.sec-4.sec4_pospro .subheading {
    line-height: 49px;
}

.tabs_btns button {
    font-size: 18px;
}

.tabs_btns button.tab_right_single {
    margin-right: auto;
    width: 175px;
}

.sec-6 .subheading {
    font-size: 32px;
    line-height: 46px;
}

.sec-8 .accordion_rista .card .card-header .btn-header-link {
    font-size: 20px;
    padding: 16px 3px;
}

.accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link.collapsed span.plusminus:before {
     width: 22px !important;
}

.accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link  span.plusminus:after {
     width: 26px !important;
}

.sec-8 .card-body p, .card-body li {
    font-size: 14px;
    font-weight: 600;
}

.accordion_rista.accordion_rista2 .card-body {
    padding-left: 2px;
}

.banner {
    padding: 47px 0 65px 0;
}

.sec-4 {
    padding: 45px 0 45px;
    margin-top: 30px;
}

/* section.banner.banner_renew_posprobg .container {
    padding: 0 0 0 75px;
} */

.aboutcards .about_infos p {
    max-width: 100%;
}

.aboutcards .about_infos h4 {
    font-size: 18px;
}

.aboutcards .aboutcard_icon img {
    width: 51px;
}

.aboutcards .aboutcard_icon {
    min-height: 100px;
}

.about_head h2 {
    font-size: 36px;
    line-height: 45px;
}

.aboutcards .aboutcard_icon.aboutcard_icon3 img {
    width: 41px;
}

.aboutcards .aboutcard_icon.aboutcard_icon4 img {
    width: 40px;
}

.about4infos p {
    font-size: 14px;
    line-height: 22px;
    margin: 0 0 12px 0;
}

.about4infos h3 {
    font-size: 36px;
    line-height: 46px;
}

.about4infos {
    max-width: 620px;
    padding-right: 170px;
}

.aboutpg_images .about_pgimgone.about_pgimgtwo img {
    width: 320px;
}

.aboutpg_images .about_pgimgone img {
    width: 430px;
}

.aboutpg_images {
    top: 150px;
}

.about4main {
    padding: 0 0px 290px 0;
}

.footer-bar-low ul {
    gap: 32px;
}

.footer-bar-low .col-lg-5 {
    flex: 0 0 42%;
    max-width: 42%;
}

.footer-bar-low .col-lg-7 {
    flex: 0 0 58%;
    max-width: 58%;
}

.logo_toggle img {
    width: 98px;
}

.header .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 14px;
    padding-left: 14px;
    font-size: 14px;
    line-height: 20px;
}

.header.headermain .right_menu .nav-item a.nav-link {
    padding: 10px 20px;

}

section.sec-3.sec3_home.visible-section .rista_tabs.new-class {
    top: 93px;
}

.sec-8 .accordion_rista .card .card-header .btn-header-link::after {
    top: 0px !important;
}

  }

  @media (max-width: 1120px) {
    /* .dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos {
    height: 660px;
} */
  }

  @media (max-width: 1034px) {
	.form_heading h3 {
    font-size: 24px;
}

/* .dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos.dotpe_left2_mode_infos {
    height: 528px;
} */

.dotpe_left_infos h4 {
    font-size: 20px;
    line-height: 31px;
}

.dotpe_popcheck img {
    width: 98px;
}

/* .dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos.dotpe_left2_mode_infos {
    height: 504px;
} */

.banner_noimage .banner-image.hmbanner_img {
    padding: 0 40px 0 110px;
}

.banner_noimage .banner-image.hmbanner_img span.hmbanner_img2 {
    height: 30vh;
    left: 0;
    bottom: 80px;
}

/* section.banner.banner_renew_posprobg .container {
    padding: 0 0 0 65px;
} */

.sec-6 .owl-nav {
	display: none;
}

.sec-6 .owl-nav {
	width: 100%;
	float: left;
	position: absolute;
	right: 0;
}

.sec-6 .owl-prev {
	position: absolute;
	left: inherit;
	top: inherit;
	right: 62px;
}

.sec-6 .owl-next {
	position: absolute;
	right: inherit;
	top: inherit;
}

.sec-6 .owl-dots {
	position: absolute;
	left: 0;
	right: inherit;
	bottom: -50px;
}

.aboutcards {
    padding: 0 0 30px 0;
}


  }

  @media (max-width: 1024px) {
.header.headermain_scroll  .navbar-expand-lg .navbar-nav a.nav-link.active {
    color: #fff !important;
    font-weight: 600;
}

  }

  @media (max-width: 991px) {
	.sec-7 .sec-7-block {
		display: flex;
		justify-content: center;
		text-align: center;
		gap: 0px;
		flex-wrap: wrap;
	}
	.header .navbar-light .navbar-toggler-icon {
    width: 32px;
    height: 19px;
}
	.navbar-light .navbar-toggler {
		color: #000;
		border-color: rgba(0,0,0,.1);
		border: 0;
	}
	.header .navbar {
		justify-content: end;
	}
	.footer-bar-low ul {
		justify-content: center;
	}
	.footer-bar-low ul .foot-logo {
		margin-bottom: 20px;
	}
	.footer-bar .foot {
		display: flex;
		justify-content: center;
		gap: 50px;
		flex-wrap: wrap;
	}
	.footer-bar .foot .foot-1 {
		flex: 0 0 45%;
		max-width: 50%;
	}
	.footer-bar-up .heading {
		padding-bottom: 46px;
		border-bottom: 1px dashed #383838;
		margin-bottom: 52px;
	}
	.footer-bar-up .subheading {
		margin-right: 0;
		margin-bottom: 36px;
	}
	.footer-bar-up {
		padding: 45px 0 57px;
	}
	.footer-bar-up .right-side {
		padding: 0;
	}
	.sec-7 .sec-1-block {
		flex: 0 0 45%;
		max-width: 50%;
		border: 1px solid #ECECEC1A;
		padding: 24px 20px;
		text-align: left;
	}
	.sec-7 .sec-7-block h3 {
		text-align: left;
		margin-top: 18px;
		margin-bottom: 0;
		font-size: 14px;
		font-weight: 600;
	}
	.sec-1-block.sec-7-img-ch h3, .sec-1-block.sec-7-img-ch {
		text-align: center;
	}
	.sec-7 {
		padding: 44px 0;
	}
	.sec-7 .sec-7-block p {
		text-align: left;
		display: none;
	}
	.sec-7 .sec-7-block .sec-7-img img {
		height: 18px;
		width: auto;
	}
	.sec-7 .sec-1-block.sec-7-img-ch {
		flex: 0 0 90%;
		max-width: 100%;
	}
	.sec-3 .nav.nav-pills .nav-item a {
	  padding: 14px 34px;
	}
	.sec-9 .owl-dots {
		display: none;
	}
	.sec-4 .nav-pills .nav-link{
		padding: 14px 34px;		
	}
	.sec-6 .test-2 img {
		height: 332px;
		object-fit: cover;
		width: 250px !important;
		position: relative;
		bottom: -34px;
	}
	.sec-3 .term {
		display: flex;
		gap: 30px;
		justify-content: center;
		width: 100%;
		float: left;
	}
	.sec-4 .block-1 {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.sec-4 .block-2 {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.sec-4 .block {
		flex-wrap: wrap;
	}

section.banner.banner_home {
    padding: 52px 0 72px 0px;
}

section.banner.banner_home .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
}

section.banner.banner_home .banner-content {
    text-align: center;
}

section.banner.banner_home .banner-content span.banner-subheading.home_banner_subheading {
    text-align: center;
    max-width: 100%;
}

section.banner.banner_home .banner-heading {
    max-width: 100%;
    text-align: center;
}

section.banner.banner_home .banner-para {
    margin: 0 auto;
    text-align: center;
    padding: 30px 0 40px 0;
    max-width: 80%;
}

section.banner.banner_home .ban-bt {
    text-align: center;
    float: none;
}


section.banner.banner_home .ban-bt a {
    float: none;
    display: inline-block;
}

.banner-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 40px 0 0 0;
    width: 100%;
}
    
.banner-mobile .ban-last {
    float: none;
    width: auto;
    margin: 0 10px;
}

.banner_contt_mob{ display: block;}

.hm_banner_desk{ display: none;}

section.banner.banner_home .banner-content {
    text-align: center;
    padding: 30px 0 0 0;
}

.sec-2 {
    margin-bottom: 44px;
}

.hm_heading h3 {
    max-width: 80%;
}


.hm_heading p {
    max-width: 100%;
    margin: 0 0 10px 0;
}

.hm_heading h2 {
    max-width: 100%;
    margin: 0 0 10px 0;
}


.sec5newa_grid .col-md-3 {
    flex: 0 0 50%;
    max-width: 50%;
    border-bottom: solid 1px rgba(234, 234, 234,0.10);
}
/* .sec5newa_grid .col-md-2:last-child {
    flex: 0 0 100%;
    max-width: 100%;
} */

.sec5newa_grid .row {
    border: solid 1px rgba(234, 234, 234,0.10);
}

.sec5newa_grid .col-md-3:nth-child(even) {
    border-left: solid 1px rgba(234, 234, 234,0.10);
}

.sec5newa_grid .hm_srvccards {
    text-align: left;
    padding: 20px 0 0 0;
}

.hm_srvccards .hm_srvccard_icon span {
    justify-content: flex-start;
}

.sec6new .hm_heading p {
    max-width: 100%;
}

.sec6_perfect_grid .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
}

.sec6new .hm_heading p {
    max-width: 100%;
}

.sec6_perfect_grid .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
}

.sec6_perfect_grid .sec6_heading {
    margin: 0 0 20px 0;
}

.sec6_perfect_grid .sec6_perfect_points {
    margin: 0 0 30px 0px;
}

.sec6_perfect_grid {
    border: none;
}

section.sec-3.sec3_home .point_btns {
    display: none;
}


/* .sec4newa_grid{ display: none;} */

.fasttrack_accordians{ display: block;}

section.footer-bar-up {
    padding: 52px 0;
}

section.sec-8.faq_btm .col-lg-7 {
    flex: 0 0 100%;
    max-width: 80%;
}

section.sec3new_upsec.sec_space .sec-6.deordr_owl_slides .owl-slider {
    max-width: 100%;
}

.hm_heading.hm_heading_big h3 {
    max-width: 75%;
}

.hm_heading.hm_heading_big h3.new_style {
  max-width: 75%;
}

.dordr_whysec_pics span.left_img {
    margin-top: 30px;
}

.hm_heading .dordr_whysec_infos p {
    max-width: 100%;
    margin: 0 auto;
}

.sec4newa_grid.do_delivr_grid {
    display: block;
    padding: 0 10px;
}

.sec4newa_grid.do_delivr_grid .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
}

.dordr_adjuestsec .hm_heading.hm_heading_big h3 {
    max-width: 90%;
}

.sec5new.dordr_adjuestsec .hm_heading p {
    max-width: 100%;
    margin: 0 auto;
}

.hm_heading h3 {
    font-size: 29px;
    line-height: 34px;
}

.logo_toggle {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.header.headermain.headermain_scroll .logo_toggle .navbar-toggler-icon {
    filter: brightness(0) invert(1);
}

.header .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0;
}

.header .navbar-expand-lg .navbar-nav {
    margin-top: 17px;
}

.header.headermain .right_menu .nav-item a.nav-link {
    text-align: center;
}

/* .dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos {
    height: 597px;
}

.dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos.dotpe_left2_mode_infos {
    height: 528px;
} */

.inverntry_m_review .sec-6 .sec-2-item {
    gap: 0;
    flex-direction: column;
}

.take_controlamin .row .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
}

.take_controlamin .row .col-md-6 {flex: 0 0 100%;max-width: 100%;}

.take_con_left {
    height: auto;
    padding: 0 0 50px 0;
    align-items: center;
}

.take_control_sec .hm_heading h3 {
    max-width: 100%;
    margin: 0 0 40px 0;
    text-align: center;
}

.takecon_img {
    text-align: center;
}

.takecon_img span {
    width: 100%;
}

.takecon_infos h4 {
    text-align: center;
}

.know_box h4 {
    font-size: 17px;
    max-width: 80%;
    min-height: 60px;
    line-height: 26px;
}

.know_box p {
    max-width: 100%;
}

.hm_heading h3 {
    font-size: 30px;
    line-height: 40px;
    max-width: 100%;
}

.hm_heading h1 {
    font-size: 21px;
    line-height: 30px;
}

.hm_heading h1 span{
    font-size: 30px;
    line-height: 40px;
    max-width: 100%;
}



.inven_choose_rista ul li {
    padding: 0 0 0 35px;
    margin: 20px 0 10px 0;
}

.sec-3 .nav.nav-pills {
    gap: 0;
}

.point_row_grid .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
}

.knowyour_grid .col-md-3 {
    flex: 0 0 50%;
    max-width: 50%;
}

.know_box h4 {
    font-size: 17px;
    max-width: 100%;
    min-height: inherit;
    line-height: 26px;
}

.whatstabimg_sec .hm_heading.hm_heading_big h3 {
    max-width: 100%;
    padding: 0 0 40px 0;
}

.whats_customr .sec-6 {
    padding: 40px 0 25px 0;
}

.accordion_rista .card .card-header .btn-header-link {
    font-size: 18px;  line-height: 32px;
}

.accordion_rista .card .card-header .btn-header-link {
    font-size: 16px;
    line-height: 24px;
}

.whatsapp_box.whatsapp_box2:after {
    left: -10px;
    width: 1px;
    height: 100%;
}

.whatsapp_box.whatsapp_box2:before {
    right: -10px;
    height: 100%;
}

section.sec_space.whats_connect .hm_heading h3 {
    max-width: 100%;
    font-size: 24px;
    line-height: 32px;
}

.hm_heading h5 {
    font-size: 21px;
    line-height: 30px;
}

.whatsapp_box {
    margin: 40px 0 40px 0;
}

section.sec-2 {
    padding-top: 0px;
    margin-bottom: 60px;
}

.fasttrack_accordians .fast_img_shape.fast_img_shape2 {
    height: 40px;
    position: relative;
    top: -18px;
}

.fasttrack_accordians .fast_img_shape {
    min-width: 60px;
}

.sec-3 .nav.nav-pills .nav-item a {
    padding: 5px 12px;
    font-size: 14px;
    border-radius: 7px;
    line-height: 22px;
    min-height: 55px;
    display: flex;
    align-items: center;
}

	.sec-3 .nav.nav-pills {
    display: flex;
    gap: 9px;
    justify-content: start;
    margin: 42px 25px;
    overflow-x: scroll;
    flex-wrap: inherit;
}
	.sec-3 .nav.nav-pills li {
		margin-bottom: 10px;
	}

    section.sec-3.sec3_home.visible-section .rista_tabs.new-class {
        padding: 21px 0;
    }

  .accordion.accordion_rista.accordion_rista2.accordion_rista3.accord_plusminus .btn.btn-header-link {
    padding-right: 75px;
}
section.sec-3.sec3_home.visible-section .rista_tabs.new-class {
    top: 84px;
}

section.sec-3.sec3_home .rista_tabs {
    padding: 0;
    transition: all 0.5s ease;
}

p.tab_lastline a.hm_fillbtn {
    display: block;
    margin-top: 20px;
    max-width: 150px;
    margin-left: 0;
}

.accordion_rista .card .card-header .btn-header-link {
    font-size: 14px;
    line-height: 26px;
}

p.tab_lastline {
    margin-top: 20px;
    font-size: 14px;
}

.hm_3boxes .point_box h3 {
    font-size: 26px;
    line-height: 34px;
    padding: 0 30px 0 30px;
    max-width: 84%;
}

.hm_3boxes .point_box h5 {
    font-size: 16px;
    line-height: 26px;
}

.banner-image.hmbanner_img {
    max-width: 390px;
    margin: 0 auto;
}

.sec-2 p.para small {
    font-size: 24px;
    font-weight: 700;
}

p.para.hmpara_btm {
    margin-top: 30px;
}

.sec-2 p {
    font-size: 24px;
    font-weight: 700;
}

.hm_3boxes {
    margin-top: 52px;
}


section.sec-3.sec3_home  .point_btns.point_btns_mob { display: block;
}

section.sec-3.sec3_home.sec3_home_tabs {
    background: none;
    padding: 0;
}

section.sec-3.sec3_home.sec3_home_tabs .point_box {
    border: none;
}


section.sec-3.sec3_home.sec3_home_tabs .point_block_main {
    padding: 30px;
}

.mobfuel{ display: block;}

.deskfuel{ display: none;}

.sec3_home_tabs .point_row_grid  .point_img {
    text-align: center;
}

.hm_srvccards .hm_srvc_infos p {
    display: none;
}

.sec5new_bottom {
    display: none;
}

/* section.banner.banner_renew_posprobg .container {
    padding: 0 25px;
} */

section.banner.banner_renew_posprobg .banner-mobile {
    align-items: flex-start;
    justify-content: flex-start;
}

.auxilary_head h2 {
    font-size: 29px;
    line-height: 38px;
    font-weight: 700;
}

.auxilary_head p {
    margin: 20px auto 30px auto;
}

.aboutpg_images.aboutpg_images_desk {
    display: none;
}

.aboutpg_images.aboutpg_images_mobile {
    display: block;
    position: relative;
    top: 0;
    min-height: 460px;
}
.about4infos {
    max-width: 100%;
    padding: 0;
}

.about4infos h3 {
    font-size: 28px;
    line-height: 125%;
    text-align: center;
    max-width: 60%;
    margin: 0 auto 10px auto;
}

.aboutpg_images .about_pgimgone img {
    width: 266px;
    margin: 0 auto 0 160px;
    border-radius: 42px 0 0 42px;
}

.about_pgimgone {
    text-align: center;
}

.about_pgimgone.about_pgimgtwo {
    left: 90px;
    bottom: 0;
    z-index: 5;
}

.aboutpg_images .about_pgimgone.about_pgimgtwo img {
    width: 260px;
    margin: 0;
}

.aboutpg_images .about_pgimgone {
    position: relative;
    z-index: 1;
    right: 0;
}

.aboutpg_images .about_pgimgone.about_pgimgtwo {
    position: absolute;
    left: 10px;
    bottom: 70px;
}

.about4main {
    padding: 0 0px 40px 0;
}

.about_head.about_head_last p {
    font-size: 15px;
    line-height: 24px;
    margin: 0 auto 50px auto;
    max-width: 67%;
}

.about_head.about_head_last h2 {
    line-height: 42px;
    max-width: 100%;
    margin-bottom: 20px;
}

.busi_loan_form form .form_click {
    padding-right: 0;
}

/* .dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos {
    height: 638px;
} */

.footer-bar-low .col-lg-5 {
    flex: 0 0 100%;
    max-width: 100%;
}

.footer-bar-low .col-lg-7 {
    flex: 0 0 100%;
    max-width: 100%;
}

.copyright_line {
    text-align: center;
    padding: 0 0 30px 0;
}

.footer-bar-low .col-lg-5 {
    display: none;
}


.copyright_line.copyright_line_mobile {
    display: block;
    padding-top: 20px;
    background: #121212;
    border-top: 1px solid #383838;
    float: left;
    width: 100%;
    padding-bottom: 20px;
}


section.footer-bar {
    border-bottom: none;
    padding-bottom: 20px;
}

.footerlogo_mobile .footerlogo {
    display: block;
    height: auto;
    margin: 20px 0 0 0;
}

.footer-bar-low ul li.termslink {
    flex: 0 0 50%;
    max-width: 50%;
    text-align: right;
    padding: 0 18px;
}
.footer-bar-low ul {
    display: flex;
    gap: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}

.footer-bar-low ul li.termslink {
    flex: 0 0 50%;
    max-width: 50%;
    text-align: right;
    padding: 0 18px;
}

.footer-bar-low ul li.terms_mobile{ display: none;}

.footer-bar-low ul li.termslink.termslink2{
    text-align: left;
}

.footer-bar-low ul li.footerlogo_mobile {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
}

.footer-bar-low ul li.social-link {
    text-align: center;
    flex: 0 0 100%;
    max-width: 100%;
    gap: 30px;
    width: 100%;
    display: block;
}

.footer-bar-low ul li.social-link a {
    text-align: center;
    display: inline-flex;
    padding: 0 16px;
}

.footer-bar-low ul li.social-link {
    margin: 30px 0 17px 0;
}

.contact_infos {
    margin-top: 11px;
}

.footer_linkmain .col-md-6 {
    display: none;
}

.footer_linkmain .col-md-3 {
    flex: 0 0 50%;
    max-width: 50%;
}




.header .navbar-light .navbar-toggler-icon {
    position: relative;
    z-index: 1;
   
    
}

.header .navbar-light .navbar-toggler-icon.collapsed {
    position: relative;
    z-index: 1;
     
    background-image: none !important;
    filter: inherit !important;
}

.header .navbar-light .navbar-toggler.toggless {
    position: relative;
    z-index: 1;
    padding-right: 0;
}


.header .navbar-light .navbar-toggler.toggless .navbar-toggler-icon {

    background-image: none !important;
    filter: inherit !important;
}



/* .header .navbar-light .navbar-toggler-icon.collapsed:after, .header .navbar-light .navbar-toggler-icon.collapsed:before{ display: none;} */


.header .navbar-light  .navbar-toggler.toggless .navbar-toggler-icon:after {
    position: absolute;
    left: 0;
    right: 0;
    width: 25px;
    height: 2px;
    content: "";
    transform: rotate(45deg);
    background: #000;
    top: 5px;
    transition: all 0.5s ease;
}

.header .navbar-light   .navbar-toggler.toggless  .navbar-toggler-icon:before {
    position: absolute;
    left: 0;
    right: 0;
    width: 25px;
    height: 2px;
    content: "";
    transform: rotate(-45deg);
    background: #000;top: 5px;
    transition: all 0.5s ease;
}

.header.headermain_scroll .navbar-light  .navbar-toggler.toggless .navbar-toggler-icon:after, .header.headermain_scroll .navbar-light  .navbar-toggler.toggless .navbar-toggler-icon:before,.header.headerdark .navbar-light  .navbar-toggler.toggless .navbar-toggler-icon:after, .header.headerdark .navbar-light  .navbar-toggler.toggless .navbar-toggler-icon:before {
    background: #fff;
}

li.termslink {
    display: block;
}

.header.headermain.headermain_scroll a.navbar-brand img.logo_onscroll {
    width: 98px;
}

.footer-bar-up .fotr_barup_infos  h4 {
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0;
}

.footer-bar-up .fotr_barup_infos .subheading {
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 30px;
    max-width: 80%;
    letter-spacing: 0.5px;
}

li.nav-item.mobilenavlink {
    display: block;
}

.header .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 14px;
    padding-left: 14px;
    font-size: 16px;
    line-height: 26px;
}

li.nav-item.mobilenavlink {
    display: block;
}

.header ul.navbar-nav {
    border-top: solid 1px #E6E6E6;
}
.header.headermain.headermain_scroll ul.navbar-nav {
    border-top: solid 1px rgba(236, 236, 236,0.22);
}

.header .container {
    max-width: 100%;
    padding: 0;
}

.header .container nav.navbar .logo_toggle {
    padding: 0 15px;
}

.header .container nav.navbar ul.navbar-nav {
    padding: 0 0px 0 20px;
}

.header .container nav.navbar ul.navbar-nav li{
position: relative;
z-index: 1;
}

.header .container nav.navbar ul.navbar-nav li.nav-item a.nav-link.active {
    color: #fff !important;
}



.header .container nav.navbar ul.navbar-nav li.nav-item {border-bottom: solid 1px #e6e6e6;}
.header.headermain.headermain_scroll .container nav.navbar ul.navbar-nav li.nav-item {border-bottom: solid 1px rgba(236, 236, 236,0.22);}


.header.headermain .container nav.navbar ul.navbar-nav li.nav-item:last-child {
    border: none;
}

.header.headermain.headermain_scroll ul.navbar-nav.right_menu {
    border: none;
    padding-right: 20px;
    display: none;
}

.header .container nav.navbar ul.navbar-nav li.nav-item:after {

     position: absolute;
    right: 27px;
    top: 28px;
    bottom: 0;
    width: 8px;
    height: 8px;
    content: '';
    border-style: solid;
    border-color: transparent 
#324157 
#324157 transparent;
    border-width: 0px 2px 2px 0px;
    z-index: 1;
    transform: rotate(-45deg);
}

.header.headermain_scroll .container nav.navbar ul.navbar-nav li.nav-item:after { border-color: transparent 
#fff 
#fff transparent;}


ul.navbar-nav.ml-auto.right_menu {
    display: none;
}

.header.headermain.headermain_scroll.toggled {
    padding-bottom: 0;
    border-bottom: none;
}

.header.headermain.toggled {
    border-bottom: none;
    padding-bottom: 0;
}

.header.headermain.headerdark.toggled .container nav.navbar ul.navbar-nav li.nav-item:after { border-color: transparent 
#fff 
#fff transparent;

  }

  .header.headermain.headerdark .container nav.navbar ul.navbar-nav li.nav-item {border-bottom: solid 1px rgba(236, 236, 236,0.22);

}

.header.headermain.headerdark ul.navbar-nav {
    border-top: solid 1px rgba(236, 236, 236,0.22);
}

.sec3_home_tabs .point_block_main {
    margin: 0;
}

.sec3_home_tabs .point_row_grid .point_img span {
    height: 250px;
}

.sec3_pospro .term .point_powerimg {
    height: auto;
}

.sec-4 .block-1.blockone_main .block-3 p {
    min-height: inherit;
}

section.sec-8.faq_btm.faq_btm_pros .col-lg-8 {
    flex: 0 0 100%;
    max-width: 100%;
}

section.sec-8.faq_btm.set_faq_section .col-lg-7 {
    flex: 0 0 100%;
    max-width: 100%;
}

.banner_home_new .banner-image.hmbanner_img span {
    height: 470px;
}

.banner_home_new .banner-image.hmbanner_img span.hmbanner_img2 {
    height: 206px;
}

.footer_centerterms a {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #fff;
    margin: 0 10px;
}

.footer_centerterms {
    text-align: center;
    display: block;
}

li.termslink.termslink_mobterm {
    display: none;
}

.footerlogo_mobile .contact_infos {
    display: block;
}

.header .container nav.navbar ul.navbar-nav li.nav-item a.nav-link.active {
    color: #000 !important;
}

.header.headermain_scroll  .container nav.navbar ul.navbar-nav li.nav-item a.nav-link.active {
    color: #fff !important;
}



}


  @media (max-width: 975px) {

.about_head h2 {
    font-size: 32px;
    line-height: 44px;
    max-width: 60%;
}

.aboutcards .about_infos p {
    font-size: 14px;
    line-height: 20px;
}

.aboutcards .about_infos h4 {
    font-size: 17px;
}
/* 
.dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos {
    height: 660px;
} */


  }

   @media (max-width: 960px) {
	   
	   .form_heading h3 {
		   font-size: 22px;
		   }
		   }
		   
		   
@media (max-width: 940px) {

   /* .dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos {
    height: 660px;
}

.dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos.dotpe_left2_mode_infos {
    height: 523px;
} */



}




     @media (max-width: 925px) {

		.dotpe_popcheck img {
    width: 110px;
}

/* .dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos.dotpe_left2_mode_infos {
    height: 522px;
} */

.dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos.dotpe_left2_mode_infos {
    padding-left: 20px;
}

	 }

	 @media (max-width: 900px) {

	 .form_heading h3 {
    font-size: 20px;
}

/* .dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos.dotpe_left2_mode_infos {
    height: 518px;
} */

	 }

	 @media (max-width: 880px) {

/* .dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos {
    height: 660px;
} */

	 }

	 @media (max-width: 860px) {

	 .dotpe_left_infos h4 {
    font-size: 18px;
    line-height: 28px;
}

	 }


      @media (max-width: 855px) {
/* .dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos {
    height: 682px;
} */

      }


	 @media (max-width: 825px) {

		
.ReactModalPortal .ReactModal__Content {
    margin-right: -37% !important;
}

/* .dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos {
    height: 639px;
}

.dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos.dotpe_left2_mode_infos {
    height: 495px;
} */

	 }


  @media (max-width: 820px) {

	.dotpe_left_infos h3 {
    font-size: 20px;
    padding-right: 20px;
    line-height: 28px;
}


.dotpe_left_infos h4 {
    font-size: 21px;
    line-height: 29px;
}

/* .dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos {
    height: 638px;
} */


  }

  @media (max-width: 800px) {



  }

  @media (max-width: 767px) {
    .topheader-inner {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .topheader-inner-a {
        flex: 0 0 100%;
        max-width: 100%;
        display: flex;
        justify-content: end;
    }
    .topheader-inner-a a {
        padding: 0px 7px;
        font-size: 12px;
        font-weight: 500;
        max-width: 92px;
    }
    .topheader-1 {
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 12px;
        flex-wrap: wrap;
    }
    .topheader-inner p {
        font-family: Manrope;
        font-size: 11px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.4000000059604645px;
        text-align: left;
    }
	.banner .row {
		flex-direction: column-reverse;
	}
    .topheader {
        padding: 8.5px 0;
    }
	.banner.banner_home .row {
		flex-direction: column;
	}
    .pos_playgrid_right-aliyn .pos_plygridinfos h3 {
        max-width: 90%;
    }
	
	.banner-image {
		margin-top: 0;
		margin-bottom: 28px;
	}
	.banner-image img{
		width: 100%;
	}
.banner-heading {
    font-size: 40px;
    line-height: 45px;
    margin: 20px 0;
}
	.banner-para {
		font-family: "Manrope", sans-serif;
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		text-align: left;
		color: #6D6D6D;
		margin-bottom: 32px;
	}
	.ban-bt a {
		width: 100%;
	}
	.ban-last {
		display: flex;
		align-items: center;
		flex-direction: column;
		flex: 0 0 50%;
		max-width: 50%;
		margin: 0;
		font-size: 12px;
	}
	.ban-last span {
    font-size: 8px;
    line-height: 20px;
}
	.ban-last-1 {
		display: flex;
		align-items: center;
		flex-direction: column;
		flex: 0 0 50%;
		max-width: 50%;
		font-size: 12px;
	}
	.ban-last-1 span{
		font-size: 8px;
	}
	.banner-mobile {
		display: flex;
		width: 100%;
		text-align: center;
		gap: 12px;
		justify-content: center;
		margin: 36px 0 0 0;
		float: left;
	}
	.banner-mobile img {
		margin-bottom: 8px;
	}
	.sec-2 .container-fluids {
		display: flex;
		flex-direction: column-reverse;
	}
	.sec-2 .container-fluids {
		display: flex;
		flex-direction: column-reverse;
		gap: 34px;
	}
	.sec-2 p {
		font-family: "Manrope", sans-serif;
		font-size: 16px;
		font-weight: 700;
		line-height: 23px;
		text-align: center;
		margin: 0;
	}
	.banner-subheading {
		font-size: 14px;
		padding: 10px;
	}
	.banner {
		padding: 24px 0 42px 0;
	}
	.sec-2 {
		margin-bottom: 49px;
	}
	.sec-2 .owl-dots {
		display: none;
	}
	.sec-3 {
		background: #D9D9D933;
		padding: 43px 0;
	}
	.sec-3 .nav.nav-pills .nav-item a {
    border-radius: 7px;
    max-width: 92px;
    min-width: 120px;
    padding: 4px 11px;
    font-size: 14px;
    line-height: 15px;
    min-height: 47px;
    display: grid;
    justify-content: center;
    align-items: center;
}
	.sec-3 .nav.nav-pills {
    display: flex;
    gap: 9px;
    justify-content: start;
    margin: 42px 25px;
    overflow-x: scroll;
    flex-wrap: inherit;
}
	.sec-3 .nav.nav-pills li {
		margin-bottom: 10px;
	}
	.hide-1{
		display: none !important;
	}
	.show-1{
		display: block !important;
	}
	#faq .card .card-header .btn-header-link.collapsed {
		padding: 20px 20px 20px 20px;
		background-color: #ffffff00;
	}
	#faq .card-body {
		flex: 1 1 auto;
		min-height: 1px;
		padding: 20px;
		font-family: Inter;
		font-size: 14px;
		font-weight: 700;
		line-height: 22px;
		text-align: left;
		background: #f9f9f9;
	}
	#faq .card-body span img {
		width: 100%;
	}
	#faq .card .card-header .btn-header-link::after {
		content: "+";
		font-family: 'Font Awesome 5 Free';
		font-weight: 900;
		float: right;
		transform: rotate(45deg);
		position: absolute;
		right: 20px;
		left: inherit;
		font-size: 28px;
	}
	#faq .card-body span {
		margin-top: 17px;
		display: block;
	}
	#faq .card .card-header .btn-header-link{
		padding: 20px 20px 20px 20px;
	}
	#faq .card {
		margin-bottom: 16px;
		border: 1px solid #e0e0e0;
		border-left: 0;
		border-right: 0;
		border-radius: 0;
	}
	.sec-4 {
    padding: 52px 0;
    margin: 0;
}
	.sec-4 .nav.nav-pills {
		padding: 49px 0 28px;
		border-bottom: 1px solid #464646;
	}
	.subheading {
    font-size: 28px;
    line-height: 125%;
}
    .heading {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: .5rem;
    }
	.sec-4 .nav-pills .nav-link {
		border-radius: 7px;
		max-width: 109px;
		min-width: 92px;
		padding: 0 9px;
		font-size: 13px;
		line-height: 15px;
		min-height: 63px;
		display: grid;
		justify-content: center;
		align-items: center;
	}
	.sec-4 .tab-content h4 {
		margin: 0px auto 19px;
		padding: 0 0 19px;
		font-family: "Manrope", sans-serif;
		font-size: 18px;
		font-weight: 700;
		line-height: 22px;
		letter-spacing: 1px;
		text-align: center;
		max-width: 203px;
		border-bottom: 1px solid #fff;
		position: relative;
		z-index: 1;
	}
	.sec-4 .tab-content h5 {
		margin: 0 auto 0 auto;
		font-family: "Manrope", sans-serif;
		font-size: 12px;
		font-weight: 400;
		line-height: 20px;
		text-align: center;
		max-width: 203px;
		position: relative;
		z-index: 1;
	}
	.sec-4 .bg-head {
		width: 100%;
		padding: 30px 15px;
		margin: 28px 0 18px;
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;
		background-position: center;
	}
	.sec-4 .bg-head::after {
		content: ' ';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 0;
		background: #FFFFFF21;
	}
	.sec-4-bt {
		margin: auto;
		width: 100%;
	}
	.sec-4-bt a {
		color: #fff;
		width: 100%;
	}
	.sec-5-img.show-1 {
		margin-top: 20px;
	}
    .sec-5 .accordion_rista .card .card-header .btn-header-link.collapsed {
        padding: 26px 20px 26px 0px;
    }
	.sec-5 .accordion_rista .card .card-header .btn-header-link {
		padding: 20px 40px 0px 50px;
	}
  .sec-5 .accordion_rista.accordion_rista2 .card .card-header .btn-header-link.collapsed {
    padding: 26px 24px 26px 0px;
}
	.sec-5 .accordion_rista.accordion_rista2 .card .card-header .btn-header-link {
		padding: 20px 40px 0px 0px;
	}
	.sec-5 .accordion_rista .card .card-header .btn-header-link::after {
		top: 20px;
	}
.sec-5 .accordion_rista .card-body {
    background: none;
    padding-left: 0;
    padding-top: 10px;
}
	.sec-3 .accordion_rista .card .card-header .btn-header-link.collapsed {
		padding: 20px 40px 20px 20px;
		background-color: #ffffff00;
	}
	.sec-3 .accordion_rista .card .card-header .btn-header-link {
		padding: 20px 40px 20px 20px;
	}
	.sec-3 .accordion_rista .card .card-header .btn-header-link::after {
		top: 20px;
	}
	.sec-5 {
		padding: 72px 0 72px;
	}
	.sec-8 .accordion_rista .card .card-header .btn-header-link.collapsed, .sec-8 .accordion_rista .card .card-header .btn-header-link {
		position: relative;
	}
	.sec-6 .sec-2-item.sec-2-item-1 .test-1 {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.sec-6 .sec-2-item.sec-2-item-1 .test-2 {
		flex: 0 0 100%;
		max-width: 100%;
		display: flex;
		justify-content: end;
	}
	.sec-6 .sec-2-item {
		flex-wrap: wrap;
	}
	.sec-6 .test-2 img {
		height: auto;
		object-fit: cover;
		width: auto !important;
		position: relative;
		bottom: 0;
	}
	.sec-6 .test-1 p {
		font-family: Inter;
		font-size: 18px;
		font-weight: 700;
		line-height: 34px;
		text-align: left;
		color: #000;
		margin: 0;
	}
	.sec-5 .subheading {
		margin-bottom: 36px;
	}
	.test-1 .client-img {
		position: absolute;
		width: 110px !important;
		height: auto;
		bottom: 25px;
		margin: 0;
	}
	.sec-6 .test-img {
		position: absolute;
		width: 54px !important;
		left: 45px;
		bottom: 250px;
		transform: scaleX(-1);
		top: inherit;
		right: inherit;
	}
	.sec-6 .owl-dots {
    position: absolute;
    left: 0;
    right: inherit;
    bottom: -48px;
}
	.sec-6 .owl-next {
		position: absolute;
		right: inherit;
		top: inherit;
	}
	.sec-6 .owl-prev {
    position: absolute;
    left: inherit;
    top: inherit;
    right: 50px;
}
	.sec-6 .owl-nav {
		width: 100%;
		float: left;
		position: absolute;
		right: 0;
	}
	.sec-9 {
    padding: 82px 0;
}
.sec-4 .nav-pills .nav-link {
    background: #212121;
}
	.sec-4 .accordion_rista .card {
		border: 1px solid #2d2d2d !important;
		border-top: 1px solid #2d2d2d !important;
		border-bottom: 1px solid #2d2d2d !important;
		border-radius: 4px !important;
		background-color: #000;
	}
	.sec-4 .accordion_rista .card .card-header .btn-header-link.collapsed {
		padding: 20px 20px 20px 20px;
		background-color: #ffffff00;
		color: #fff;
	}
	.sec-4 .accordion_rista .card .card-header .btn-header-link {
		background: #2d2d2d;
		color: #fff;
	}
	.sec-4 .accordion_rista .card-body {
		background: #2d2d2d;
		color: #fff;
	}
	.sec-4 .accordion_rista .card .card-header .btn-header-link.collapsed::after {
		color: #1D4ED8;
	}
	.sec-4-bt a {
		margin-top: 8px;
	}
	.sec-6 .test-1 p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #000;
    margin: 0;
}
	.sec-8 {
		padding: 72px 0 46px;
	}
	.sec-8 .subheading {
		margin-bottom: 26px;
	}
	.sec-8 .accordion_rista .card .card-header .btn-header-link.collapsed, .sec-8 .accordion_rista .card .card-header .btn-header-link {
        padding: 26px 40px 26px 20px;
        font-family: "Manrope", sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
    }
	.sec-8 .card-body p, .card-body li {
		font-family: "Manrope", sans-serif;
		font-size: 14px;
		font-weight: 300;
		line-height: 24px;
		text-align: justify;
	}
	.sec-8 .sec-para {
		display: none;
	}
	.footer-bar-up .subheading {
    margin-right: 0;
    margin-bottom: 8px;
    font-family: "Manrope", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.5px;
    text-align: left;
    max-width: 280px;
}
.footer-bar-up .heading {
    padding-bottom: 30px;
    border-bottom: 1px dashed rgba(255,255,255,0.5);
    margin-bottom: 10px;
    font-family: "Manrope", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}
	..footer-bar-up .fotr_barup_infos h4 {
		font-family: "Manrope", sans-serif;
		font-size: 20px;
		font-weight: 400;
		line-height: 28px;
		letter-spacing: -0.5px;
		text-align: left;
	}
	.footer-bar-up h5 {
		font-family: "Manrope", sans-serif;
		font-size: 16px;
		font-weight: 600;
		line-height: 22px;
		text-align: left;
		margin: 24px 0 36px;
	}
	.footer-bar-up .right-side .form-control {
    border: 1px solid #FFF;
    border-radius: 4px;
}
	.social-link {
		display: flex;
		gap: 32px;
		flex: 0 0 100%;
		max-width: 100%;
		justify-content: center;
	}
	.footer-bar-low ul {
        justify-content: center;
        flex-wrap: wrap;
        gap: 15px;
        padding: 0;
    }
	.footer-bar-low {
		padding: 47px 0;
	}
	/* .sec-4 .container .row {
		margin: 0;
	} */
	.sec-4 .container .row .col-12 {
    padding: 0 0px;
}
	.sec-4 .container .tab-content .row .col-12 {
    padding: 0 15px;
}
	.sec-3 .container .row .col-12 {
		padding: 0;
	}
	.footer-bar-low ul li a{
		font-weight: 500;
	}
	.sec-8  .accordion_rista .card {
		border: 0;
		border-bottom: 1px solid #dbdbdb;
		border-top: 0;
		margin: 0;
	}
	.sec-8  .accordion_rista .card .card-header {
		background: #fff;
	}
	.sec-8  .accordion_rista .card .card-header .btn-header-link::after {
		right: 0;
	}
	.sec-8  .accordion_rista .card .card-header {
		box-shadow: none !important;
	}
	.sec-5 .heading {
		text-align: center;
	}
	.sec-5 .subheading {
    text-align: center;
    font-size: 28px;
    line-height: 125%;
}
	.sec-4 .heading {
		text-align: center;
	}
	.sec-4 .subheading {
		text-align: center;
		font-weight: 300;
	}
	.sec-4 .subheading span {
		font-weight: 800;
	}
	.sec-4 .nav.nav-pills {
		justify-content: center;
	}
	.sec-8 .ban-bt {
		display: none;
	}
	.sec-9 .heading {
    margin: 10px 0 49px;
}
	.sec-5  .accordion_rista .card {
		margin-bottom: 16px;
		border: 1px solid #e0e0e0;
		border-left: 0;
		border-right: 0;
		border-radius: 0;
		border-top: 0;
		margin: 0;
	}

	section.banner.banner_home .banner-mobile {float: none;flex-direction: row;}

section.banner.banner_home .banner-mobile .ban-last {
    display: inherit;
    flex-direction: column;
    flex: 0 0 auto;
    max-width: 100%;
    justify-content: center;
    font-weight: 700;
}

section.banner.banner_home .banner-mobile .ban-last img.ban-last-img {
    margin: 0px 5px 10px 0;
}

section.banner.banner_home .banner-mobile .ban-last img.ban-last-img {
    margin: 0 0px 7px 0;
}

.point_block_main {
    padding: 0;
}

.point_box h3 {
    max-width: 99%;
}

.point_box p {
    max-width: 95%;
}

.sec-3.sec3_home .tabs_owl_crousel .owl-nav {
    display: none;
}

.hm_3boxes .point_box h5 {
    font-size: 16px;
    line-height: 26px;
    padding: 30px 30px 0 30px;
}

.hm_3boxes .point_box p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 5px;
    padding: 0 30px 22px 30px;
}

.point_acordimg {
    display: none;
}

.hm_heading.hm_heading_big h3 {
    max-width: 85%;
}

.hm_heading.hm_heading_big h3.new_style {
    max-width: 95%;
}

section.sec5new.sec_space.dordr_adjuestsec {
    padding-bottom: 0;
}

p.para.hmpara_btm {
    margin-top: 10px;
}

.banner-image.hmbanner_img {
    padding: 0 0 0 50px;
}

.banner-image.hmbanner_img span {
    width: 90%;
}

.banner-image.hmbanner_img span.hmbanner_img2 {
    width: 80%;
    left: 0;
}

.dordr_whysec_pics span.left_img {
    width: 100%;
}

.dordr_whysec_pics span.right_img {
    width: 100%;
    margin-top: 0;
}

.sec3new.sec_space.direct_ordr_most {
    padding-bottom: 190px;
}

section.sec-2.direct_sec2 {
    padding: 0 25px;
}

section.sec-8.faq_btm .col-lg-7 {
    flex: 0 0 100%;
    max-width: 100%;
}

.sec-8 .accordion_rista .card .card-header .btn-header-link.collapsed, .sec-8 .accordion_rista .card .card-header .btn-header-link {
    font-size: 16px;
    padding-right: 20px;
}

.accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link::after {
    width: 24px;
}

.accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link.collapsed::after {
    width: 20px;
    height: 2px;
    right: 0px;
}

.accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link.collapsed::before {
    height: 2px;
    right: 19px;
    width: 20px;
}

.sec-8 .accordion_rista.accordion_rista2 .card #faqhead6 .btn-header-link::after, .sec-8 .accordion_rista.accordion_rista2 .card #faqhead8 .btn-header-link::after, .sec-8 .accordion_rista.accordion_rista2 .card #faqhead9 .btn-header-link::after {
    top: 18px;
    right: -1px;
}

.sec-8  .accordion_rista.accordion_rista2 .card #faqhead6 .btn-header-link::after, .sec-8  .accordion_rista.accordion_rista2 .card #faqhead8 .btn-header-link::after, .sec-8  .accordion_rista.accordion_rista2 .card #faqhead9 .btn-header-link::after, .sec-8  .accordion_rista.accordion_rista2 .card #faqhead4 .btn-header-link::after, .sec-8  .accordion_rista.accordion_rista2 .card #faqhead5 .btn-header-link::after, .sec-8  .accordion_rista.accordion_rista2 .card #faqhead7 .btn-header-link::after, .sec-8 .accordion_rista.accordion_rista2 .card #faqhead9 .btn-header-link::after {
   
    left: inherit;
	 top: 18px;
    right: -1px;
}

.about_main .dc_hdgtitle {
    font-size: 34px;
    line-height: 46px;
}

section.about_elgibilty {
    padding: 15px 0 75px 0px;
}


.dotpe_popup.dotpe_popup2.dotpe_popup3 .dotpe_left_infos {
    padding-left: 30px;
}

.dotpe_poplogo img {
    width: 128px;
}

.dotpe_popup.dotpe_popup2 {
    flex-direction: column;
}

.dotpe_popup.dotpe_popup2 .dotpe_left {
    display: none;
}

.dotpe_popup.dotpe_popup2 .dotpe_right {
    flex: 0 0 100%;
    max-width: 100%;
}

.dotpe_popup.dotpe_popup2 .dotpe_right .busi_loan_form  form {
    border-radius: 24px;
}

.dotpe_popup.dotpe_popup2 .dotpe_right .busi_loan_form form button.btn {
    width: 100%;
}


.whatsapp_box {
    margin: 20px 0 0px 0;
}

.whatsapp_box.whatsapp_box2:after {
  display: none;
}

.whatsapp_box.whatsapp_box2:before {
	display: none;
}

.whatsapp_box.whatsapp_box2 {
    padding-left: 0;
}

section.sec-5.whats_tabimg {
    padding-top: 60px;
}

section.sec_space.whats_connect .hm_heading h3 {
    max-width: 100%;
    font-size: 21px;
    line-height: 29px;
}

section.sec_space.whats_connect .hm_heading h3 {
    font-size: 21px;
    line-height: 29px;
}

.whats_customr .sec-6 {
    padding: 10px 0 0 0;
}


.sec6_perfect_grid .row {
    position: relative;
    z-index: 1;
}


.sec6_perfect_grid .row.row_perfectimg1 {
	background: url(../../images/home-webp/Fine-Dine.webp) left top no-repeat;
	background-size: contain;
}

.sec6_perfect_grid .row.row_perfectimg2 {
	background: url(../../images/home-webp/QSR.webp) left top no-repeat;background-size: contain;
}

.sec6_perfect_grid .row.row_perfectimg3 {
	background: url(../../images/home-webp/Cloud-Kitchen.webp) left top no-repeat;background-size: contain;
}

.sec6_perfect_grid .row.row_perfectimg4 {
	background: url(../../images/home-webp/Pubs-bar-and-Club.webp) left top no-repeat;background-size: contain;
}


.sec6_perfect_grid .row .col-md-4 {
    padding: 0;
}

.sec6_perfect_grid .row {
    border-radius: 8px;
    overflow: hidden;
}

.sec6_perfect_grid {
    max-width: 420px;
    margin: 0 auto;
}

.sec6_perfect_grid .row:after {
    position: absolute;
    top: 0;
    
    background: rgba(255,255,255,0.7);
    left: 0;
    right: 0;
    content: "";
    width: 100%;
    z-index: -1;
    height: 53%; backdrop-filter: blur(14px);
}

.sec6new_inner .sec6_perfect_grid:nth-child(even) .row:after {
    background: rgba(0,0,0,0.7);
    backdrop-filter: blur(34px);
}

.sec6_perfect_grid .sec6_heading {
    color: #000;
    padding: 20px 0 0 20px;
}

.sec6new_inner .sec6_perfect_grid:nth-child(even) .sec6_heading {
    color: #fff;
    padding: 20px 0 0 20px;
}

.sec6new_inner .sec6_perfect_grid:nth-child(even) ul li {
  
    filter: brightness(0) invert(1);
}

.sec6_perfect_grid .sec6_perfect_points ul li {
    /* color: #fff; */
    filter:inherit;
}

.sec6_perfect_grid .sec6_perfect_points {
    padding: 0  0 20px 22px;
    margin-bottom: 0;
}

.sec6_imgbox img {
    width: 100%;
}

.hmtabs_arrows {
    display: none;
}

.point_img img {
    width: 340px !important;
}

.hm_heading p {
    font-size: 14px;
}

.hm_heading h2 {
    font-size: 14px;
    max-width: 340px;
    margin: 0 auto 10px auto;
}

.sec3_n_btns a {
    font-size: 16px;
}




span.banner-subheading.home_banner_subheading {
    background: none;
    font-size: 14px;
    padding: 3px 0;
    background: #E9EFFB;
    display: block;
    width: 205px;
    margin: 0 auto 10px auto;
    color: #000;
    line-height: 25px;
    height: 32px;
}
section.banner.banner_home .banner-content {
    text-align: center;
    padding: 0px 0 0 0;
}

section.banner.banner_home .banner-heading {
    max-width: 80%;
    text-align: center;
    margin: 0px auto 10px auto;
}

section.banner.banner_home .banner-para {
    padding: 30px 0 40px 0;
}

.banner-image.hmbanner_img span.hmbanner_img2 {
    bottom: 20px;
}

.hm_heading h5 {
    font-size: 16px;
    line-height: 26px;
}

.hm_heading.hm_heading_big h3.new_style {
     font-size: 16px;
    line-height: 26px;
}

.hm_heading h1 {
    font-size: 16px;
    line-height: 26px;
}

.sec_space {
    padding: 52px 0;
}

.hm_heading h3 {
    font-size: 28px;
    line-height: 125%;
}

.hm_heading h1 span {
    font-size: 28px;
    line-height: 125%;
}
section.sec-2 {
    padding-top: 0px;
    margin-bottom: 72px;
}
.banner-image.hmbanner_img img.ban-last-img {
    border-radius: 8px;
    width: 86%;
    margin-right: 15px;
}

.hm_srvccards .hm_srvc_infos p {
    max-width: 95%;
    display: none;
}

.sec-2 p.para small {
    font-size: 16px;
    font-weight: 500;
}

p.para.hmpara_btm {
    margin-top: 30px;
}

.sec-2 p {
    font-size: 16px;
    font-weight: 700;
}

.sec6_imgbox span {
    border-radius: 0 0 8px 8px;
}

.sec3_home_tabs .point_block_main {
    padding-right: 0;
    padding-left: 0;
}

.point_box {
    border-bottom: solid 1px #E6E6E6;
}

.point_accordians {
    padding: 0 30px;
}

.point_box {
    border-bottom: solid 1px #d3cccc;
}

.point_accordians {
    padding: 0 20px;
}

.sec-5 .naver_acorrds .accordion_rista .card {
    border: none !important;
    box-shadow: none;
    border-bottom: solid 1px #E8E8E8 !important;
    padding: 0 10px;
}

.sec-5 .naver_acorrds .accordion_rista .card.card_last{
    border-bottom: none !important;
}


.sec-5 .naver_acorrds .accordion_rista .card .card-header {
    background: #fff !important;
}

.sec-5 .naver_acorrds .accordion_rista .card .card-header a.btn.btn-header-link {
    background: none;
    padding-left: 0;
    padding-bottom: 26px;
}

.sec-5 .naver_acorrds .accordion_rista .card-body {
    background: #fff !important;
}

.sec-5 .naver_acorrds .accordion_rista .card .card-header .btn-header-link::after {
    left: inherit;
    right: 0;
}

.point_accordians.fasttrack_accordians {
    padding: 0;
    display: none;
}

.point_accordians.fasttrack_accordians .accordion_rista.accordion_rista2 .card-body {
    padding-left: 0;
    padding-right: 40px;
}

.fasttrack_accordians .fast_img_shape {
    min-width: 50px;
}


.sec6new .hm_heading p {
    font-size: 14px;
    line-height: 22px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

section.sec-5 .hm_heading p {
    font-size: 14px;
    line-height: 22px;
    margin-bottom:30px ;
}

.sec6_perfect_points ul li {
    font-size: 14px;
    font-weight: 500;
    background-position: 5px center;
    background-size: 16px 16px;
    padding-left: 29px;
    margin-bottom: 5px;
    margin-left: -5px;
}

.sec6_heading br {
    display: none;
}

.sec6_perfect_grid .sec6_heading {
    font-size: 20px;
    margin-bottom: 12px;
    line-height: 28px;
    min-height: 76px;
}

/* .sec-3 .nav.nav-pills .nav-item a {
    font-size: 14px;
    line-height: 20px;
    min-height: 64px;
    font-weight: 700;
    padding: 4px 15px;
    min-width: 136px;
} */

.sec-3 .nav.nav-pills .nav-item a {
    font-size: 14px;
    line-height: 20px;
    min-height: 64px;
    font-weight: 700;
    padding: 4px 15px;
    min-width: 136px;
    white-space: nowrap;
    width: 100%;
    display: inline-flex;
    max-width: inherit;
}

.point_box h5 {
    font-size: 14px;
    line-height: 38px;
    font-weight: 500;
    margin-bottom: 0;
}

.point_box h3 {
    margin: 5px 0 15px 0;
    font-size: 20px;
    line-height: 24px;
}

.fotr_barup_infos p {
    font-size: 14px;
    line-height: 22px;
    max-width: 100%;
    border-bottom: dashed 1px rgba(255,255,255,0.25);
    padding-right: 15%;
    padding-bottom: 30px;
    margin-bottom: 30px;
}
.footer-bar-up .right-side .form-control {
    border: 1px solid #FFFFFF66;
    padding: 26px 22px;
    background: #12121200;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
}

.footer-bar-up .right-side .form-control::placeholder {
	font-size: 14px;
	font-weight: 500;
}

.footer-bar-low ul .foot-logo {
    width: 92px;
}

.footer-bar-low ul li.social-link .foot-logo {
    width: auto;
}

.banner-mobile .ban-last span {
    display: block;
}

.accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link span.plusminus:after {
    width: 24px;
}

.accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link.collapsed span.plusminus:before {
 
    width: 20px;
}

section.banner.banner_renew_posprobg .banner-image {
    position: inherit;
    bottom: 0;
    right: 0;
    left: 0;
}

section.banner.banner_renew_posprobg .container .row {
    flex-direction: row;
    margin-right: -15px;
}

section.banner.banner_renew_posprobg .banner-content {
    text-align: center;
}

section.banner.banner_renew_posprobg .banner-content .banner-heading {
    text-align: center;
    font-size: 40px;
    max-width: 340px;
    padding-right: 0;
    margin: 0 auto 30px auto;
    line-height: 45px;
}


section.banner.banner_renew_posprobg .banner-heading span.home_banner_subheading {
    margin: 0px auto 20px auto;
    font-size: 14px;
    line-height: 25px;
    font-weight: 500;
    padding: 3px 11px;
    width: 295px;
}

section.banner.banner_renew_posprobg {
    background: #000000;
}


section.banner.banner_renew_posprobg .container {
    padding: 0 15px;
}

section.banner.banner_renew_posprobg .container .row .col-lg-7 {
    padding-right: 15px;
}

section.banner.banner_renew_posprobg .container .col-lg-5 {
    padding-right: 15px;
}

.tabs_btns {
    display: none;
}

section.banner.banner_renew_posprobg .banner-para {
    margin-right: 0;
    text-align: center;
    max-width: 340px;
    margin: 0 auto 30px auto;
}

.pospage_mobileimg {
    display: block;
    padding: 0 0 20px 0;
}

.banner_renew_posprobg .banner-image{ display: none;}

section.banner.banner_renew_posprobg .banner-mobile {
    align-items: flex-start;
    justify-content: center;
    display: flex;
    flex-direction: row;
}

section.banner.banner_renew_posprobg .ban-last {
    margin: 0px 0 4px 0;
    flex: 0 0 48%;
    max-width: 48%;
}

section.banner.banner_renew_posprobg {
    padding-bottom: 24px;
    padding-top: 52px;
}
section.sec-2.sec2_pospro {
    padding-top: 52px;
}

section.sec-3.sec3_home.sec3_pospro .heading {
    font-size: 16px;
}


.sec-3 .term {
    gap: 0;
    flex-direction: column;
}

.sec-3 .term .term-1 {
    flex: 0 0 100%;
    max-width: 100%;
}

.sec-3 .term .term-1 img {
    border-radius: 8px;
}

.sec-3 .term p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 32px;
}

section.sec-3.sec3_home.sec3_home_tabs {
    background: none;
    padding: 0 0 5px 0;
}

section.sec-4.sec4_pospro .subheading {
    line-height: 125%;
    font-weight: 700;
    max-width: 340px;
    margin: 0 auto 0 auto;
}

section.sec-4.sec4_pospro .subheading span {
    display: inline;
    font-weight: 300;
}

.sec-4.sec4_pospro .nav.nav-pills {
    padding-top: 30px;
    gap: 0px;
    overflow: auto;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 5px 5px;
    margin-top: 30px;
    border: none;
    justify-content: flex-start;
}

.sec-4.sec4_pospro .nav.nav-pills li.nav-item {
    margin: 0 5px;
}


.getdemo_float {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    background: #000;
    padding: 13px 15px;
    display: none;
}

.getdemo_float.getdemo_show { display: block;
}

.getdemo_float button {
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    padding: 9px 42px;
    background: #1D4ED8;
    color: #fff;
    border-radius: 4px;
    position: relative;
    z-index: 1;
    border: none;width: 100%;
}

.getdemo_float button:hover {
    background: #153AA1;
}

.sec-4 .bg-head {
    background: none;
    padding: 27px 27px 0 27px;
}

.sec-4 .bg-head::after {
    background: none;
}

.sec-4 .tab-content .bg-head h4 {
    text-align: left;
    max-width: 100%;
    color: #3F3F3F;
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    margin-bottom: 7px;
}

.sec-4 .tab-pane {
    background: #fff;
    margin: 10px;
    border-radius: 8px;
}
.sec-4 .tab-content .bg-head h5 {
    color: #000;
    font-size: 18px;
    max-width: 100%;
    text-align: left;
    font-weight: 600;
    line-height: 26px;
    max-width: 75%;
    margin-right: auto;
    margin: 0;
}

.sec-4 .block-3 h3 {
    color: #000;
}

.sec-4 .block-3 p {
    color: #585858;
    min-height: inherit;
}

.sec-4 .block-3 {
    background: none;
    padding: 5px;
}

.sec-4 .block-3 img.outletmob {
    display: block;
}

.sec-4 .block-2{ display: none;}

.sec-4 .block-1.mincontt .block-3 h3 {
    min-height: inherit;
}
.sec-4 .block-1.mincontt .block-3 p {
    min-height: inherit;
}

.sec-4 .block-1.mincontt2 .block-3 p {
    min-height: inherit;
}

.sec-5 {
    padding: 52px 0 52px;
}

.sec-4 .block img {
    display: none;
}

.sec-4 .block-3 {
    position: relative;
    z-index: 1;
    padding: 0 0 0 36px;
}

.sec-4 .block-3 img.outletmob {
    position: absolute;
    left: 0;
    top: 3px;
    transform: scale(1.0) translate(0px, 0px);
}

.sec-5 .heading {
    font-size: 16px;
    font-weight: 500;
}


.accordion_rista .card .card-header {
    background: none !important;
    border: none;
    box-shadow: none;
}

.accordion_rista .card .card-header a.btn.btn-header-link {
    background: none;
    border: none;
    padding: 20px 20px 10px 0;
    position: relative;
    color: #000;
    line-height: 20px;
}

.sec-5 .accordion_rista .card {
    border: none !important;
    border-bottom: solid 1px #ddd !important;
    border-radius: 0 !important;
    padding: 0 10px;
}

.sec-5 .hm_3boxes .accordion_rista .card.bx_cardlst {
    border-bottom: none !important;
}

.sec-5 .accordion_rista .card-body {
    background: none !important;
    padding-right: 30px;
}

.sec-5 .accordion_rista .collapse.show {
    background: none;
}



.accordion_rista .card .card-header a.btn.btn-header-link:after {
    left: inherit;
    right: 0;
    top: 6px;
}

.accordion_rista .card .card-header a.btn.btn-header-link.collapsed:after {
  
    top: 26px;
}

.fasttrack_accordians .accordion_rista.accordion_rista2 .card .card-header a.btn.btn-header-link:after {
    top: 26px;
}

.fasttrack_accordians  .accordion_rista.accordion_rista2 .card .card-header a.btn.btn-header-link:after {
    left: inherit;
    right: 13px;  content: "_";
    transform: rotate(0deg);top: 13px;
}

.fasttrack_accordians   .accordion_rista.accordion_rista2 .card .card-header a.btn.btn-header-link.collapsed::after {
    content: "+";
    transform: rotate(0deg);
    font-size: 29px;
    top: 36px;
}


.sec-5 .accordion_rista .card .card-header .btn-header-link {
    padding: 20px 20px 20px 0px;
}

.sec-6 {
    padding: 52px 0 112px;
}

.sec-6 .heading {
    font-size: 16px;
}

.sec-6 .subheading {
    font-size: 28px;
    line-height: 125%;
    max-width: 340px;
    margin: 0 auto;
}
.sec-6 .owl-nav {
    display: block;
}

.footer-bar-up h4 {
    max-width: 270px;
}

.footer-bar-up h5{
    max-width: 270px
    
}

.accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link.collapsed span.plusminus:before {
     width: 14px !important;
}

.accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link  span.plusminus:after {
     width: 18px !important;
}

section.temrms_main_infos {
    padding: 45px 0;
}

.auxilary_head p {
    font-size: 14px;
    line-height: 20px;  font-weight: 500;
}

.terms_content .terms_block p {
    font-size: 14px;  font-weight: 500;
}

.terms_content .terms_block ul li {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}

.terms_content {
    text-align: center;
}

.terms_content h4 {
    font-size: 14px;
    line-height: 20px;
}

.about_head h2 {
    font-size: 28px;
    max-width: 80%;
    line-height: 125%;
    margin-bottom: 20px;
}


.about_head h5 {
    font-size: 14px;
    line-height: 29px;
    background: #E9EFFB;
    display: inline-block;
    border-radius: 35px;
    width: 81px;
    height: 35px;
    line-height: 33px;
}

.about_head p {
    color: #6d6d6d;
    font-size: 14px;
    line-height: 20px;
    max-width: 70%;
}

.aboutteam_slides {
    padding: 30px 0 0px 0;
}

section.aboutmain {
    padding: 52px 0;
}

.abouticons_grid .col-md-3 {
    flex: 0 0 50%;
    max-width: 50%;
}

.aboutcards .about_infos p {
    font-size: 14px;
    line-height: 20px;
    max-width: 150px;
}

.aboutcards .about_infos h4 {
    font-size: 15px;
    line-height: 20px;
    max-width: 130px;
    margin: 0 auto 9px auto;
}

.about_head.about_head_last p {
    font-size: 14px;
    line-height: 22px;
    margin: 0 auto 40px auto;
    max-width: 60%;
    color: #000;
}

.about_head.about_head_last h2 {
    line-height: 32px;
    max-width: 70%;
    text-transform: inherit;
}
.about4infos h3 {
    max-width: 80%;
}

.about_head.about_head_last p {
    max-width: 80%;
}

.about_head.contact_head h5 {
    width: 101px;
}

.footerlogo_mobile .footerlogo{ display: block;}

.point_btns.point_btns_mob a img {
    margin-left: 5px;
}

.rista_tabs .container {
    padding: 0;
}

.sec-3 .rista_tabs .container ul.nav.nav-pills {
    margin-left: 0;
    margin-right: 0;
    gap: 0;
}

.sec-3 .rista_tabs .container ul.nav.nav-pills::-webkit-scrollbar {
    -webkit-appearance: none;
}

.sec-3 .rista_tabs .container ul.nav.nav-pills li.nav-item {
    margin: 0 0px 0 16px;
}

.sec-3 .rista_tabs .container ul.nav.nav-pills li.nav-item:last-child {
    margin-right: 9px;
}

section.sec-3.sec3_home.visible-section .rista_tabs.new-class {
    padding: 21px 0 4px 0;
}

section.sec-5 .hm_heading p {
    max-width: 80%;
    margin: 0 auto;
}

section.sec-5 .hm_heading p {
    max-width: 340px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0;
}

p.tab_lastline {
    text-align: left;
    border-top: solid 1px #d3cccc;
    padding: 20px 30px 0 30px;
    margin-top: 0;
}

p.tab_lastline a.hm_fillbtn {
    margin-left: auto;
    margin-right: auto;
}

.footer_linkmain .col-md-3 {
    flex: 0 0 100%;
    max-width: 100%;
}

.footer_infoslinks {
    text-align: left;
}

.footer-bar h3 {
    text-align: left;
    margin-bottom: 17px;
    margin-top: 24px;
    line-height: 21px;
}

section.footer-bar {
    padding: 28px 0 0px 0;
}

.footer-bar-low {
    padding: 14px 0 40px 0;
}

.footer-bar-low ul li.termslink {
    flex: 0 0 46%;
    max-width: 46%;
    text-align: right;
    padding: 0 7px;
}

.footer-bar-low ul li a {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}

.copyright_line.copyright_line_mobile {
    font-size: 14px;
    line-height: 16px;
}
section.sec-3.sec3_home.visible-section .tab_blockinfo_main {
    margin-top: 50px;
}

section.sec-3.sec3_home.visible-section .tab_blockinfo_main {
    margin-top: 25px;
}

.term-new {
    margin-top: 0;
}

section.sec-4.sec4_pospro .heading {
    font-size: 16px;
}

.sec-4 .block-1 {
    padding: 0 12px;
    padding-bottom: 8px;
}

.sec-5 .subheading {
    max-width: 340px;
    margin: 0 auto;
}
.sec-5 .accordion_rista .card:last-child {
    border-bottom: none !important;
}

.name_profile {
    padding: 40px 0 0px 0;
}
.test-2 {
    margin-top: 0;
}

.sec-6 .owl-theme .owl-nav {
    margin-top: 10px;
}
.sec-8 .accordion_rista .card .card-header .btn-header-link.collapsed::after {
 
    font-size: 24px;
}

section.banner.banner_home .banner-content span.banner-subheading.home_banner_subheading {
    padding: 3px 10px 0;
    text-align: center;
    width: 100%;
    max-width: 241px;
}

section.banner.banner_home.banner_noimage.banner_direct_ordering.set_banner span.banner-subheading.home_banner_subheading {
    width: 144px;
}

section.banner.banner_home.banner_noimage.banner_direct_ordering.set_banner.set_whatsapp_banner_section span.banner-subheading.home_banner_subheading {
    width: 190px;
}

.hm_heading p {
    max-width: 80%;
    margin: 0 auto 10px auto;
}

section.banner.banner_home.banner_noimage.banner_direct_ordering.set_banner.set_inventory_banner span.banner-subheading.home_banner_subheading {
    width: 181px;
    font-size: 14px;
}
section.banner.banner_home.banner_noimage.banner_direct_ordering.set_banner.set_inventory_banner .banner-heading {
    max-width: 80% !important;
}

.take_control_sec .hm_heading h3 {
    max-width: 80%;
    margin: 0 auto 40px auto;
    text-align: center;
}

.about4infos p {
    max-width: 90%;
}

.footer-bar-up.whatsapp_footer_bar h2.subheading.subheading_aboutfoot {
    padding-right: 30%;
    max-width: 100%;
    padding-bottom: 30px;
    border-bottom: 1px dashed rgb(255 255 255 / 50%);
    margin-bottom: 30px;
}

.container.contctt_con {
    padding: 0;
}

section.sec-3.sec3_home.visible-section .rista_tabs.new-class {
    background: none;
    position: inherit;
    top: 0 !important;
    left: 0;
    right: 0;
    padding: 0;
    z-index: 2;
}
section.sec-3.sec3_home.visible-section.sec3_pospro .rista_tabs.new-class {
    top: 0;
}

section.sec-3.sec3_home.visible-section.sec3_pospro .tab_blockinfo_main {
    margin-top: 0;
}

section.sec-3.sec3_home.visible-section .rista_tabs.new-class .nav.nav-pills .nav-item a.nav-link.active {
    background: #000000;
    color: #fff;
}

section.sec-3.sec3_home.visible-section .rista_tabs.new-class .nav.nav-pills .nav-item a {
    color: #000;
}

section.sec-3.sec3_home.visible-section .rista_tabs.new-class .nav.nav-pills .nav-item a:hover {
    color: #fff;
}

section.sec-3.sec3_home.visible-section .rista_tabs.new-class .nav.nav-pills {
    margin: 42px 0px 24px 0px;
}

.sec-8 .accordion_rista .card .card-header .btn-header-link::after {
    top: 6px !important;
}
.sec5new_top {
    padding-top: 30px;
    padding-bottom: 30px;
}


.sec-5 .accordion_rista .card:last-child{ border-bottom: none !important;}

.footer-bar-low ul li.footerlogo_mobile {
    text-align: center;
}

.footer-bar-low ul li.footerlogo_mobile {
    text-align: center;
}

.sec-4 .block-3 h3 {
    margin: 13px 0 6px 0;
    font-size: 16px;
}

.owl-slider.pos-testimonial-slider {
    padding: 0 15px;
}

section.sec-8.faq_btm {
    padding: 52px 0;
}

.sec-3 .subheading {
    max-width: 80%;
}

section.sec-3.sec3_home.sec3_home_tabs.sec3_hmproductsuite .point_block_main {
    padding: 30px;
}

section.sec-5 .subheading {
    padding-bottom: 12px;
}

.footer-bar-up h4 {
    font-size: 28px;
    line-height: 125%;
}

section.sec-5.hm_ristapos {
    padding-top: 52px;
}

.point_block_main.point_block_main2 {
    margin-top: 32px;
}

section.sec3new.sec_space.direct_ordr_most.inverntry_m_review {
    padding-bottom: 66px;
}

section.sec-2.aboutsec2.aboutseclast p {
    font-weight: 400;
}

section.sec-2.aboutsec2.aboutseclast p.para.hmpara_btm.ab-crousel-text {
    font-weight: 600;
}

.ban-last {
    font-weight: 400;
}

.sec3_home_tabs .point_block_main {
    margin: 0 0px;
}

.sec3_home_tabs .point_row_grid .point_img span {
    height: auto;
}

.sec3_home_tabs .point_row_grid .point_img img {
    width: 100% !important;
}

.hm_3boxes .point_accordians {
    padding: 0;
}

.sec-5 .hm_3boxes .point_accordians .accordion_rista .card {
    padding: 0 10px;
    margin: 0 20px;
}
p.tab_lastline a.hm_fillbtn {
    max-width: 180px;
}

.hm_heading.hm_heading_big h3.new_style span {
    font-size: 28px;
    line-height: 125%;
}

.hm_heading.hm_heading_big h3 {
    font-size: 28px;
    line-height: 125%;
}

.sec-4 .block-1 span.deskicon {
    min-height: inherit;
    display: none;
    align-items: flex-end;
}

.sec6_imgbox span {
    height: 170px;
}
section.sec-5.hm_ristapos .selected-image {
    height: auto;
}

.fasttrack_accordians .accordion_rista.accordion_rista2 .card .card-header a.btn.btn-header-link.collapsed {
    font-size: 16px;
}

.fasttrack_accordians .accordion_rista.accordion_rista2 .card .card-header a.btn.btn-header-link {
    font-size: 16px;
}

.dotpe_popouter.dotpe_popouter_new span.closebtn_new {
    right: 10px;
    background: black;
    top: 10px;
            width: 22px;
    height: 22px;
    line-height: 19px;
}

.dotpe_popouter.dotpe_popouter_new span.closebtn_new img {
    width: 9px;
}

.form_heading h3 {
    font-size: 20px;
    line-height: 26px;
    padding-right: 31px;
}

.form_heading p {
    font-size: 14px;
    line-height: 22px;
    margin-top: 11px;
}

.busi_loan_form form .form_click {
    font-size: 11px;
    color: #555;
    line-height: 18px;
    margin-bottom: 25px;
}

.sec-2 .container {
    padding: 0;
}

section.sec-2.set_logo_section {padding-left: 0;padding-right: 0;}

.dotpe_popouter.dotpe_popouter_new .busi_loan_form form .form-group.form_group_nice .nice-select .nice-select-dropdown {
    height: 120px;
    overflow: auto;
}

.direct_brand2logos {
    padding: 0 0 40px 0;
}

section.sec-2.direct_sec2.set_second_logo_section {
    padding-left: 0;
}

section.sec-3.sec3_home.sec3_inverntry.set_invt_choose_rista.inven_choose_rista.sec3_home_tabs {
    padding-top: 52px;
}

.sec-3.set_invt_choose_rista .subheading {
    margin-bottom: 42px;
}


section.banner.banner_home .banner-heading span {
    display: block;
}

.banner_home_new .banner-image.hmbanner_img span.hmbanner_img2 {
    height: 154px;
    left: 0px;
    width: auto;
    bottom: 14px;
}

.banner_home_new .banner-image.hmbanner_img span {
    height: 260px;
    width: 230px;
}

.banner-image.hmbanner_img {
    padding: 0 0 0 0px;
    max-width: 330px;
}

section.banner.banner_home .banner-para {
    max-width: 85%;
}

.sec-2 p.para {
    margin: 0 auto 20px auto;
    max-width: 270px;
}

.sec4newa_grid .hm_whycards {
    text-align: center;
    max-width: 100%;
}

section.sec4new.sec_space {
    padding-bottom: 52px;
    padding-top: 52px;
}

.sec4newa_grid .col-md-4 {
    flex: 0 0 50%;
    max-width: 50%;
}

.hmtabs_mainbox.hmtabs_mainbox_redesign .tab_blockinfo_main .tab-pane {
    display: block;
    opacity: 1;
    margin-top: 40px;
}

.sec-3 .hmtabs_mainbox.hmtabs_mainbox_redesign .rista_tabs .container ul.nav.nav-pills {
    display: none;
}

.hmtabs_mainbox.hmtabs_mainbox_redesign {
    padding-top: 10px;
    padding-bottom: 52px;
}

.perfect_grid_desk{display: none;}

.perfect_grid_mobile{
    display: block;    
}

.sec6_perfect_grid.sec6_perfect_grid_blck .row:after {
    backdrop-filter: blur(34px);
    background: #000000b3;
}

.sec6_perfect_grid.sec6_perfect_grid_blck .row .sec6_heading {
    color: #fff;
}

.sec6_perfect_grid.sec6_perfect_grid_blck .row ul li {
    filter: brightness(0) invert(1);
}

.perfect_grid_mobile .sec6_perfect_grid .row {
    margin: 0 !important;
}

.perfect_grid_mobile .sec6_perfect_grid {
    margin-right: 25px;
    margin-left: -50px;
}

section.sec6new.sec_space {
    padding-top: 0;
    padding-bottom: 52px;
}

.pos_plygridinfos h3 {
    font-size: 18px;
    line-height: 24px;
}

.pos_playgrid.pos_playgrid_right .row .pos_plygridinfos li {
    padding-right: 2%;
}

.pos_plygridinfos h3 {
    font-size: 20px;
    line-height: 24px;
}

.pos_plygridinfos h5 {
    font-size: 18px;
}

.pos_plygridinfos ul li {
    padding: 0 4% 0 35px;
    margin-bottom: 14px;
}

.pos_playgrid .row {
    flex-direction: column-reverse;
}

.pos_plygrid_img {
    padding: 0 0 30px 0;
}

.pos_playgrid.pos_playgrid_right .row {
    flex-direction: column-reverse;
}

.pos_playgrid.pos_playgrid_right .row .pos_plygridinfos {
    padding: 0;
}

.sec5_hiddepos{ display: none;}

.sec4newa_grid.sec4newa_grid_pospage{
    display: block;
}

.sec5new_inner.poply_desk{display: none; }

.sec5new.sec5new_propage.sec5_posplaybg{ background: #fff;}

section.sec5new.sec_space.sec5new_propage.sec5_posplaybg .sec5new_top {
    background: #fff;
    padding-top: 50px;
}
.sec5new.sec5new_propage.sec5_posplaybg .subheading {
    color: #000;
}

.sec5new.sec5new_propage.sec5_posplaybg .posblckhm_heading p {
    color: #000;
}

.ban-last-img.hm_mainbandesktop{
    display: none;

}

.ban-last-img.hm_mainbanmobile{
    display: block;
}

.invent_mobile{ display: block;}

.set-invt-control-section .take_conbtn.take_conbtn_accords{display: block; }

.set-invt-control-section .mobile-design-accordian .take_conbtn.take_conbtn_accords a {
    color: #fff;
}

section.sec-3.sec3_home.sec3_inverntry.sec3_home_tabs {
    padding-bottom: 52px;
}

section.banner.banner_home.set_whatsapp_banner_section .banner-heading span {
    display: inline-block;
    margin-left: 0;
}

.sec6_perfect_grid .sec6_heading br {
    display: block;
}

.pos_playgrid {
    padding-top: 40px;
}

.pos_plygridinfos h3 {
    max-width: 90%;
}

.pos_play_grid_outer {
    padding: 0 15px;
    padding-bottom: 42px;
}

.sec5a_contt .hm_heading.hm_heading_big h3 {
    max-width: 99%;
}

.rista_go_newinfos p {
    max-width: 90%;
}

section.sec-5 .hm_heading p {
    max-width: 90%;
}

section.sec-5.sec-5_pospage .subheading {
    margin-bottom: 10px;
}

section.sec-5 .hm_heading p {
    max-width: 90%;
}

.posblckhm_heading p {
    max-width: 97%;
    margin: 24px auto 10px auto;
}

.sec4_prostabs.sec4_prostabs_newtabs .row{ padding: 0 0px;}

.sec-4 .sec4_prostabs.sec4_prostabs_newtabs .container .row .col-12 {
    padding: 0;
}

.sec-4.sec4_pospro .nav.nav-pills::-webkit-scrollbar{
    -webkit-appearance: none;
}

::-webkit-scrollbar{-webkit-appearance: none; width: 0; }

.sec4_prostabs.sec4_prostabs_newtabs .tab-content {
    padding: 0 8px;
    margin-top: -20px !important;
}

.sec-6 .owl-nav button {
    width: 44px;
    height: 44px;
    border: 1px solid #fff !important;
}

.sec-6 .owl-nav button span {
    font-size: 32px;
    top: -10px;
    line-height: 58px;
}

.sec4_prostabs.sec4_prostabs_newtabs .tab-content.mt-3 .row {
    padding: 0 18px;
}

.gobeyond_slide .owl-item:nth-child(even) .item {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 260px;
    width: 100%;
}

section.sec3new.sec_space.postestimonial.direct_ordr_most.inverntry_m_review {
    padding-top: 52px;
    padding-bottom: 100px;
}

.mobile-design-accordian .card-header .title {
    font-size: 17px;
}

section.sec-5.whats_tabimg.set_whatsapptab_section.set_whatsapptab_second_section p {
    max-width: 70%;
    margin: 0 auto 30px auto;
}

.pospage_mobileimg img {
    width: 100%;
}

}

  @media (max-width: 576px) {
	.footer-bar .foot .foot-1 {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.sec-8  .accordion_rista .card .card-header {
		position: relative;
	}
	.footer-bar {
		padding: 80px 0 80px;
	}
	.sec-8 .accordion_ristaq .card .card-header .btn-header-link::after {
		right: -28px;
		left: inherit;
		top: 0;
	}
	.sec-9 .restaurants-img img {
		height: 24px !important;
		width: auto;
	}
	.sec-9 .restaurants-img .big-img {
		width: auto !important;
		height: 16px !important;
		margin: auto;
	}
	/* .header .navbar-light .navbar-toggler-icon {
		width: 18px;
		height: 12px;
	} */
  
.header {
    padding: 22px 0;
}
	.subheading span {
		display: inline;
	}
	
	.sec-6 .sec-2-item.sec-2-item-1 .test-2 img {
		height: 221px;
		width: auto;
	}
	.sec-6 .test-img {
        position: absolute;
        width: 54px !important;
        left: 25px;
        bottom: 80px;
        transform: scaleX(-1);
        top: inherit;
        right: inherit;
    }
.sec-6 .sec-2-item {
    padding-left: 30px;
    padding-top: 38px;
    padding-right: 30px;
    margin-top: 32px;
}
	.sec-4-bt {
		margin: auto;
		width: 100%;
		padding: 0 15px;
	}

  .hm_heading.hm_heading_big h3 {
    font-size: 28px;
    max-width: 340px;
    margin-bottom: 20px;        line-height: 125%;
}

.hm_heading.hm_heading_big h3.new_style span {
    font-size: 28px;
    max-width: 340px;
    margin: 0 auto;        line-height: 125%;
}
.hm_heading.hm_heading_big h3.new_style{
    max-width: 100%;
}

.sec-8 .accordion_rista .card .card-header .btn-header-link.collapsed, .sec-8 .accordion_rista .card .card-header .btn-header-link {
    font-size: 16px;
}

.ReactModalPortal .ReactModal__Content {
    margin-right: -30% !important;
}

.knowyour_grid .col-md-3 {
    flex: 0 0 100%;
    max-width: 100%;
}

.sec6_perfect_grid {
    max-width: 100%;
    margin: 0 0x;
}

.sec6_perfect_grid .sec6_perfect_points {
    padding: 0  0 20px 22px;
    margin-bottom: 0;
}

.sec6_perfect_grid .row.row_perfectimg4, .sec6_perfect_grid .row.row_perfectimg1, .sec6_perfect_grid .row.row_perfectimg3, .sec6_perfect_grid .row.row_perfectimg2 {
    background-size: cover;
}

.accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link span.plusminus:after {
    width: 20px;
}

.accordion.accordion_rista.accordion_rista2.accordion_rista3 .card .card-header .btn-header-link.collapsed span.plusminus:before {
 
    width: 16px;
}

.aboutpg_images .about_pgimgone img {
    margin-left: 86px;
}

.aboutpg_images .about_pgimgone.about_pgimgtwo img {
    width: 270px;
    margin: 0;
}

.aboutpg_images.aboutpg_images_mobile {
    display: block;
    position: relative;
    top: 0;
    min-height: 500px;
    padding-bottom: 70px;
}

.aboutpg_images .about_pgimgone.about_pgimgtwo {
    left: 0;
    right: 30px;
    bottom: 30px;
}

.aboutpg_images .about_pgimgone img {
    width: 286px;
}

.conatct_form form {
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
}

.contact_infosin p {
    padding: 25px 15px 30px 15px;
}

.contact_infosin p a.contactbtns {
    margin-left: 0;
    margin-top: 20px;
}

.contact_infosin p {
    padding: 45px 15px 50px 15px;
}

.contact_thakss p img {
    display: block;
    margin: 0 auto 10px auto;
}

.contact_thakss p {
    max-width: 270px;
    margin: 0 auto 20px auto;
}

.contact_thakss  .contact_infosin p {
    max-width: 100%;
}

.sec6new .hm_heading.hm_heading_blue h3 span {
    display: block;
}
.sec5newa_grid {
    padding: 0 25px;
}

.footer-bar-up .subheading {
    max-width: 80%;
}
.footer-bar-up h4 {
    letter-spacing: -0.5px;
}

section.sec-3.sec3_home.sec3_inverntry.set_invt_choose_rista.inven_choose_rista.sec3_home_tabs {
    padding-top: 52px;
}

section.sec-3.sec3_home.sec3_home_tabs .point_block_main {
    padding: 30px;
}

section.sec-3.sec3_home.sec3_inverntry.set_invt_choose_rista.inven_choose_rista.sec3_home_tabs .point_block_main {
    padding: 0;
}

section.sec-2.set_logo_section {
    padding-right: 0;
    padding-left: 0;
}



.banner_home_new .banner-image.hmbanner_img span.hmbanner_img2 {
    left: 0px;
}



  }

  @media (max-width: 440px) {
.sec-8  .accordion_rista .card #faqhead6 .btn-header-link::after, .sec-8  .accordion_rista .card #faqhead8 .btn-header-link::after, .sec-8  .accordion_rista .card #faqhead9 .btn-header-link::after {
		right: -28px;
		left: inherit;
		top: -25px;
}

.sec-8 .accordion_rista .card .card-header .btn-header-link.collapsed, .sec-8 .accordion_rista .card .card-header .btn-header-link {
    font-size: 14px;
}

.accordion_rista .card .card-header .btn-header-link.collapsed {
    font-size: 16px;
    line-height: 24px;

}

.accordion_rista .card .card-header .btn-header-link {
    font-size: 16px;
}

section.sec-5 .hm_heading p{ font-size: 20px; line-height: 30px;}

.hm_srvccards .hm_srvc_infos p {
    display: none;
}

.dotpe_popouter .busi_loan_form form {
    padding: 25px;
}

section.banner.banner_home .banner-heading {
    max-width: 273px;
}

section.banner.banner_home .banner-para {
    max-width: 340px;
}

.banner-mobile .ban-last {
    margin: 0 2px;
}

p.para.hmpara_btm {
    max-width: 70%;
    margin: 30px auto 0 auto;
}

.hm_heading h1 span {
    max-width: 340px;
}



/* .sec6_perfect_grid {
    max-width: 340px;
} */
.sec6_imgbox {
    max-height: 163px;
}
.ReactModalPortal .ReactModal__Content {
    margin-right: -42% !important;
}

.perfect_grid_mobile .sec6_perfect_grid {
    margin-right: 20px;
    margin-left: -48px;
}

.sec6_perfect_grid .sec6_perfect_points {
    padding: 0  3px 0px 15px;
    margin-bottom: 0;
}

.sec6_perfect_grid .sec6_perfect_points {
    margin: 0 0 0px 0px;
}

.sec6_perfect_points ul li {
    font-size: 13px;
    background-position: 5px center;
    background-size: 14px 14px;
    padding-left: 23px;
    margin-left: -5px;
    padding-right: 20px;
    line-height: 17px;
    margin-bottom: 13px;
    background-position: left top;
}

.sec6_perfect_grid .sec6_heading {
    font-size: 18px;
    margin-bottom: 12px;
    line-height: 26px;
    min-height: 74px;
}

.sec6_perfect_points ul {
    min-height: 128px;
}
.sec6_perfect_grid .row:after {
    height: 59%;
}

.sec6_imgbox span {
    height: 170px;
}


}


  @media (max-width: 400px){
	.sec-8  .accordion_rista .card #faqhead6 .btn-header-link::after, .sec-8  .accordion_rista .card #faqhead8 .btn-header-link::after, .sec-8  .accordion_rista .card #faqhead9 .btn-header-link::after, .sec-8  .accordion_rista .card #faqhead4 .btn-header-link::after, .sec-8  .accordion_rista .card #faqhead5 .btn-header-link::after, .sec-8  .accordion_rista .card #faqhead7 .btn-header-link::after {
		right: -28px;
		left: inherit;
		top: -25px;
	}

    .sec6_perfect_grid {
    /* max-width: 290px; */
    margin: 0 auto;
}

.sec6_perfect_grid .row:after {
    height: 57%;
}

p.tab_lastline {
    justify-content: center;
    align-items: center;
}

p.tab_lastline strong {
    flex: 0 0 45%;
    max-width: 45%;
}
p.tab_lastline a {
    flex: 0 0 42%;
    max-width: 42%;
}
.ReactModalPortal .ReactModal__Content {
    margin-right: -35% !important;
}


  }

  @media (max-width: 375px){

	.sec-4 .nav.nav-pills {
		display: flex;
		gap: 8px;
	}

	.sec-4 .nav-pills .nav-link {
		padding: 0 5px;
		font-size: 12px;
	}

    .ReactModalPortal .ReactModal__Content {
    margin-right: -32% !important;
}

  }

  

  .new-des iframe {
	margin: auto;
	display: flex;
	justify-content: center;
}
.banner-latest-add img {
	width: 640px;
	border-radius: 10px;
}
.banner-latest-add{
    display: flex;
    justify-content: center;
}
.banner-latest-add img {
	width: 640px;
	border-radius: 10px;
	border: 1px solid #dadce0;
}

@media (max-width: 660px) {
    .new-des iframe {
        margin: auto;
        display: flex;
        justify-content: center;
        max-width: 360px;
    }
  }