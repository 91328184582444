
/* PersonalModal.css */

.customModalContent {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(215, 25, 25, 0.75);
  z-index: 1000;
}

@media (max-width: 1069px) {
  .customModalContent {
  position: fixed;
    top: 43%;
    left: 50%;
    transform: translate(-50%, 0);
  }
}