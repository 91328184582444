/* Ticker.css */
.App {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center tickers horizontally if needed */
}

.tickers-wrapper {
  display: flex;
  flex-direction: column; /* Stack tickers vertically */
  gap: 20px; /* Space between tickers, adjust as needed */
  width: 100%;
  max-width: 1200px; /* Optional: Set a maximum width */
  margin: 0 auto; /* Center tickers horizontally */
}

.ticker-container {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.ticker {
  display: flex;
  width: 200%; 
}

.ticker-content {
  display: flex;
  gap: 6px;
  padding-right: 0;
}

.ticker-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo-container {
  position: relative;
  /* width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); */
}
.sec-9 .brand-logo-slide .ticker-container .ticker-item .logo-container span {
    width: 150px;
    display: flex;
    padding: 0 18px;
    justify-content: center;
    align-items: center;
}

.logo-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 720px) {
  .logo-container {
    /* width: 64px;
    height: 64px;
    border-radius: 16px; */
  }
}
.sec-9 .brand-logo-slide .ticker-slider {
    display: flex;
}

.sec-9 .brand-logo-slide .ticker-container .ticker-item .logo-container {
    width: auto;
    object-fit: cover;
    /* padding: 0 17px; */
}

.scroll-right {
  animation: scroll-right 65s linear infinite;
}

.scroll-left {
  animation: scroll-left 65s linear infinite;
}

@keyframes scroll-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes scroll-left {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}
